<template>
    
    <PhraseBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import PhraseBody from "@/components/master/PhraseBody.vue"; 
  
  export default defineComponent({
    name: "PhraseView",
    components: {
      
        PhraseBody
      
    },
  });
</script>
  