<template>
    
    <!-- Preloader -->
    <div id="preloader" class="preloader">
        <div class="animation-preloader">
            <div class="spinner">                
            </div>
                <div class="txt-loading">
                    <img src="@/assets/baseAssets/img/logoLong.png" alt="">
                </div>
            <p class="text-center">Loading</p>
        </div>
        <div class="loader">
            <div class="row">
                <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                </div>
                <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                </div>
                <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                </div>
                <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                </div>
            </div>
        </div>
    </div>

    <!-- NAV -->
    <header class="header-1">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-2 col-sm-5 col-md-4 col-6">
                    <div class="logo">
                        <router-link :to="{name: 'home'}">
                            <img src="@/assets/baseAssets/img/logoLong.png"  alt="Transland" style="width: 48px;">
                               
                        </router-link>
                          
                    </div>
                </div>
                <div class="col-lg-10 text-end p-lg-0 d-none d-lg-flex justify-content-between align-items-center">
                    <div class="menu-wrap">
                        <div class="main-menu">
                            <ul>
                                
                                <li><router-link :to="{name: 'home'}">Home</router-link> </li>
                                <li><router-link :to="{name: 'about'}">About us</router-link></li>
                                <li><router-link :to="{name: 'staking'}">Staking</router-link></li>
                                <li><router-link :to="{name: 'grid-bot'}">Grid bot</router-link></li>
                                <li><a href="#">Legal</a>
                                    <ul class="sub-menu"  style="background: #fff">
                                        <li><router-link :to="{name: 'privacy-policy'}">Privacy policy</router-link></li>
                                        <li><router-link :to="{name: 'terms'}">Terms of use</router-link></li>
                                        
                                    </ul>
                                </li>
                                <li><router-link :to="{name: 'contact'}">Contact</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="header-right-element text-whit">
                        
                        <router-link :to="{name: 'sign-in'}" target="_blank" class="theme-btn black" style="background-color: #145eff; color:#ffffff; border-color:#145eff">Sign in</router-link>
                    </div>
                </div>
                <div class="d-block d-lg-none col-sm-1 col-md-8 col-6">
                    <div class="mobile-nav-wrap">
                        <div id="hamburger"><i class="fal fa-bars"></i></div>
                        <!-- mobile menu - responsive menu  -->
                        <div class="mobile-nav">
                            <button type="button" class="close-nav">
                                <i class="fal fa-times-circle"></i>
                            </button>
                            <nav class="sidebar-nav">
                                <ul class="metismenu" id="mobile-menu">
                                    
                                    <li><router-link :to="{name: 'home'}">Home</router-link></li>
                                    <li><router-link :to="{name: 'about'}">About us</router-link></li>
                                    <li><router-link :to="{name: 'staking'}">Staking</router-link></li>
                                    <li><router-link :to="{name: 'grid-bot'}">Grid bot</router-link></li>
                                    <li>
                                        <a class="has-arrow" href="#">Legal</a>
                                        <ul class="sub-menu">
                                            
                                            <li><router-link :to="{name: 'privacy-policy'}">Privacy policy</router-link></li>
                                            <li><router-link :to="{name: 'terms'}">Terms of use</router-link></li>
                                        </ul>
                                    </li>
                                    
                                    <li><router-link :to="{name: 'contact'}">Contact</router-link></li>
                                </ul>

                                <router-link :to="{name: 'sign-in'}" class="theme-btn d-block mt-4 text-center ms-0" style="background-color: #3772FF; border-color:#3772FF; color: #ffffff;" target="_blank">Sign in</router-link>
                            </nav>
                        </div> 
                    </div>
                    <div class="overlay"></div>
                </div>
            </div>
        </div>
    </header>

</template>

<style>
    @import url(@/assets/baseAssets/css/icons.css);
    @import url(@/assets/baseAssets/css/animate.css);
    @import url(@/assets/baseAssets/css/slick.css);
    @import url(@/assets/baseAssets/css/magnific-popup.css);
    @import url(@/assets/baseAssets/css/metismenu.css);
    @import url(@/assets/baseAssets/css/bootstrap.min.css);
    @import url(@/assets/baseAssets/css/style.css);
    @import url(@/assets/baseAssets/css/style2.css);
</style>


<script>
    export default {
        
        mounted(){

            document.body.classList.remove("dark");
            document.body.classList.add("body-wrapper");

            //modernizr
            const script2Check = document.getElementById("Script2");
            if(script2Check){
                document.body.removeChild(script2Check);
            }
            let Script2 = document.createElement("script");
            Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fmodernizr.min.js?alt=media&token=e7d99b75-336e-4019-a92b-af5b8a26590a");
            Script2.setAttribute("id", "Script2");
            document.body.appendChild(Script2);

            //jquery easing
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fjquery.easing.js?alt=media&token=a8d7ebb4-1e23-4192-a4f6-1e2cf49a3d4f");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //popper
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fpopper.min.js?alt=media&token=06306b9f-0061-4013-bbcf-d64222261bdc");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //bootstrap.min
            const script5Check = document.getElementById("Script5");
            if(script5Check){
                document.body.removeChild(script5Check);
            }
            let Script5 = document.createElement("script");
            Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fbootstrap.min.js?alt=media&token=75da05f7-6672-4c5d-a2a3-54bfc595b358");
            Script5.setAttribute("id", "Script5");
            document.body.appendChild(Script5);

            //isotope
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fisotope.pkgd.min.js?alt=media&token=8588d85e-f6c7-454a-9c6f-cf63264e2423");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //imageload
            const script7Check = document.getElementById("Script7");
            if(script7Check){
                document.body.removeChild(script7Check);
            }
            let Script7 = document.createElement("script");
            Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fimageload.min.js?alt=media&token=1a6a19a2-3067-48a5-b895-ea50b311b1b1");
            Script7.setAttribute("id", "Script7");
            document.body.appendChild(Script7);

            //scrollup
            // const script8Check = document.getElementById("Script8");
            // if(script8Check){
            //     document.body.removeChild(script8Check);
            // }
            // let Script8 = document.createElement("script");
            // Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2FscrollUp.min.js?alt=media&token=5ae8c895-77ed-45f1-a225-0fc0a1d8da9d");
            // Script8.setAttribute("id", "Script8");
            // document.body.appendChild(Script8);

            //slick
            const script9Check = document.getElementById("Script9");
            if(script9Check){
                document.body.removeChild(script9Check);
            }
            let Script9 = document.createElement("script");
            Script9.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fslick.min.js?alt=media&token=88731e9f-cb01-42b0-aae5-022523af76b2");
            Script9.setAttribute("id", "Script9");
            document.body.appendChild(Script9);

            //magnific
            const script10Check = document.getElementById("Script10");
            if(script10Check){
                document.body.removeChild(script10Check);
            }
            let Script10 = document.createElement("script");
            Script10.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fmagnific-popup.min.js?alt=media&token=d8069f01-2031-4b7b-b2ff-d0b39b5e21b2");
            Script10.setAttribute("id", "Script10");
            document.body.appendChild(Script10);

            //wow
            const script11Check = document.getElementById("Script11");
            if(script11Check){
                document.body.removeChild(script11Check);
            }
            let Script11 = document.createElement("script");
            Script11.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fwow.min.js?alt=media&token=700252b6-212f-4c1c-a0bf-f26c156a5c6f");
            Script11.setAttribute("id", "Script11");
            document.body.appendChild(Script11);

            //metis
            const script12Check = document.getElementById("Script12");
            if(script12Check){
                document.body.removeChild(script12Check);
            }
            let Script12 = document.createElement("script");
            Script12.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Fmetismenu.js?alt=media&token=c46c05b2-e14a-43a3-88ad-a63a3f1048a5");
            Script12.setAttribute("id", "Script12");
            document.body.appendChild(Script12);

            //active
            const script13Check = document.getElementById("Script13");
            if(script13Check){
                document.body.removeChild(script13Check);
            }
            let Script13 = document.createElement("script");
            Script13.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/baseJS%2Factive.js?alt=media&token=252be127-ee61-4942-b375-d808071cbb52");
            Script13.setAttribute("id", "Script13");
            document.body.appendChild(Script13);

            

            
        }

    }
</script>
