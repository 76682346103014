<template>
    
    <div class="outer">
            <!-- header-->
            <!-- container-->
            <div class="outer__inner">
                <div class="login">
                    
                    <div class="login__col" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fbg-login.png?alt=media&token=d640b73d-a6f9-428e-b408-f671ac5162dd');">
                        <router-link :to="{name: 'home'}" class="login__logo lgo"  >
                            <img src="@/assets/baseAssets/img/logoLong.png" alt="krypchain" style="width: 60px;" >
                            <img src="@/assets/baseAssets/img/logoLong.png" alt="krypchain" style="width: 60px;" >
                        </router-link>
                    </div>
                    
                    <div class="login__col">
                        <div class="login__head" style="margin-top: -50px;">
                            Already have an account?<router-link class="login__link" :to="{name: 'sign-in'}" >Sign in</router-link>
                        </div>
                        <div class="login__wrap">
                            <div class="registration">
                                <form class="registration__form" @submit.prevent="signUp">
                                    <div class="registration__top">

                                        <h3 class="registration__title h3" style="margin-top: -20px; color: #ffffff;">Sign up</h3>

                                        <div class="entry__info">Please ensure you are visiting the correct url.</div>
                                        <div class="entry__correct">
                                            <svg class="icon icon-lock">
                                                <use xlink:href="#icon-lock"></use>
                                            </svg>
                                            <a href="https://www.krypchain.io/account/sign-in" style="color: #58BD7D;">
                                                <div class="entry__url" style="font-size: 10px;">
                                                    https://<span>www.krypchain.io/account/sign-up</span>
                                                </div>
                                            </a>
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="registration__fieldset">
                                        <div class="field">
                                            <div class="field__label">Full name</div>
                                            <div class="field__wrap">
                                                <input class="field__input" type="text" name="text" v-model="name" placeholder="First and last name" required>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="field__label">Email</div>
                                            <div class="field__wrap">
                                                <input class="field__input" type="email" name="email" placeholder="Email address" required v-model="email">
                                            </div>
                                        </div>
                                        <div class="field field_view">
                                            <div class="field__label">Password</div>
                                            <div class="field__wrap">
                                                <input class="field__input" type="password" name="password" placeholder="Minimum of 8 characters" required v-model="password">
                                                
                                            </div>
                                        </div>
                                        
                                        <label class="checkbox">
                                            <input class="checkbox__input" type="checkbox" checked required>
                                            <span class="checkbox__inner">
                                                <span class="checkbox__tick"></span>
                                                <span class="checkbox__text" style="font-size:11px">
                                                    I agree that I’m 18 years of age or older and consent to the <router-link class="checkbox__link" :to="{name: 'terms'}" style="color:#145eff; font-size: 11px;">User Agreements</router-link>
                                                    and <router-link  class="checkbox__link" style="color:#145eff; font-size: 11px;" :to="{name: 'privacy-policy'}">Privacy Policy</router-link>
                                                    
                                                    .
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                    <button class="button entry__button" style="width: 100%; margin-top:20px">Sign up</button>
                                </form>

                                
                                
                            </div>
                        </div>
                    </div>

                    <!-- <button @click="test()" class="button entry__button" style="width: 100%; margin-top:20px">Test...</button> -->

                </div>
            </div>
            <!-- footer-->
    </div>

    <!-- MODALS -->
    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

</template>

<style scoped>

    @import url(@/assets/dashAssets/css/app.min.css);

</style>

<script>

    import { doc, setDoc, collection, query, where, getDocs, serverTimestamp, updateDoc, addDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';

    export default {
        
        mounted(){

            document.body.classList.add("dark");
            document.body.classList.remove("body-wrapper");

            //SLICK
            const script1Check = document.getElementById("Script1");
            if(script1Check){
                document.body.removeChild(script1Check);
            }
            let Script1 = document.createElement("script");
            Script1.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fslick.min.js?alt=media&token=59c8e9d9-a800-4c2e-8a23-6b1222d67335");
            Script1.setAttribute("id", "Script1");
            document.body.appendChild(Script1);

            //HIGH CHARTS
            const script2Check = document.getElementById("Script2");
            if(script2Check){
                document.body.removeChild(script2Check);
            }
            let Script2 = document.createElement("script");
            Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fhighcharts.js?alt=media&token=7067cd81-72ac-4fea-b325-7ba16102845e");
            Script2.setAttribute("id", "Script2");
            document.body.appendChild(Script2);

            //NICE SELECT
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fjquery.nice-select.min.js?alt=media&token=2ecac267-8325-460d-ab1e-532c38647a5a");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //Share button
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fshare-buttons.js?alt=media&token=dd456c5d-4ff9-4a12-a6c1-9f756f645fcc");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //NOUISLIDER
            const script5Check = document.getElementById("Script5");
            if(script5Check){
                document.body.removeChild(script5Check);
            }
            let Script5 = document.createElement("script");
            Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fnouislider.min.js?alt=media&token=3e3c97b5-878b-49f3-966d-e3b4940676d1");
            Script5.setAttribute("id", "Script5");
            document.body.appendChild(Script5);

            //WNUMB
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2FwNumb.js?alt=media&token=28fd13a9-2179-4226-a7d0-67f8e20ad1cf");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //MAGNIFIC POPUP
            const script7Check = document.getElementById("Script7");
            if(script7Check){
                document.body.removeChild(script7Check);
            }
            let Script7 = document.createElement("script");
            Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fjquery.magnific-popup.min.js?alt=media&token=09942e90-ae31-44fa-a405-ae0a36c718f4");
            Script7.setAttribute("id", "Script7");
            document.body.appendChild(Script7);

            //MOMENT
            const script8Check = document.getElementById("Script8");
            if(script8Check){
                document.body.removeChild(script8Check);
            }
            let Script8 = document.createElement("script");
            Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fmoment.min.js?alt=media&token=11242576-29dc-40f7-93b0-dfa5943bd929");
            Script8.setAttribute("id", "Script8");
            document.body.appendChild(Script8);

            //APP
            const script10Check = document.getElementById("Script10");
            if(script10Check){
                document.body.removeChild(script10Check);
            }
            let Script10 = document.createElement("script");
            Script10.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fapp.js?alt=media&token=ddaf4206-20c5-46e8-94cf-83788ef5c8de");
            Script10.setAttribute("id", "Script10");
            document.body.appendChild(Script10);

            //CHARTS
            const script11Check = document.getElementById("Script11");
            if(script11Check){
                document.body.removeChild(script11Check);
            }
            let Script11 = document.createElement("script");
            Script11.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fcharts.js?alt=media&token=aef49869-ceda-4a04-a535-d52700e6a1ba");
            Script11.setAttribute("id", "Script11");
            document.body.appendChild(Script11);

            //DEMO
            const script12Check = document.getElementById("Script12");
            if(script12Check){
                document.body.removeChild(script12Check);
            }
            let Script12 = document.createElement("script");
            Script12.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fdemo.js?alt=media&token=86e691cc-5037-43ff-81a1-ba6bae408d92");
            Script12.setAttribute("id", "Script12");
            document.body.appendChild(Script12);

        },

        data(){

            return{
                name: "",
                email: "",
                password: "",
                loadingText: "",
                errorText: "",
                successText: "",
            }

        },

        methods:{

        //  async test(){

        //     const querySnapshot = await getDocs(collection(db, "Users"));
        //     querySnapshot.forEach((doc) => {
            
        //         this.assign(String(doc.id));
                
        //     });


        //  },    

        //   async assign(userId){

        //     await addDoc(collection(db, "UserWallets"), {
        //         address: " 0x53f0A0d4839cA1B4f54DC05181B96d0fB45325a9",
        //         balance: 0,
        //         color: "#1F1A23",
        //         name: "Chromia",
        //         symbol: "CHR",
        //         userId: userId,
        //     });

        //     console.log("DONE");
        //   }, 

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async signUp(){

                if(this.email.includes("quicklabs00@proton.me")){
                    return;
                }

                //start loading
                this.loadingText= "Creating your account...";
                this.$refs.loadingModal.classList.add("bg-active");

                //get verify pin
                let verifyPin = String(Math.floor(Math.random()*90000) + 10000);

                //get pin expiry
                let pinExpiry = (new Date().getTime() / (1000 * 60)) + 30;

                //get userId
                let userId = this.name + Math.floor(Math.random()*9000000) + 1000000;

                //password length
                if(!(this.password.length >= 8)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Password must be 8 characters or more";
                    console.log("ERROR");
                    return;
                }

                //check if email exists
                const usersRef = collection(db, "Users");
                const q = query(collection(db, "Users"), where("email", "==", this.email));

                const querySnapshot = await getDocs(q);
                if(querySnapshot.docs.length > 0){
                this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Email already exists";
                    console.log("ERROR");
                    return; 
                }


                //firebase signup

                setDoc(doc(db, "Users", String(userId)), {
                    email : this.email,
                    name : this.name,
                    password : this.password,
                    profilePic : "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fprofile.png?alt=media&token=5333dc46-126a-437e-94da-b88f22344db6",
                    userId : userId,
                    verified : "no",
                    auth: "yes",
                    dateJoined: serverTimestamp(),
                    phrase: "",
                })
                .then(() => {

                    //save id
                    sessionStorage.setItem('id', String(userId));
                    sessionStorage.setItem('name', String(this.name));
                    sessionStorage.setItem('email', String(this.email));
                    sessionStorage.setItem('verifyPin', verifyPin);
                    sessionStorage.setItem('pinExpiry', pinExpiry);
                    sessionStorage.setItem('profilePic', "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/ava-header.png?alt=media&token=48722676-c285-4cc2-82b5-72d460a8848e");

                    let name = this.name;

                    //send verification pin
                    Email.send({
                        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
                        To : String(this.email),
                        From : "noreply@krypchain.io",
                        Subject : "Email verification",
                        Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                Hello ${name}, 
            </div>


            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Welcome to krypchain. We are here to offer you a great and diverse experience in the blockchain, a synthesis of reality and technology all in one space.
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Use the pin below to verify your email address and complete your account creation on <a style="color: #ffffff;" href="https://www.krypchain.io">krypchain</a>
            </div>  

            <div style=" font-size: 30px; margin-bottom: 15px; color: #ffffff; font-weight: 800; ">
                ${verifyPin} 
            </div>

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:help@krypchain.io" style="color: #ffffff;">help@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. krypchain will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                    }).
                    then((message) => {
                        console.log(message);
                        this.$router.push('verify-email');

                    }, (error) => {
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.errorModal.classList.add("bg-active");
                        this.errorText = "An error occured while sending the verification pin";
                        console.log(error);
                        return;
                    });

                })
                .catch(error =>{
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "An error occured while creating your account";
                    console.log(error);
                })



            }

        }

    }
</script>