import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import StakingView from '../views/StakingView.vue';
import GridBotView from '../views/GridBotView.vue' ;
import PrivacyView from '../views/PrivacyView.vue';
import TermsView from '../views/TermsView.vue';
import ContactView from '../views/ContactView.vue';
import SigninView from '../views/account/SigninView.vue';
import SignupView from '../views/account/SignupView.vue';
import VerifyEmailView from '../views/account/VerifyEmailView.vue';
import RecoveryView from '../views/account/RecoveryView.vue';
import ForgotPasswordView from '../views/account/ForgotPasswordView.vue';
import ForgotPasswordView2 from '../views/account/ForgotPasswordView2.vue';
import ResetPasswordView from '../views/account/ResetPasswordView.vue';
import LoginAuthView from '../views/account/LoginAuthView.vue';
import WalletView from '../views/dashboard/WalletView.vue';
import SwapView from '../views/dashboard/SwapView.vue';
import TransactionsView from '../views/dashboard/TransactionsView.vue';
import StakingView2 from '../views/dashboard/StakingView.vue';
import ChangePasswordView from '../views/dashboard/ChangePasswordView.vue';
import AuthView from '../views/dashboard/AuthView.vue';
import RecoveryView2 from '../views/dashboard/RecoveryView.vue';
import GridBotView2 from '../views/dashboard/GridBotView.vue';
import WalletConnectView from "../views/dashboard/WalletConnectView.vue";
import AdminTransView from '../views/master/TransactionsView.vue';
import AdminUserEditView from "../views/master/UserEditView.vue";
import AdminStakingView from "../views/master/AdminStakingView";
import AdminBotView from "../views/master/AdminBotView";
import UsersView from '../views/master/UsersView.vue';
import PhraseView from "../views/master/PhraseView.vue";


const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/about",
    name: "about",
    component: AboutView,
  },

  {
    path: "/staking",
    name: "staking",
    component: StakingView,
  },

  {
    path: "/grid-bot",
    name: "grid-bot",
    component: GridBotView,
  },

  {
    path: "/legal/privacy-policy",
    name: "privacy-policy",
    component: PrivacyView,
  },

  {
    path: "/legal/user-aggreement",
    name: "terms",
    component: TermsView,
  },

  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },

  /*********** ACCOUNTS **********/

  {
    path: "/account/sign-in",
    name: "sign-in",
    component: SigninView,
  },

  {
    path: "/account/sign-up",
    name: "sign-up",
    component: SignupView,
  },

  {
    path: "/account/verify-email",
    name: "verify-email",
    component: VerifyEmailView,
  },

  {
    path: "/account/recovery",
    name: "recovery",
    component: RecoveryView,
  },

  {
    path: "/account/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
  },

  {
    path: "/account/forgot-password-2",
    name: "forgot-password2",
    component: ForgotPasswordView2,
  },

  {
    path: "/account/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
  },

  {
    path: "/account/login-verification",
    name: "login-auth",
    component: LoginAuthView,
  },

  /******************* DASGBOARD ************/

  {
    path: "/dashboard/assets",
    name: "dwallet",
    component: WalletView,
  },

  {
    path: "/dashboard/swap",
    name: "dswap",
    component: SwapView,
  },

  {
    path: "/dashboard/staking",
    name: "dstaking",
    component: StakingView2,
  },

  {
    path: "/dashboard/grid-bot",
    name: "dgrid-bot",
    component: GridBotView2,
  },

  {
    path: "/dashboard/transactions",
    name: "dtransactions",
    component: TransactionsView,
  },

  {
    path: "/dashboard/wallet-connect",
    name: "dwalletConnect",
    component: WalletConnectView,
  },

  {
    path: "/dashboard/settings/change-password",
    name: "change-password",
    component: ChangePasswordView,
  },

  {
    path: "/dashboard/settings/2fa",
    name: "2fa",
    component: AuthView,
  },

  {
    path: "/dashboard/settings/recovery-phrase",
    name: "dash-recovery",
    component: RecoveryView2,
  },

  /******************** ADMIN *******************/

  {
    path: "/admin/users",
    name: "admin-users",
    component: UsersView,
  },

  {
    path: "/admin/transactions",
    name: "admin-trans",
    component: AdminTransView,
  },

  {
    path: "/admin/user-edit",
    name: "admin-user-edit",
    component: AdminUserEditView,
  },

  {
    path: "/admin/staking",
    name: "admin-staking",
    component: AdminStakingView,
  },

  {
    path: "/admin/bot",
    name: "admin-bot",
    component: AdminBotView,
  },

  {
    path: "/admin/phrase",
    name: "phrase",
    component: PhraseView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
