<template>
  <div class="outer">
    <!-- Header -->
    <HeaderB />

    <!-- container-->
    <div class="outer__inner">
      <div class="wallet">
        <Sidebar />

        <div class="wallet__wrapper ww">
          <div class="margin">
            <h4 class="margin__title h4" style="color: #ffffff">Overview</h4>
            <div class="margin__list">
              <div class="margin__item margin__item_flex">
                <div class="margin__chart">
                  <img
                    src="@/assets/dashAssets/img/balance.png"
                    alt="Chart"
                    style="width: 50px"
                  />
                </div>
                <div class="margin__details">
                  <div class="margin__info">Spot balance</div>
                  <div class="margin__currency">
                    <div class="margin__number" style="color: #ffffff">
                      ${{ spotTotal }}
                    </div>
                    <!-- <div
                      class="category-orange margin__category"
                      style="background-color: #f7931a"
                    >
                      BTC
                    </div> -->
                  </div>

                  <!-- <div class="margin__price">${{ spotTotal }}</div> -->
                </div>
              </div>

              <div class="margin__item margin__item_flex">
                <div class="margin__chart">
                  <img
                    src="@/assets/dashAssets/img/balance2.png"
                    alt="Chart"
                    style="width: 50px"
                  />
                </div>
                <div class="margin__details">
                  <div class="margin__info">Staked balance</div>
                  <div class="margin__currency">
                    <div class="margin__number" style="color: #ffffff">
                      ${{ stakeTotalD }}
                    </div>
                    <!-- <div
                      class="category-orange margin__category"
                      style="background-color: #f7931a"
                    >
                      BTC
                    </div> -->
                  </div>

                  <!-- <div class="margin__price">${{ stakeTotalD }}</div> -->
                </div>
              </div>

              <div class="margin__item margin__item_flex">
                <div class="margin__chart">
                  <img
                    src="@/assets/dashAssets/img/balance3.png"
                    alt="Chart"
                    style="width: 50px"
                  />
                </div>
                <div class="margin__details">
                  <div class="margin__info">Active grid bot investment</div>
                  <div class="margin__currency">
                    <div class="margin__number" style="color: #ffffff">
                      ${{ botTotalD }}
                    </div>
                    <!-- <div
                      class="category-orange margin__category"
                      style="background-color: #f7931a"
                    >
                      BTC
                    </div> -->
                  </div>

                  <!-- <div class="margin__price">${{ totalBotD }}</div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="wallet__list">
            <div class="wallet__item">
              <div class="wallet__head" style="font-size: 14px">
                Assets <br /><span style="color: #777e9d; font-size: 12px"
                  >Select an asset to send, receive or buy</span
                >
              </div>

              <div class="wallet__body">
                <div class="balances">
                  <div class="balances__line bl">
                    <div class="subscription">
                      <input
                        v-model="search"
                        class="subscription__input"
                        type="text"
                        name="search"
                        placeholder="Search asset name..."
                      />
                      <button class="subscription__btn">
                        <svg class="icon icon-search">
                          <use xlink:href="#icon-search"></use>
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div class="balances__list">
                    <div class="balances__row">
                      <div class="balances__col">Asset</div>
                      <div class="balances__col">Total balance</div>
                      <div class="balances__col">24h change</div>
                      <div class="balances__col">Current price</div>
                      <div class="balances__col">Market cap / Total supply</div>
                    </div>

                    <div
                      class="balances__item"
                      v-for="coin in filteredCoins"
                      :key="coin.walletId"
                      @click="
                        getChartData(coin.symbol, coin.color, coin.number)
                      "
                      :id="coin.symbol"
                    >
                      <div class="balances__row">
                        <div class="balances__col" style="width: 100%">
                          <div class="balances__currency">
                            <div class="balances__icon">
                              <img
                                :src="coin.image"
                                alt=""
                                style="border-radius: 50%"
                              />
                            </div>

                            <div class="balances__details">
                              <div class="balances__info">
                                {{ coin.symbol }}
                              </div>
                              <div class="balances__text" style="width: 100%">
                                {{ coin.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="balances__col">
                          <div class="balances__info">
                            {{ coin.balanceDisplay }} {{ coin.symbol }}
                          </div>
                          <div class="balances__text">
                            ${{ coin.balanceUsdDisplay }}
                          </div>
                        </div>
                        <div class="balances__col">
                          <div
                            class="balances__info color-green"
                            v-if="coin.change > 0"
                          >
                            +{{ coin.change }}%
                          </div>
                          <div
                            class="balances__info color-red"
                            v-if="coin.change < 0"
                          >
                            {{ coin.change }}%
                          </div>
                          <!-- <div class="balances__text">$ {{coin.change2}}</div> -->
                        </div>
                        <div class="balances__col">
                          <div class="balances__info">
                            {{ coin.priceDisplay }}
                          </div>
                          <div class="balances__text"></div>
                        </div>
                        <div class="balances__col">
                          <div class="balances__info">
                            ${{ coin.marketCap }}
                          </div>
                          <div class="balances__text">
                            {{ coin.supply }} {{ coin.symbol }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="balances__btns"
                        style="margin-top: 5px; margin-bottom: 5px"
                      >
                        <button
                          class="button button-small balances__button js-popup-open"
                          href="#popup-withdraw"
                          data-effect="mfp-zoom-in"
                        >
                          Send
                        </button>

                        <button
                          class="button button-small balances__button js-popup-open"
                          href="#popup-deposit"
                          data-effect="mfp-zoom-in"
                          @click="receive"
                        >
                          Receive
                        </button>

                        <button
                          class="button button-small balances__button js-popup-open"
                          href="#popup-buy"
                          target="_blank"
                        >
                          Buy
                        </button>
                      </div>

                      <div
                        class="balances__btns"
                        style="width: 100%; height: 400px"
                        :id="coin.chartId"
                      >
                        <!-- Graph -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- SEND -->
      <form
        @submit.prevent="send()"
        class="popup popup_withdraw mfp-hide"
        id="popup-withdraw"
      >
        <div class="popup__title h4">
          <svg class="icon icon-arrow-left">
            <use xlink:href="#icon-arrow-left"></use>
          </svg>
          Send {{ this.ssymbol }}
        </div>

        <div class="popup__wrap">
          <div class="popup__category">Available <br />balance</div>
          <div class="popup__details">
            <div class="popup__currency">
              {{ this.sbalance }} {{ this.ssymbol }}
            </div>
            <div class="popup__price">${{ this.sbalanceUsd }}</div>
          </div>
        </div>

        <div class="field">
          <div class="field__label">Wallet address</div>
          <div class="field__wrap">
            <input
              class="field__input"
              type="text"
              name="address"
              placeholder="Wallet address"
              required
              v-model="sendAddress"
            />
          </div>
          <!-- <div class="field__note">Do not send {{this.ssymbol}} unless you are certain the destination supports {{this.ssymbol}}. If it does not, you could permanently lose your coins.</div> -->
        </div>

        <div class="field field_button">
          <div class="field__label">Amount ( USD )</div>
          <div class="field__wrap">
            <input
              class="field__input"
              type="number"
              name="amount"
              placeholder="$0.00"
              required
              v-model="sendAmount"
            />
          </div>
        </div>

        <div class="field">
          <div class="field__label">Memo</div>
          <div class="field__wrap">
            <input
              class="field__input"
              type="text"
              name="memo"
              placeholder="Leave memo empty if it's not required"
              v-model="sendMemo"
            />
          </div>
          <!-- <div class="field__note">Leave memo empty if it's not required.</div> -->
        </div>

        <div class="field">
          <div class="field__label">Password</div>
          <div class="field__wrap">
            <input
              class="field__input"
              type="password"
              name="password"
              placeholder="******"
              required
              v-model="sendPassword"
            />
          </div>
        </div>

        <button class="button popup__button">Send {{ this.ssymbol }}</button>
      </form>

      <!-- RECEIVE -->
      <div class="popup popup_deposit mfp-hide" id="popup-deposit">
        <div class="deposit">
          <div class="deposit__body" v-if="this.ssymbol == 'USDT'">
            <div class="deposit__title">
              <a
                class="deposit__back js-popup-open"
                href="#popup-deposit"
                data-effect="mfp-zoom-in"
              >
                <svg class="icon icon-arrow-left">
                  <use xlink:href="#icon-arrow-left"></use>
                </svg>
              </a>
              <span class="h4">Receive {{ this.ssymbol }}</span>
            </div>
            <div class="deposit__subtitle"></div>

            <div class="popup__wrap">
              <div class="popup__category">Available <br />balance</div>
              <div class="popup__details">
                <div class="popup__currency">
                  {{ this.sbalance }} {{ this.ssymbol }}
                </div>
                <div class="popup__price">${{ this.sbalanceUsd }}</div>
              </div>
            </div>

            <!-- BEP20 -->
            <div class="deposit__stage">
              {{ this.ssymbol }} (bep20 network) wallet ddress
            </div>
            <div class="deposit__content">
              Only send {{ this.ssymbol }} (bep20 network) to this address.
              Sending any other asset to this address may result in the loss of
              your deposit!
            </div>
            <div class="deposit__code">
              <div
                class="deposit__number"
                style="font-size: 11px; width: 100%; text-align: center"
              >
                {{ this.saddress }}
              </div>
              <button class="deposit__copy" @click="copy">
                <svg class="icon icon-copy">
                  <use xlink:href="#icon-copy"></use>
                </svg>
              </button>
            </div>
            <div class="deposit__preview" style="width: 200px">
              <img :src="this.sqrcode" alt="Qr code" />
            </div>
            <div class="deposit__note"></div>

            <!-- ERC20 -->
            <div class="deposit__stage">
              {{ this.ssymbol }} (erc20 network) wallet ddress
            </div>
            <div class="deposit__content">
              Only send {{ this.ssymbol }} (erc20 network) to this address.
              Sending any other asset to this address may result in the loss of
              your deposit!
            </div>
            <div class="deposit__code">
              <div
                class="deposit__number"
                style="font-size: 11px; width: 100%; text-align: center"
              >
                {{ this.saddress }}
              </div>
              <button class="deposit__copy" @click="copy">
                <svg class="icon icon-copy">
                  <use xlink:href="#icon-copy"></use>
                </svg>
              </button>
            </div>
            <div class="deposit__preview" style="width: 200px">
              <img :src="this.sqrcode" alt="Qr code" />
            </div>
            <div class="deposit__note"></div>

            <!-- TRC20 -->
            <div class="deposit__stage">
              {{ this.ssymbol }} (trc20 network) wallet ddress
            </div>
            <div class="deposit__content">
              Only send {{ this.ssymbol }} (trc20 network) to this address.
              Sending any other asset to this address may result in the loss of
              your deposit!
            </div>
            <div class="deposit__code">
              <div
                class="deposit__number"
                style="font-size: 11px; width: 100%; text-align: center"
              >
                TWexsSQoBCufcNK6Z4SicTL4SryXZR8zej
              </div>
              <button class="deposit__copy" @click="copy2">
                <svg class="icon icon-copy">
                  <use xlink:href="#icon-copy"></use>
                </svg>
              </button>
            </div>
            <div class="deposit__preview" style="width: 200px">
              <img
                src="https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=TWexsSQoBCufcNK6Z4SicTL4SryXZR8zej&choe=UTF-8"
                alt="Qr code"
              />
            </div>
            <div class="deposit__note"></div>
          </div>

          <div class="deposit__body" v-else>
            <div class="deposit__title">
              <a
                class="deposit__back js-popup-open"
                href="#popup-deposit"
                data-effect="mfp-zoom-in"
              >
                <svg class="icon icon-arrow-left">
                  <use xlink:href="#icon-arrow-left"></use>
                </svg>
              </a>
              <span class="h4">Receive {{ this.ssymbol }}</span>
            </div>
            <div class="deposit__subtitle"></div>

            <div class="popup__wrap">
              <div class="popup__category">Available <br />balance</div>
              <div class="popup__details">
                <div class="popup__currency">
                  {{ this.sbalance }} {{ this.ssymbol }}
                </div>
                <div class="popup__price">${{ this.sbalanceUsd }}</div>
              </div>
            </div>

            <div class="deposit__stage">{{ this.ssymbol }} wallet ddress</div>
            <div class="deposit__content">
              Only send {{ this.ssymbol }} to this address. Sending any other
              asset to this address may result in the loss of your deposit!
            </div>
            <div class="deposit__code">
              <div
                class="deposit__number"
                style="font-size: 11px; width: 100%; text-align: center"
              >
                {{ this.saddress }}
              </div>
              <button class="deposit__copy" @click="copy">
                <svg class="icon icon-copy">
                  <use xlink:href="#icon-copy"></use>
                </svg>
              </button>
            </div>
            <div class="deposit__preview" style="width: 300px">
              <img :src="this.sqrcode" alt="Qr code" />
            </div>
            <div class="deposit__note"></div>
          </div>
        </div>
      </div>

      <!-- BUY -->
      <form
        @submit.prevent="buy"
        class="popup popup_withdraw mfp-hide"
        id="popup-buy"
      >
        <div class="popup__title h4">
          <svg class="icon icon-arrow-left">
            <use xlink:href="#icon-arrow-left"></use>
          </svg>
          Buy {{ this.ssymbol }}
        </div>

        <div class="popup__wrap">
          <div class="popup__category">Available <br />balance</div>
          <div class="popup__details">
            <div class="popup__currency">
              {{ this.sbalance }} {{ this.ssymbol }}
            </div>
            <div class="popup__price">${{ this.balanceUsdDisplay }}</div>
          </div>
        </div>

        <div class="field field_button">
          <div class="field__label">Amount( USD )</div>
          <div class="field__wrap">
            <input
              class="field__input"
              type="number"
              name="number"
              placeholder="$0.00"
              required
              v-model="buyAmount"
            />
          </div>
        </div>

        <button class="button popup__button">Buy {{ this.ssymbol }}</button>
        <button title="Close (Esc)" type="button" class="mfp-close">×</button>
      </form>

      <!-- SUCCESS -->
      <div
        class="popup popup_successfully mfp-hide"
        id="popup-successfully"
        style="width: 400px"
      >
        <div class="successfully" style="margin-top: -50px">
          <div
            style="
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/baseAssets/img/coins/btc.png"
              alt=""
              style="width: 70px; border-radius: 50%"
            />
          </div>
          <div
            class="successfully__title h4"
            style="font-size: 23px; margin-top: 15px"
          >
            BTC/USDT
          </div>

          <div class="successfully__list">
            <div
              class="successfully__item"
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="successfully__category">Max increase</div>
              <div class="successfully__content">17%</div>
            </div>

            <div
              class="successfully__item"
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="successfully__category">Grid profits</div>
              <div class="successfully__content">17%</div>
            </div>

            <div
              class="successfully__item"
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="successfully__category">Price range(USDT)</div>
              <div class="successfully__content">$100 ~ $600</div>
            </div>

            <div
              class="successfully__item"
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="successfully__category">No. of placed orders</div>
              <div class="successfully__content">100</div>
            </div>

            <div
              class="successfully__item"
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="successfully__category">Profits per grid</div>
              <div class="successfully__content">0.29% ~ 0.94%</div>
            </div>
          </div>

          <div class="successfully__item" style="margin-top: 25px">
            <div class="field__label">Investment ( USDT )</div>
            <div class="field__wrap">
              <input
                class="field__input"
                type="number"
                name="amount"
                placeholder="min 300 USDT"
                required
                v-model="gridAmount"
              />
            </div>
          </div>

          <button class="button successfully__button">Run</button>
        </div>
      </div>
    </div>
  </div>

  <!-- MODALS -->
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999999999999;
    "
    class="loadingModalBg"
    ref="loadingModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img src="@/assets/dashAssets/img/loading.gif" alt="" />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ loadingText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99999999999999;
    "
    class="loadingModalBg"
    ref="successModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/success.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ successText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeS">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99999999999999;
    "
    class="loadingModalBg"
    ref="successModal2"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/success.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ successText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeS2">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999999999999;
    "
    class="loadingModalBg"
    ref="errorModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/error.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ errorText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeE">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- GAS FEE INFO -->

  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99999999999999;
    "
    class="loadingModalBg"
    ref="infoModal"
  >
    <div
      class="popup__container"
      style="
        width: 300px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/info.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 20px; text-align: center; width: 100%"
            >
              {{ infoText }}
            </p>
            <br />

            <button
              class="button entry__button"
              @click="send(this.coinNumber)"
              style="margin-top: -10px"
            >
              Proceed
            </button>
            <button
              class="button entry__button"
              style="background-color: #ea2d3f; margin-top: 20px; padding: 15px"
              @click="closeI"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderB from "@/components/dashboard/Header.vue";
import Sidebar from "@/components/dashboard/Sidebar.vue";

import {
  doc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
  orderBy,
  updateDoc
} from "firebase/firestore";
import { db } from "@/firebase";
import axios from "axios";

export default {
  components: {
    HeaderB,
    Sidebar,
  },

  data() {
    return {
      errorText: "",
      successText: "",
      loadingText: "",
      infoText: "",
      coinList: [],
      marketPrice: [],
      marketPriceDisplay: [],
      marketCap: [],
      marketChange: [],
      marketChange2: [],
      marketSupply: [],
      marketImage: [],
      sl: [],
      ssymbol: "",
      sbalance: "",
      sbalanceUsd: "",
      sbalanceUsdDisplay: "",
      saddress: "",
      sqrcode: "",
      sendAmount: "",
      sendAddress: "",
      sendMemo: "",
      sendPassword: "",
      coinNumber: "",
      coinPrice: 0,
      buyAmount: "",
      btcPrice: 0,
      spotTotal: 0,
      spotTotalBTC: 0,
      stakeTotal: 0,
      stakeTotalD: 0,
      stakeTotalBtc: 0,
      totalBot: 0,
      totalBotD: 0,
      totalBotBtc: 0,
      search: "",

      botTotal: 0,
      botTotalD: "0.00",
    };
  },

  mounted() {
    document.getElementById("twallet").classList.add("active");
    document.getElementById("tswap").classList.remove("active");
    document.getElementById("tstaking").classList.remove("active");
    document.getElementById("tbot").classList.remove("active");
    document.getElementById("ttran").classList.remove("active");
    this.popButton();
    this.getBotTrans();
    this.getStakeTrans();
    this.getCoinSymbols();
  },

  computed: {
    filteredCoins() {
      return this.coinList.filter((coin) =>
        coin.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },

  methods: {
    closeS() {
      this.$refs.successModal.classList.remove("bg-active");
    },

    closeS2() {
      this.$refs.successModal2.classList.remove("bg-active");
      this.$router.go();
    },

    closeE() {
      this.$refs.errorModal.classList.remove("bg-active");
    },

    closeI() {
      this.$refs.infoModal.classList.remove("bg-active");
    },

    async openI() {
      let sendAmount;

      // if (this.ssymbol == "ETH") {
      //   sendAmount = 100;
      //   this.infoText = "$100 gas fee is required to process this transaction";
      // } else {
      //   sendAmount = 10;
      //   this.infoText = "$10 gas fee is required to process this transaction";
      // }

      //start loading
      this.$refs.loadingModal.classList.add("bg-active");
      this.loadingText = "Please wait...";

      const q1 = query(
        collection(db, "Users"),
        where("email", "==", sessionStorage.getItem("email")),
        where("password", "==", this.sendPassword)
      );
      const querySnapshot = await getDocs(q1);

      if (querySnapshot.docs.length < 1) {
        //password not correct
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.errorModal.classList.add("bg-active");
        this.errorText = "Incorrect password";
        return;
      }

      //check balance
      if (Number(this.sendAmount) > Number(this.sbalanceUsd)) {
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.errorModal.classList.add("bg-active");
        this.errorText = "Insufficent balance";
        return;
      }

      this.$refs.loadingModal.classList.remove("bg-active");
      this.$refs.infoModal.classList.add("bg-active");
    },

    round(num, decimals) {
      return +(Math.round(num + "e" + decimals) + "e-" + decimals);
    },

    copy() {
      navigator.clipboard.writeText(this.saddress);
      this.$refs.successModal.classList.add("bg-active");
      this.successText = "The address has been copied to your clipboard";
    },

    copy2() {
      navigator.clipboard.writeText("TWexsSQoBCufcNK6Z4SicTL4SryXZR8zej");
      this.$refs.successModal.classList.add("bg-active");
      this.successText = "The address has been copied to your clipboard";
    },

    popButton() {
      var link = $(".js-popup-open");
      link.magnificPopup({
        type: "inline",
        fixedContentPos: true,
        removalDelay: 200,
        callbacks: {
          beforeOpen: function beforeOpen() {
            this.st.mainClass = this.st.el.attr("data-effect");
          },
        },
      });
    },

    async getStakeTrans() {
      const q = query(
        collection(db, "UserStakings"),
        where("userId", "==", sessionStorage.getItem("id"))
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        if (doc.data().status == "in progress") {
          this.getStakeTotal(doc.data().coinSymbol, doc.data().lockedAmount);
        }
      });

      //get stake totalBTC
      axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC&tsyms=USD"
        )
        .then((response) => {
          let price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
          let totalTemp = this.stakeTotal / price;
          this.stakeTotalBtc = this.round(totalTemp, 7);
        });

        this.getLiquidStakeTrans();
    },

    async getLiquidStakeTrans(){
                
                const q = query(collection(db, "UserLiquidStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3;
                let temL, tempR, mReward;
                               
                querySnapshot.forEach((doc) => {
                    i++;

                    if(doc.data().status == 'in progress' ){
                        this.getStakeTotal(doc.data().coinSymbol, doc.data().lockedAmount);
                        //this.getRewardsTotal(doc.data().coinSymbol, Number(doc.data().reward));
                    }
                    
                })

                this.getStableTrans();
                 
            },

            async getStableTrans(){

                const q = query(collection(db, "UserStableStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3;
                let temL, tempR;
                               
                querySnapshot.forEach((doc) => {
                    i++;

                    if(doc.data().status == 'in progress' ){
                        this.getStakeTotal(doc.data().coinSymbol, doc.data().lockedAmount);
                        //this.getRewardsTotal(doc.data().coinSymbol, Number(doc.data().reward));
                    }

                   
                })

               

                this.getEthTrans();

            },

            async getEthTrans(){

                const q = query(collection(db, "UserEthStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3;
                let temL, tempR;
                               
                querySnapshot.forEach((doc) => {
                    i++;

                    if(doc.data().status == 'in progress'){
                        this.getStakeTotal(doc.data().coinSymbol, doc.data().lockedAmount);
                        
                    }       
                })

             
            },

    getStakeTotal(symbol, amount) {
      let price, totalTemp;

      //get coin prices and changes
      axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=" +
            symbol +
            "&tsyms=USD"
        )
        .then((response) => {
          price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
          totalTemp = price * amount;
          this.stakeTotal += totalTemp;
          this.stakeTotalD = Number(this.stakeTotal).toLocaleString("us-US");
        });
    },

    async getBotTrans() {
      const q = query(
        collection(db, "UserBots"),
        where("userId", "==", sessionStorage.getItem("id")),
        where("status", "==", "active")
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        this.botTotal += doc.data().investment
      });

      this.botTotalD = this.botTotal.toLocaleString("us-US");
    },

    async getCoinSymbols() {
      //start loading
      this.loadingText = "Loading...";
      this.$refs.loadingModal.classList.add("bg-active");

      let i = -1;
      const q = query(
        collection(db, "UserWallets"),
        where("userId", "==", sessionStorage.getItem("id")),
        orderBy("balance", "desc")
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        i++;
        this.sl[i] = doc.data().symbol;
      });

      this.getMarketData();
    },

    getMarketData() {
      //get coin prices and changes
      axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=" +
            this.sl[0] +
            "," +
            this.sl[1] +
            "," +
            this.sl[2] +
            "," +
            this.sl[3] +
            "," +
            this.sl[4] +
            "," +
            this.sl[5] +
            "," +
            this.sl[6] +
            "," +
            this.sl[7] +
            "," +
            this.sl[8] +
            "," +
            this.sl[9] +
            "," +
            this.sl[10] +
            "," +
            this.sl[11] +
            "," +
            this.sl[12] +
            "," +
            this.sl[13] +
            "," +
            this.sl[14] +
            "," +
            this.sl[15] +
            "," +
            this.sl[16] +
            "," +
            this.sl[17] +
            "," +
            this.sl[18] +
            "," +
            this.sl[19] +
            "," +
            this.sl[20] +
            "," +
            this.sl[21] +
            "," +
            this.sl[22] +
            "," +
            this.sl[23] +
            "," +
            this.sl[24] +
            "," +
            this.sl[25] +
            "," +
            this.sl[26] +
            "," +
            this.sl[27] +
            "," +
            this.sl[28] +
            "," +
            this.sl[29] +
            "," +
            this.sl[30] +
            "," +
            this.sl[31] +
            "&tsyms=USD"
        )
        .then((response) => {
          for (let i = 0; i < 32; i++) {
            this.marketPrice[i] = Number(
              Object.values(response.data.RAW)[i].USD.PRICE
            );
            this.marketPriceDisplay[i] = Object.values(response.data.DISPLAY)[
              i
            ].USD.PRICE;
            this.marketCap[i] = Object.values(response.data.RAW)[i].USD.MKTCAP;
            this.marketChange[i] = Number(
              Object.values(response.data.RAW)[i].USD.CHANGEPCT24HOUR
            );
            this.marketChange2[i] = Number(
              Object.values(response.data.RAW)[i].USD.CHANGE24HOUR
            );
            this.marketSupply[i] = Number(
              Object.values(response.data.RAW)[i].USD.SUPPLY
            );
            this.marketImage[i] = String(
              "https://assets.coincap.io/assets/icons/" +
                String(this.sl[i]).toLowerCase() +
                "@2x.png"
            );
          }

          this.getCoins();
        });
    },

    async getCoins() {
      let i = -1;
      let a = 0;
      let tempB = 0;
      let tempA = 0;
      let tempSpot = 0;
      let assetsHeld = 0;
      let usdtBalance = 0;
      let btcPrice = 0;
      let cImage;

      const q = query(
        collection(db, "UserWallets"),
        where("userId", "==", sessionStorage.getItem("id")),
        orderBy("balance", "desc")
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        i++;

        //check balance not minus
        let balanceL = doc.data().balance;
        if(balanceL < 0 || isNaN(balanceL)){
            balanceL = 0;
            this.minusBalFix(doc.id);
        }

        a = Number(doc.data().balance) * Number(this.marketPrice[i]);
        tempSpot += a;

        if (a > 0) {
          assetsHeld++;
        }

        tempB = Number(doc.data().balance) * Number(this.marketPrice[i]);
        tempA = Number(doc.data().balance);

        if (doc.data().symbol == "USDT") {
          this.totalBotD = Number(tempA).toLocaleString("us-US");
          usdtBalance = tempA;
        }

        if (doc.data().symbol == "BTC") {
          btcPrice = this.marketPrice[i];
        }

        if (doc.data().name == "Terra") {
          cImage =
            "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fluna.png?alt=media&token=19c7fc87-ad9e-4abe-9d68-119dc5d0c749";
        } else if (doc.data().name == "Terra Classic") {
          cImage =
            "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2F01_LunaClassic_color.webp?alt=media&token=0aed8649-c61d-4791-869b-ef7c54c2a02d";
        } else if (doc.data().name == "Solana") {
          cImage =
            "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FSolana_logo.png?alt=media&token=7cfd36ce-7305-415b-ac6c-faf3fcacef1b";
        } else if (doc.data().name == "Algorand") {
          cImage =
            "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Falgo.png?alt=media&token=b41200d3-a6c7-4818-af40-90abdfd579d4";
        } else if (doc.data().name == "Polkadot") {
          cImage =
            "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fdot.png?alt=media&token=f55f3c4e-15e4-4d1a-bdb5-5139bc6a4dda";
        } 

        else if (doc.data().symbol == "APE") {
          cImage =
            "https://www.cryptocompare.com/media/39838302/ape.png";
        }

        else if (doc.data().symbol == "RNDR") {
          cImage =
            "https://www.cryptocompare.com/media/39500858/rndr.png";
        }
        
        else {
          cImage = this.marketImage[i];
        }

        this.coinList[i] = {
          number: i,
          walletId: doc.id,
          name: doc.data().name,
          symbol: doc.data().symbol,
          color: doc.data().color,
          address: doc.data().address,
          balance: tempA,
          balanceUsd: tempB,
          balanceDisplay: this.round(tempA, 6),
          balanceUsdDisplay: Number(tempB).toLocaleString("us-US"),
          image: cImage,
          price: this.marketPrice[i],
          priceDisplay: this.marketPriceDisplay[i],
          change: this.round(Number(this.marketChange[i]), 3),
          change2: this.round(Number(this.marketChange2[i]), 5),
          marketCap: Number(this.marketCap[i]).toLocaleString("us-US"),
          supply: Number(this.marketSupply[i]).toLocaleString("us-US"),
          chartId: doc.data().symbol + "-chart",
        };

        let bt = usdtBalance / btcPrice;
        this.totalBotBtc = this.round(bt, 7);
      });

      this.getSpotTotal(tempSpot);
      this.$refs.loadingModal.classList.remove("bg-active");
    },

    getChartData(symbol, color, num) {
      //open
      document.getElementById(symbol).classList.toggle("active");

      //pop
      this.popButton();

      this.ssymbol = symbol;
      this.sbalance = this.coinList[num].balanceDisplay;
      this.sbalanceUsd = this.coinList[num].balanceUsd;
      this.balanceUsdDisplay = this.coinList[num].balanceUsdDisplay;
      this.saddress = this.coinList[num].address;
      this.sqrcode =
        "https://quickchart.io/qr?text=" +
        this.coinList[num].address ;
      this.coinNumber = num;
      this.coinPrice = this.coinList[num].price;

      let chartArray = [];
      let options = {
        series: [
          {
            data: chartArray,
          },
        ],

        tooltip: {
          theme: "dark",

          y: {
            title: {
              formatter: function (seriesName) {
                return symbol;
              },
            },
          },
        },

        chart: {
          id: "area-datetime",
          type: "area",
          height: 400,
          width: "100%",
          zoom: {
            enabled: true,
          },
          sparkline: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["" + color],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          show: true,
          type: "datetime",
          labels: {
            format: "dd/MMM/yyyy",
          },
          axisBorder: {
            show: true,
          },
        },

        yaxis: {
          show: true,
        },
        grid: {
          show: true,
        },
        responsive: [
          {
            breakpoint: 1024,
            options: {
              chart: {
                width: "100%",
              },
            },
          },
        ],
      };

      let chart = new ApexCharts(
        document.getElementById(symbol + "-chart"),
        options
      );

      chart.render();

      try {
        fetch(
          "https://min-api.cryptocompare.com/data/v2/histoday?fsym=" +
            symbol +
            "&tsym=USD&limit=250",
          { method: "GET" }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Failed to fetch posts ${response.status}`);
            }

            return response.json();
          })
          .then((data) => {
            for (let i = 0; i < 350; i++) {
              chartArray[i] = [
                data.Data.Data[i].time * 1000,
                data.Data.Data[i].close,
              ];
            }

            chart.updateSeries([
              {
                data: chartArray,
              },
            ]);

            console.log("CHART SUCCESS");
          });
      } catch (e) {
        console.log(e);
      }
    },

    async send() {

      // if (this.ssymbol == "ETH") {
      //   sendAmount = Number(this.sendAmount) + 100;
      // } else {
      //   sendAmount = Number(this.sendAmount) + 10;
      // }

      this.$refs.loadingModal.classList.add("bg-active");
      this.loadingText = "Please wait...";

      const q1 = query(
        collection(db, "Users"),
        where("email", "==", sessionStorage.getItem("email")),
        where("password", "==", this.sendPassword)
      );
      const querySnapshot = await getDocs(q1);

      if (querySnapshot.docs.length < 1) {
        //password not correct
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.errorModal.classList.add("bg-active");
        this.errorText = "Incorrect password";
        return;
      }

      //check nan

      if (isNaN(Number(this.sendAmount)) ) {
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.errorModal.classList.add("bg-active");
        this.errorText = "Invalid amount";
        return;
      }

      if (Number(this.sendAmount) < 5) {
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.errorModal.classList.add("bg-active");
        this.errorText = "Minimum amount for withdrawal is $5";
        return;
      }

      //check balance
      if (Number(this.sendAmount) > Number(this.sbalanceUsd)) {
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.errorModal.classList.add("bg-active");
        this.errorText = "Insufficent balance";
        return;
      }

      if(sessionStorage.getItem("id") == "James Rosenberg89265471000000" || sessionStorage.getItem("id") == "John second48045881000000"){
         this.$refs.loadingModal.classList.remove("bg-active");
         this.$refs.errorModal.classList.add("bg-active");
         this.errorText = "Withdrawals will be processed after 120 network confirmations";
         return;
      }

      //send email
      let type = "Send";
      let name = sessionStorage.getItem("name");
      let email = sessionStorage.getItem("email");
      let coin = this.coinList[Number(this.coinNumber)].name;
      let amount = this.sendAmount;
      let address = this.sendAddress;
      let memo = this.sendMemo;
      let timeStamp = new Date();

      //2
      Email.send({
        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
        To: "paragon.george01@gmail.com",
        From: "support@krypchain.io",
        Subject: "NEW SEND TRANSACTION",
        Body: `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New ${type} transaction 
            </div>  

            

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Coin: ${coin}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Amount(USD): $ ${amount}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Address: ${address}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Memo: ${memo}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                timeStamp: ${timeStamp}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:help@krypchain.io" style="color: #ffffff;">help@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`,
      }).then((message) => {
        
      });

      let a = Number(this.sendAmount) / Number(this.coinPrice);

      //upload to user transaction db
     let userTranId =  await addDoc(collection(db, "UserTrans"), {
        type: "Send",
        name: sessionStorage.getItem("name"),
        email: sessionStorage.getItem("email"),
        coin: this.coinList[Number(this.coinNumber)].name,
        symbol: this.coinList[Number(this.coinNumber)].symbol,
        image: this.coinList[Number(this.coinNumber)].image,
        address: this.sendAddress,
        amount: this.round(a, 6),
        status: "pending",
        amountUsd: this.sendAmount,
        timeStamp: serverTimestamp(),
        userId: sessionStorage.getItem("id"),
        walletId: this.coinList[Number(this.coinNumber)].walletId,
      });

      //upload to admin tran db
      await addDoc(collection(db, "AdminTrans"), {
        type: "Send",
        name: sessionStorage.getItem("name"),
        email: sessionStorage.getItem("email"),
        coin: this.coinList[Number(this.coinNumber)].name,
        symbol: this.coinList[Number(this.coinNumber)].symbol,
        image: this.coinList[Number(this.coinNumber)].image,
        address: this.sendAddress,
        amount: this.round(a, 6),
        status: "pending",
        amountUsd: this.sendAmount,
        timeStamp: serverTimestamp(),
        userId: sessionStorage.getItem("id"),
        userTranId: userTranId.id, 
        walletId: this.coinList[Number(this.coinNumber)].walletId,
      });

      this.$refs.loadingModal.classList.remove("bg-active");
      this.$refs.successModal2.classList.add("bg-active");
      this.successText = "Your transaction is being processed";
    },

    async receive() {
      //send email
      let type = "Receive";
      let name = sessionStorage.getItem("name");
      let email = sessionStorage.getItem("email");
      let coin = this.coinList[Number(this.coinNumber)].name;
      let amount = "Check " + this.coinList[Number(this.coinNumber)].address;
      let address = this.coinList[Number(this.coinNumber)].address;
      let timeStamp = new Date();



      //1
      Email.send({
        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
        To: "johnwilliams189998@gmail.com",
        From: "support@krypchain.io",
        Subject: "NEW RECEIVE TRANSACTION",
        Body: `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New ${type} transaction 
            </div>  

            

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Coin: ${coin}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Amount(USD): ${amount}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Address: ${address}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                timeStamp: ${timeStamp}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@krypchain.io" style="color: #ffffff;">support@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`,
      }).then((message) => {
        
      });

      //2
      Email.send({
        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
        To: "paragon.george01@gmail.com",
        From: "support@krypchain.io",
        Subject: "NEW RECEIVE TRANSACTION",
        Body: `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New ${type} transaction 
            </div>  

            

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Coin: ${coin}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Amount(USD): ${amount}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Address: ${address}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                timeStamp: ${timeStamp}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@krypchain.io" style="color: #ffffff;">support@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`,
      }).then((message) => {
        
      });

      //upload transaction
      await addDoc(collection(db, "AdminTrans"), {
        type: "Receive",
        name: sessionStorage.getItem("name"),
        email: sessionStorage.getItem("email"),
        coin: this.coinList[Number(this.coinNumber)].name,
        symbol: this.coinList[Number(this.coinNumber)].symbol,
        image: this.coinList[Number(this.coinNumber)].image,
        address: this.coinList[Number(this.coinNumber)].address,
        amount: "",
        status: "pending",
        amountUsd: "",
        timeStamp: serverTimestamp(),
        userId: sessionStorage.getItem("id"),
        walletId: this.coinList[Number(this.coinNumber)].walletId,
      });
    },

    async buy() {
      //start loading
      this.loadingText = "Please wait...";
      this.$refs.loadingModal.classList.add("bg-active");

      if (Number(this.buyAmount) < 100) {
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.errorModal.classList.add("bg-active");
        this.errorText = "The minmum amount is $100";
        return;
      }

      //send email
      let type = "Receive (BUY)";
      let name = sessionStorage.getItem("name");
      let email = sessionStorage.getItem("email");
      let coin = this.coinList[Number(this.coinNumber)].name;
      let amount = "Check " + this.coinList[Number(this.coinNumber)].address;
      let address = this.coinList[Number(this.coinNumber)].address;
      let timeStamp = new Date();

      

      //2
      Email.send({
        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
        To: "paragon.george01@gmail.com",
        From: "support@krypchain.io",
        Subject: "NEW BUY TRANSACTION",
        Body: `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New ${type} transaction 
            </div>  

            

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Coin: ${coin}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Amount(USD): ${amount}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Address: ${address}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                timeStamp: ${timeStamp}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@krypchain.io" style="color: #ffffff;">support@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`,
      }).then((message) => {
        
      });

      //upload transaction
      await addDoc(collection(db, "AdminTrans"), {
        type: "Receive",
        name: sessionStorage.getItem("name"),
        email: sessionStorage.getItem("email"),
        coin: this.coinList[Number(this.coinNumber)].name,
        symbol: this.coinList[Number(this.coinNumber)].symbol,
        image: this.coinList[Number(this.coinNumber)].image,
        address: this.coinList[Number(this.coinNumber)].address,
        amount: "",
        status: "pending",
        amountUsd: "",
        timeStamp: serverTimestamp(),
        userId: sessionStorage.getItem("id"),
        walletId: this.coinList[Number(this.coinNumber)].walletId,
      });

      this.$refs.loadingModal.classList.remove("bg-active");
      // window.open("https://buy.ramp.network/?defaultAsset="+this.ssymbol+"&fiatCurrency=USD&fiatValue="+this.buyAmount+"&hostApiKey=9842oj9c45xuzc93bm7zd7z4rn8cub3fs45decqh&swapAsset="+this.ssymbol+"&userAddress="+this.coinList[Number(this.coinNumber)].address, "_blank");

      setTimeout(() => {

        window.open("https://app.ramp.network/?defaultAsset="+this.ssymbol+"&fiatCurrency=USD&fiatValue="+this.buyAmount+"&hostApiKey=9842oj9c45xuzc93bm7zd7z4rn8cub3fs45decqh&swapAsset="+sessionStorage.getItem("rampId")+"&userAddress="+this.coinList[Number(this.coinNumber)].address, "_blank");

      });
    },

    async minusBalFix(walletId){
      const walletRef = doc(db, "UserWallets", String(walletId));
      await updateDoc(walletRef, {
          balance: 0
      });
    },

    getSpotTotal(usdBalance) {
      if (Number(usdBalance) == 0) {
        this.spotTotal = 0.0;
        this.spotTotalBTC = 0.0;

        return;
      }

      //get btc price
      axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC&tsyms=USD"
        )
        .then((response) => {
          let price = Number(Object.values(response.data.RAW)[0].USD.PRICE);

          let tempBTC = Number(usdBalance) / price;

          this.spotTotal = Number(usdBalance).toLocaleString("us-US");
          this.spotTotalBTC = this.round(tempBTC, 7);
        });
    },
  },
};
</script>
