<template>
    
    <RecoveryBody/>
    
</template>
  
<script>
  import { defineComponent } from "vue";
  import RecoveryBody from "@/components/account/RecoveryBody.vue"; 
  
  export default defineComponent({
    name: "RecoveryView",
    components: {
      RecoveryBody
    },
  });
</script>
  