<template>
    
    <div class="outer">

        <!-- HEADER -->
        <HeaderB/>

        <!-- BODY -->
        <!-- container-->
        <div class="outer__inner">
                <div class="wallet">

                    <!-- <Sidebar/> -->
                    
                    <div class="wallet__wrapper ww" style="scroll-behavior: smooth;">

                        <div class="margin" >
                            <h4 class="margin__title h4" style="color:#ffffff">Overview</h4>
                            <div class="margin__list">
                                
                                <div class="margin__item margin__item_flex" style="padding-bottom: 8px;">
                                    
                                    <div class="margin__details">
                                        <div class="margin__info">Locked amount</div>
                                        <div class="margin__currency">
                                            <!-- <div class="margin__number" style="color: #ffffff;">{{totalStakeBtc}}</div> -->
                                            <div class="margin__number" style="color: #ffffff;">${{totalStakeD}}</div>
                                            <!-- <div class="category-orange margin__category" style="background-color: #F7931A;">BTC</div> -->
                                            
                                        </div>

                                        <!-- <div class="margin__price">${{totalStakeD}}</div> -->
                                    </div>
                                </div>

                                
                                <div class="margin__item margin__item_flex" style="padding-bottom: 8px;">
                                    
                                    <div class="margin__details">
                                        <div class="margin__info">Pending rewards</div>
                                        <div class="margin__currency">
                                            <div class="margin__number" style="color: #ffffff;">${{totalRewardD}}</div>
                                            <!-- <div class="category-orange margin__category" style="background-color: #F7931A;">BTC</div> -->
                                            
                                        </div>

                                        <!-- <div class="margin__price">${{totalRewardD}}</div> -->
                                    </div>
                                </div>

                                <div class="margin__item margin__item_flex" style="padding-bottom: 8px;">
                                    
                                    <div class="margin__details">
                                        <a href="#history">
                                            <button class="button-black button-small wallet__button"> View staking history</button>
                                        </a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        

                        <div class="wallet__list" >
                            <div class="wallet__item">
                                
                                
                                
                                <div class="wallet__body" >
                                    <div class="balances" >
                                        
                                        <!-- FIXED -->
                                        <div class="balances__list" >

                                            <br>
                                            <hr style="opacity: 0.1;">
                                            <br>
                                            <h3 style="color:#ffffff; margin-left: 40px; margin-top: 20px;">Fixed staking</h3>
                                            <br>

                                            <div class="balances__row">
                                                <div class="balances__col">Asset</div>
                                                <div class="balances__col"></div>
                                                <div class="balances__col">30 days</div>
                                                <div class="balances__col">90 days</div>
                                                <div class="balances__col">180 days</div>
                                            </div>

                                            <div class="balances__item " v-for="st in stakingList" :key="st.symbol" >
                                                <div class="balances__row">
                                                    <div class="balances__col">
                                                        <div class="balances__currency">
                                                            <div class="balances__icon">
                                                                <img :src="st.image" alt="Currency" style="border-radius:50%">
                                                            </div>
                                                            <div class="balances__details">
                                                                <div class="balances__info">{{st.symbol}}</div>
                                                                <div class="balances__text">{{st.name}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="balances__col " >

                                                        <button class="button button-small balances__button"  @click="stakePrep(st.number)"  style="padding: 15px;">Stake {{st.symbol}}</button>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info color-green">{{st.days30}}%</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info color-green">{{st.days90}}%</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info color-green">{{st.days180}}%</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    
                                                </div>
                                            </div>


                                        </div>

                                        <!-- LIQUID -->
                                        <div class="balances__list" >

                                            <br>
                                            <hr style="opacity: 0.1;">
                                            <br>
                                            <h3 style="color:#ffffff; margin-left: 40px; margin-top: 20px;">Liquid staking</h3>
                                            <br>

                                            <div class="balances__row">
                                                <div class="balances__col">Asset</div>
                                                <div class="balances__col"></div>
                                                <div class="balances__col">APY</div>
                                                <div class="balances__col">Minimum</div>
                                                <!-- <div class="balances__col">180 days</div> -->
                                            </div>

                                            <div class="balances__item " v-for="st in liquidList" :key="st.symbol" >
                                                <div class="balances__row">
                                                    <div class="balances__col">
                                                        <div class="balances__currency">
                                                            <div class="balances__icon">
                                                                <img :src="st.image" alt="Currency" style="border-radius:50%">
                                                            </div>
                                                            <div class="balances__details">
                                                                <div class="balances__info">{{st.symbol}}</div>
                                                                <div class="balances__text">{{st.name}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="balances__col " >

                                                        <button class="button button-small balances__button"  @click="stakePrepLiquid(st)"  style="padding: 15px;">Stake {{st.symbol}}</button>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info color-green">{{st.apy}}%</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info">${{st.minimum}}</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                   
                                                    
                                                </div>
                                            </div>


                                        </div>

                                        <!-- STABLE -->
                                        <div class="balances__list" >

                                            <br>
                                            <hr style="opacity: 0.1;">
                                            <br>
                                            <h3 style="color:#ffffff; margin-left: 40px; margin-top: 20px;">Stable staking</h3>
                                            <br>

                                            <div class="balances__row">
                                                <div class="balances__col">Asset</div>
                                                <div class="balances__col"></div>
                                                <div class="balances__col">30 days</div>
                                                <div class="balances__col">60 days</div>
                                                <div class="balances__col">90 days</div>
                                            </div>

                                            <div class="balances__item " v-for="st in stableList" :key="st.symbol" >
                                                <div class="balances__row">
                                                    <div class="balances__col">
                                                        <div class="balances__currency">
                                                            <div class="balances__icon">
                                                                <img :src="st.image" alt="Currency" style="border-radius:50%">
                                                            </div>
                                                            <div class="balances__details">
                                                                <div class="balances__info">{{st.symbol}}</div>
                                                                <div class="balances__text">{{st.name}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="balances__col " >

                                                        <button class="button button-small balances__button"  @click="stakePrepStable(st)"  style="padding: 15px;">Stake {{st.symbol}}</button>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info color-green">{{st.days30}}%</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info color-green">{{st.days60}}%</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info color-green">{{st.days90}}%</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    
                                                </div>
                                            </div>


                                        </div>

                                        <!-- ETH 2.0 -->
                                        <div class="balances__list" style="margin-bottom: 100px;">

                                            <br>
                                            <hr style="opacity: 0.1;">
                                            <br>
                                            <h3 style="color:#ffffff; margin-left: 40px; margin-top: 20px;">ETH 2.0 staking</h3>
                                            <br>

                                            <div class="balances__row">
                                                <div class="balances__col">Asset</div>
                                                <div class="balances__col"></div>
                                                <div class="balances__col">APY</div>
                                                <div class="balances__col">Duration</div>
                                                <div class="balances__col">Minimum</div>
                                            </div>

                                            <div class="balances__item " v-for="st in ethList" :key="st.symbol" >
                                                <div class="balances__row">
                                                    <div class="balances__col">
                                                        <div class="balances__currency">
                                                            <div class="balances__icon">
                                                                <img :src="st.image" alt="Currency" style="border-radius:50%">
                                                            </div>
                                                            <div class="balances__details">
                                                                <div class="balances__info">{{st.symbol}}</div>
                                                                <div class="balances__text">{{st.name}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="balances__col " >

                                                        <button class="button button-small balances__button"  @click="stakePrepEth(st)"  style="padding: 15px;">Stake {{st.symbol}}</button>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info color-green">{{st.apy}}%</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info">{{st.days}} days</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    <div class="balances__col">
                                                        <div class="balances__info ">{{st.minimum}} {{ st.symbol }}</div>
                                                        <div class="balances__text"></div>
                                                    </div>
                                                    
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- FIXED HISTORY -->
                        <div class="wallet__list" style="margin-top: 0px;" id="yesT">

                            <div class="activity__wrapper" id="history">

                                <h3 style="color:#ffffff">Fixed staking history</h3>
                                <br>

                                <div class="activity__table" style="width: 100%; ">
                                    <div class="activity__row">
                                        <div class="activity__col">
                                            <div class="sorting">Status</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Coin</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Locked amount</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Locked period</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">APY</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Reward</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Start date</div>
                                        </div>
                                        <div class="activity__col">End date</div>
                                    </div>

                                    <!-- ROWS -->
                                    <div class="activity__row" v-for="rewards in rewardsList" :key="rewards.number">
                                        <div class="activity__col">
                                            <div class="category-yellow activity__category" v-if="rewards.status == 'in progress'">{{rewards.status}}</div>
                                            <div class="category-green activity__category" v-else>completed</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__coin">
                                                <div class="activity__icon">
                                                    <img :src="rewards.image" alt="coin" style="border-radius: 50%;">
                                                </div>
                                                <div class="activity__currency">{{rewards.symbol}}</div>
                                            </div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Locked amount</div>
                                            {{rewards.lockedAmount}} {{rewards.symbol}}
                                      
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Locked period</div>
                                            {{rewards.lockedPeriod}} days
                                      
                                        </div>
                                        <div class="activity__col " style="color: #07cf50;">
                                            <div class="activity__label">APY</div>
                                            {{rewards.apy}}%
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Reward</div>
                                            {{rewards.reward}} {{rewards.symbol}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Start date</div>
                                            {{rewards.startDate}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">End date</div>
                                            {{rewards.endDate}}
                                      
                                        </div>
                                    </div>
        
                                    
                                    
                                </div>

                            </div>

                        </div>

                        <!-- LIQUID HISTORY -->
                        <div class="wallet__list" style="margin-top: 0px;" id="yesT1">

                            <div class="activity__wrapper">

                                <h3 style="color:#ffffff; margin-top: 50px">Liquid staking history</h3>
                                <br>

                                <div class="activity__table" style="width: 100%; ">
                                    <div class="activity__row">
                                        <div class="activity__col">
                                            <div class="sorting">Status</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Coin</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Locked amount</div>
                                        </div>
                                        
                                        <div class="activity__col">
                                            <div class="sorting">APY</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Reward</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Start date</div>
                                        </div>
                                        <div class="activity__col">Withdraw</div>
                                    </div>

                                    <!-- ROWS -->
                                    <div class="activity__row" v-for="rewards in liquidRewardsList" :key="rewards.number">
                                        <div class="activity__col">
                                            <div class="category-yellow activity__category" v-if="rewards.status == 'in progress'">{{rewards.status}}</div>
                                            <div class="category-green activity__category" v-else>completed</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__coin">
                                                <div class="activity__icon">
                                                    <img :src="rewards.image" alt="coin" style="border-radius: 50%;">
                                                </div>
                                                <div class="activity__currency">{{rewards.symbol}}</div>
                                            </div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Locked amount</div>
                                            {{rewards.lockedAmount}} {{rewards.symbol}}
                                      
                                        </div>
                                        
                                        <div class="activity__col " style="color: #07cf50;">
                                            <div class="activity__label">APY</div>
                                            {{rewards.apy}}%
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Reward</div>
                                            {{rewards.reward}} {{rewards.symbol}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Start date</div>
                                            {{rewards.startDate}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Wihdraw</div>
                                            <button @click="withdraw(rewards)" class="button button-small balances__button"  style="padding: 15px; max-width: 150px">Withdraw</button>
                                      
                                        </div>
                                    </div>
        
                                    
                                    
                                </div>

                            </div>

                        </div>

                        <!-- STABLE HISTORY -->
                        <div class="wallet__list" style="margin-top: 0px;" id="yesT2">

                            <div class="activity__wrapper">

                                <h3 style="color:#ffffff; margin-top: 50px">Stable staking history</h3>
                                <br>

                                <div class="activity__table" style="width: 100%; ">
                                    <div class="activity__row">
                                        <div class="activity__col">
                                            <div class="sorting">Status</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Coin</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Locked amount</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Locked period</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">APY</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Reward</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Start date</div>
                                        </div>
                                        <div class="activity__col">End date</div>
                                    </div>

                                    <!-- ROWS -->
                                    <div class="activity__row" v-for="rewards in stableRewardsList" :key="rewards.number">
                                        <div class="activity__col">
                                            <div class="category-yellow activity__category" v-if="rewards.status == 'in progress'">{{rewards.status}}</div>
                                            <div class="category-green activity__category" v-else>completed</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__coin">
                                                <div class="activity__icon">
                                                    <img :src="rewards.image" alt="coin" style="border-radius: 50%;">
                                                </div>
                                                <div class="activity__currency">{{rewards.symbol}}</div>
                                            </div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Locked amount</div>
                                            {{rewards.lockedAmount}} {{rewards.symbol}}
                                      
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Locked period</div>
                                            {{rewards.lockedPeriod}} days
                                      
                                        </div>
                                        <div class="activity__col " style="color: #07cf50;">
                                            <div class="activity__label">APY</div>
                                            {{rewards.apy}}%
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Reward</div>
                                            {{rewards.reward}} {{rewards.symbol}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Start date</div>
                                            {{rewards.startDate}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">End date</div>
                                            {{rewards.endDate}}
                                      
                                        </div>
                                    </div>
        
                                    
                                    
                                </div>

                            </div>

                        </div>

                        <!-- ETH HISTORY -->
                        <div class="wallet__list" style="margin-top: 0px; margin-bottom: 100px" id="yesT3">

                            <div class="activity__wrapper">

                                <h3 style="color:#ffffff; margin-top: 50px">ETH 2.0 staking history</h3>
                                <br>

                                <div class="activity__table" style="width: 100%; ">
                                    <div class="activity__row">
                                        <div class="activity__col">
                                            <div class="sorting">Status</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Coin</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Locked amount</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Locked period</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">APY</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Reward</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Start date</div>
                                        </div>
                                        <div class="activity__col">End date</div>
                                    </div>

                                    <!-- ROWS -->
                                    <div class="activity__row" v-for="rewards in ethRewardsList" :key="rewards.number">
                                        <div class="activity__col">
                                            <div class="category-yellow activity__category" v-if="rewards.status == 'in progress'">{{rewards.status}}</div>
                                            <div class="category-green activity__category" v-else>completed</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__coin">
                                                <div class="activity__icon">
                                                    <img :src="rewards.image" alt="coin" style="border-radius: 50%;">
                                                </div>
                                                <div class="activity__currency">{{rewards.symbol}}</div>
                                            </div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Locked amount</div>
                                            {{rewards.lockedAmount}} {{rewards.symbol}}
                                      
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Locked period</div>
                                            {{rewards.lockedPeriod}} days
                                      
                                        </div>
                                        <div class="activity__col " style="color: #07cf50;">
                                            <div class="activity__label">APY</div>
                                            {{rewards.apy}}%
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Reward</div>
                                            {{rewards.reward}} {{rewards.symbol}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Start date</div>
                                            {{rewards.startDate}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">End date</div>
                                            {{rewards.endDate}}
                                      
                                        </div>
                                    </div>
        
                                    
                                    
                                </div>

                            </div>

                        </div>

                    </div> 
                    
                    
                </div>

                

        </div>

    </div>


    <!-- MODALS -->
    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999; " class="loadingModalBg " ref="stakeModal">

        <div class="popup popup_withdraw" >
                    <div class="popup__title h4">
                        <svg class="icon icon-arrow-left">
                            <use xlink:href="#icon-arrow-left"></use>
                        </svg>
                        Stake {{ssymbol}}
          
                    </div>
                    
                    <div class="popup__wrap">
                        <div class="popup__category">
                            Available <br>balance
                        </div>
                        <div class="popup__details">
                            <div class="popup__currency">{{sbalanceDisplay}} {{ssymbol}}</div>
                            <div class="popup__price">${{sbalanceUsdDisplay}}</div>
                        </div>
                    </div>

                    <div class="field">
                        <div class="field__label">Amount (USD)</div>
                        <div class="field__wrap">
                            <input class="field__input" type="number" v-model="stakeAmount" name="Amount(USD)" required placeholder="$1000 minimum"/>
                        </div>
                        
                    </div>

                    <div class="field">
                        <div class="field__label">Duration / APY</div>
                        <div class="field__wrap">

                            <select v-model="duration"  style="color:#ffffff; padding:20px 15px 20px 15px; border-radius: 10px; background-color: #242731; width: 100%;">
                                <option value="" disabled selected hidden>Select duration</option>
                                <option :value="{days:'30', apy:sdays30}"> 30 days - - - {{sdays30}}% APY </option>
                                <option :value="{days:'90', apy:sdays90}"> 90 days - - -  {{sdays90}}% APY</option>
                                <option :value="{days:'180', apy:sdays180}"> 180 days - - -  {{sdays180}}% APY</option>
                            </select>
                            
                        </div>
                    </div>

                    
                    <!-- <div class="field">
                        <div class="field__label">Password</div>
                        <div class="field__wrap">
                            <input class="field__input" type="password" name="password" placeholder="******" required/>
                        </div>
                    </div> -->
                    
                    <button class="button popup__button " @click="stake">Stake {{ssymbol}}</button>

                    <div style="width:100%; display:flex; justify-content:center; align-items:center">
                        <button class="category-red" style="border-radius:10px; padding:10px; margin-top:15px; " @click="closeStake">close</button>
                    </div>
            </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999; " class="loadingModalBg " ref="liquidStakeModal">

        <div class="popup popup_withdraw" >
                    <div class="popup__title h4">
                        <svg class="icon icon-arrow-left">
                            <use xlink:href="#icon-arrow-left"></use>
                        </svg>
                        Stake {{ssymbol}}
          
                    </div>
                    

                    <div class="field">
                        <div class="field__label">Amount (USD)</div>
                        <div class="field__wrap">
                            <input class="field__input" type="number" v-model="stakeAmount" name="Amount(USD)" required :placeholder="'$'+sMinimum"/>
                        </div>
                        
                    </div>

                    <div class="field">
                        <div class="field__label">APY</div>
                        <div class="field__wrap">

                            <h3 class="color-green">{{ sApy }}%</h3>
                            
                        </div>
                    </div>

                    
                    <!-- <div class="field">
                        <div class="field__label">Password</div>
                        <div class="field__wrap">
                            <input class="field__input" type="password" name="password" placeholder="******" required/>
                        </div>
                    </div> -->
                    
                    <button class="button popup__button " @click="stakeLiquid">Stake {{ssymbol}}</button>

                    <div style="width:100%; display:flex; justify-content:center; align-items:center">
                        <button class="category-red" style="border-radius:10px; padding:10px; margin-top:15px; " @click="closeLiquidStake">close</button>
                    </div>
            </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999; " class="loadingModalBg " ref="stableStakeModal">

        <div class="popup popup_withdraw" >
                    <div class="popup__title h4">
                        <svg class="icon icon-arrow-left">
                            <use xlink:href="#icon-arrow-left"></use>
                        </svg>
                        Stake {{ssymbol}}
          
                    </div>
                    
                    

                    <div class="field">
                        <div class="field__label">Amount (USD)</div>
                        <div class="field__wrap">
                            <input class="field__input" type="number" v-model="stakeAmount" name="Amount(USD)" required :placeholder="'$'+sMinimum+' minimum'"/>
                        </div>
                        
                    </div>

                    <div class="field">
                        <div class="field__label">Duration / APY</div>
                        <div class="field__wrap">

                            <select v-model="duration"  style="color:#ffffff; padding:20px 15px 20px 15px; border-radius: 10px; background-color: #242731; width: 100%;">
                                <option value="" disabled selected hidden>Select duration</option>
                                <option :value="{days:'30', apy:sdays30}"> 30 days - - - {{sdays30}}% APY </option>
                                <option :value="{days:'60', apy:sdays60}"> 60 days - - -  {{sdays60}}% APY</option>
                                <option :value="{days:'90', apy:sdays90}"> 90 days - - -  {{sdays90}}% APY</option>
                            </select>
                            
                        </div>
                    </div>

                    
                    <!-- <div class="field">
                        <div class="field__label">Password</div>
                        <div class="field__wrap">
                            <input class="field__input" type="password" name="password" placeholder="******" required/>
                        </div>
                    </div> -->
                    
                    <button class="button popup__button " @click="stakeStable">Stake {{ssymbol}}</button>

                    <div style="width:100%; display:flex; justify-content:center; align-items:center">
                        <button class="category-red" style="border-radius:10px; padding:10px; margin-top:15px; " @click="closeStableStake">close</button>
                    </div>
            </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999; " class="loadingModalBg " ref="ethStakeModal">

        <div class="popup popup_withdraw" >
                    <div class="popup__title h4">
                        <svg class="icon icon-arrow-left">
                            <use xlink:href="#icon-arrow-left"></use>
                        </svg>
                        Stake {{ssymbol}}
          
                    </div>
                    

                    <div class="field">
                        <div class="field__label">Amount (ETH)</div>
                        <div class="field__wrap">
                            <input class="field__input" type="number" v-model="stakeAmountETH" name="Amount(ETH)" required :placeholder="'Minimum of '+sMinimum+' ETH'"/>
                        </div>
                        
                    </div>

                    <div class="field">
                        <div class="field__label">APY & Duration</div>
                        <div class="field__wrap">

                            <!--<h3 style="color:#fff"><span class="color-green">{{ sApy }}%</span> for {{ duration }} days</h3>-->

                            <h3 style="color:#fff"><span class="color-green">4%</span>  daily</h3>
                            
                        </div>
                    </div>

                    
                    <!-- <div class="field">
                        <div class="field__label">Password</div>
                        <div class="field__wrap">
                            <input class="field__input" type="password" name="password" placeholder="******" required/>
                        </div>
                    </div> -->
                    
                    <button class="button popup__button " @click="stakeEth">Stake {{ssymbol}}</button>

                    <div style="width:100%; display:flex; justify-content:center; align-items:center">
                        <button class="category-red" style="border-radius:10px; padding:10px; margin-top:15px; " @click="closeEthStake">close</button>
                    </div>
            </div>

    </div>


</template>

<script>

    import HeaderB from "@/components/dashboard/Header.vue";
    import Sidebar from "@/components/dashboard/Sidebar.vue";

    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc, orderBy } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {

        components: { 
            
            HeaderB,
            Sidebar
        
        },

        mounted(){

            document.getElementById("twallet").classList.remove("active");
            document.getElementById("tswap").classList.remove("active");
            document.getElementById("tstaking").classList.add("active");
            document.getElementById("tbot").classList.remove("active");
            document.getElementById("ttran").classList.remove("active");

            this.getLiquidStakeTrans();
            this.reward();
            //this.getStakeTrans();
           
        },

        data(){
            return{
                errorText:"",
                successText:"",
                loadingText:"",
                sl:[],
                marketPrice:[],

                stakingList: [],
                ethList: [],
                liquidList: [],
                stableList: [],
                balanceList:[],
                sApy: "",
                sMinimum: "",

                sname: "",
                simage: "",
                ssymbol:"",
                sbalance: "",
                sbalanceUsd:"",
                sbalanceDisplay:"",
                sbalanceUsdDisplay: "",
                swalletId: "",
                sdays30: "",
                sdays60: "",
                sdays90: "",
                sdays180: "",

                stakeAmount: 0,
                stakeAmountETH: "",
                duration: "",
                sprice: 0,
                rewardsList:[],
                totalStake: 0,
                totalStakeD: 0,
                totalStakeBtc:0,
                totalReward: 0,
                totalRewardD: 0,
                totalRewardBtc:0,

                liquidRewardsList:[],
                stableRewardsList:[],
                ethRewardsList:[],
                
            }
        },

        methods: {
           
            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeStake(){
                this.$refs.stakeModal.classList.remove("bg-active");
            },

            closeLiquidStake(){
                this.$refs.liquidStakeModal.classList.remove("bg-active");
            },

            closeEthStake(){
                this.$refs.ethStakeModal.classList.remove("bg-active");
            },

            closeStableStake(){
                this.$refs.stableStakeModal.classList.remove("bg-active");
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getEthStaking(){

                let i = -1;
                const q = query(collection(db, "EthStaking")); 
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    i++;

                    this.ethList[i] = {
                        number: i,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        apy: doc.data().apy,
                        minimum: doc.data().minimum,
                        days: doc.data().days,
                    }
                
                })

                this.getLiquidStaking();

            },

            async getLiquidStaking(){

                let i = -1;
                const q = query(collection(db, "LiquidStaking")); 
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    i++;

                    this.liquidList[i] = {
                        number: i,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        apy: doc.data().apy,
                        minimum: doc.data().minimum,
                    }
                
                })

                this.getStableStaking();

            },

            async getStableStaking(){

                let i = -1;
                const q = query(collection(db, "StableStaking")); 
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    i++;

                    this.stableList[i] = {
                        number: i,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        days30: doc.data().days30,
                        days60: doc.data().days60,
                        days90: doc.data().days90,
                        minimum: doc.data().minimum,
                    }
                
                })

                this.getStakingInfo();

            },

            async getStakingInfo(){

                let i = -1;
                const q = query(collection(db, "AminStaking")); 
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    i++;

                    this.stakingList[i] = {
                        number: i,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        days30: doc.data().days30,
                        days90: doc.data().days90,
                        days180: doc.data().days180,
                    }
                
                })

                this.getCoinSymbols();
                

            },

            async getCoinSymbols(){

                let i = -1;
                const querySnapshot = await getDocs(collection(db, "AminStaking"));
                
                querySnapshot.forEach((doc) => {
                    i++;
                    this.getMarketData(i,doc.data().symbol);
                })
                

            },

            getMarketData(i,symbol){
                
                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+symbol+'&tsyms=USD')
                .then( response => {

                    this.marketPrice[i] = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                    this.getBalances(i, symbol);
                    
                }); 

                this.$refs.loadingModal.classList.remove("bg-active");
            },

            async getBalances(i,symbol){

                let a;

                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", symbol), );

                    const querySnapshot = await getDocs(q);

                    querySnapshot.forEach((doc) => {

                        a = Number(doc.data().balance) * Number(this.marketPrice[i]);

                        this.balanceList[i] = {
                            balance: doc.data().balance,
                            balanceDisplay: Number(doc.data().balance).toFixed(6),
                            balanceUsd: a,
                            balanceUsdDisplay: Number(a).toLocaleString("us-US"),
                            walletId: doc.id,
                        }
                    });

                // console.log(this.balanceList)
                
            },

            stakePrep(num){

                let i = Number(num);
                

                this.swalletId = this.balanceList[i].walletId;
                this.simage =   this.stakingList[i].image;
                this.sname = this.stakingList[i].name;
                this.ssymbol = this.stakingList[i].symbol;
                this.sbalance = Number(this.balanceList[i].balance);
                this.sbalanceDisplay = this.balanceList[i].balanceDisplay;
                this.sbalanceUsd = Number(this.balanceList[i].balanceUsd);
                this.sbalanceUsdDisplay = this.balanceList[i].balanceUsdDisplay;
                this.sdays30 = this.stakingList[i].days30;
                this.sdays90 = this.stakingList[i].days90;
                this.sdays180 = this.stakingList[i].days180;
                this.sprice = this.marketPrice[i];

                this.$refs.stakeModal.classList.add("bg-active");
            },

            stakePrepLiquid(liquid){
                this.ssymbol = liquid.symbol;
                this.sMinimum = liquid.minimum;
                this.simage = liquid.image;
                this.sname = liquid.name;
                this.sApy = liquid.apy;
                this.$refs.liquidStakeModal.classList.add("bg-active");
            },

            stakePrepEth(eth){
                this.ssymbol = eth.symbol;
                this.sMinimum = eth.minimum;
                this.simage = eth.image;
                this.sname = eth.name;
                this.sApy = eth.apy;
                this.duration = eth.days;
                this.$refs.ethStakeModal.classList.add("bg-active");
            },

            stakePrepStable(stable){
                this.ssymbol = stable.symbol;
                this.sMinimum = stable.minimum;
                this.simage = stable.image;
                this.sname = stable.name;
                this.sdays30 = stable.days30;
                this.sdays60 = stable.days60;
                this.sdays90 = stable.days90;
                this.$refs.stableStakeModal.classList.add("bg-active");
            },

            async stake(){

                //start loading
                this.loadingText= "Staking "+this.ssymbol+"...";
                this.$refs.loadingModal.classList.add("bg-active");

                
                //check stake amount
                if(Number(this.stakeAmount) < 1000){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "The minimum amount for staking is $1000";
                    return;

                }

                //check balance
                if(Number(this.sbalanceUsd) < Number(this.stakeAmount)){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+this.ssymbol+" balance";
                    return;

                }

                //check select
                if(String(this.duration) == ""){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Please select duration";
                    return;

                }

                //update balance
                let lockedAmount = Number(this.stakeAmount)/Number(this.sprice);
                let newBalance = this.sbalance - lockedAmount;

                const wRef = doc(db, "UserWallets", String(this.swalletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                let days = Number(this.duration.days);
                let apy = Number(this.duration.apy);

                //get reward
                let rApy = apy/100;
                // let reward = (Number(lockedAmount) * (rApy/365)) * Number(days);
                let reward = Number(lockedAmount) * rApy;

                //get end date
                let todaySecs = new Date().getTime()/1000;
                let durationSecs = days * 24 * 60 * 60;
                let endDateSecs = todaySecs + durationSecs;

                //upload transaction
                await addDoc(collection(db, "UserStakings"), {
                    
                    coinName:  this.sname,
                    coinImage: this.simage,
                    coinSymbol: this.ssymbol,
                    walletId: this.swalletId,
                    userId: sessionStorage.getItem("id"),
                    apy: apy,
                    lockedPeriod: days,
                    lockedAmount: this.round(Number(lockedAmount), 7),
                    reward: this.round(Number(reward), 7),
                    startDate: serverTimestamp(),
                    endDate: Number(endDateSecs),
                    status: "in progress"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = this.ssymbol+" has been staked successfully";

            },

            async stakeLiquid(){

                let balance, coinAmount, price, walletId, newBalance;

                //start loading
                this.loadingText= "Staking "+this.ssymbol+"...";
                this.$refs.loadingModal.classList.add("bg-active");

                
                //check stake amount
                if(Number(this.stakeAmount) < this.sMinimum){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "The minimum amount for staking is $"+this.sMinimum;
                    return;

                }

                //get coinAmount
                await axios
                .get(
                "https://min-api.cryptocompare.com/data/pricemultifull?fsyms="+this.ssymbol+"&tsyms=USD"
                )
                .then((response) => {
                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                });

                coinAmount = this.stakeAmount/price
                console.log("coin amount: "+coinAmount);

                //balance
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", this.ssymbol) );

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {

                    walletId = doc.id;
                    balance = doc.data().balance;

                })

                if(balance < coinAmount){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+this.ssymbol+" balance";
                    return;
                }

                newBalance = balance - coinAmount;

                const wRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //upload transaction
                await addDoc(collection(db, "UserLiquidStakings"), {
                    coinName:  this.sname,
                    coinImage: this.simage,
                    coinSymbol: this.ssymbol,
                    walletId: walletId,
                    userId: sessionStorage.getItem("id"),
                    apy: this.sApy,
                    lockedPeriod: 0,
                    lockedAmount: this.round(Number(coinAmount), 7),
                    reward: 0,
                    startDate: serverTimestamp(),
                    endDate: 0,
                    status: "in progress"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = this.ssymbol+" has been staked successfully";

            },

            async stakeStable(){

                let balance, coinAmount, price, walletId, newBalance;

                //start loading
                this.loadingText= "Staking "+this.ssymbol+"...";
                this.$refs.loadingModal.classList.add("bg-active");

                
                //check stake amount
                if(Number(this.stakeAmount) < this.sMinimum){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "The minimum amount for Stable staking is $"+this.sMinimum;
                    return;

                }

                //check select
                if(String(this.duration) == ""){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Please select duration";
                    return;

                }

                //get coinAmount
                await axios
                .get(
                "https://min-api.cryptocompare.com/data/pricemultifull?fsyms="+this.ssymbol+"&tsyms=USD"
                )
                .then((response) => {
                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                });

                coinAmount = this.stakeAmount/price
                console.log("coin amount: "+coinAmount);

                //balance
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", this.ssymbol) );

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {

                    walletId = doc.id;
                    balance = doc.data().balance;

                })

                if(balance < coinAmount){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+this.ssymbol+" balance";
                    return;
                }

                newBalance = balance - coinAmount;

                const wRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                let days = Number(this.duration.days);
                let apy = Number(this.duration.apy);

                //get reward
                let rApy = apy/100;
                // let reward = (Number(lockedAmount) * (rApy/365)) * Number(days);
                let reward = Number(coinAmount) * rApy;

                //get end date
                let todaySecs = new Date().getTime()/1000;
                let durationSecs = days * 24 * 60 * 60;
                let endDateSecs = todaySecs + durationSecs;

                //upload transaction
                await addDoc(collection(db, "UserStableStakings"), {
                    
                    coinName:  this.sname,
                    coinImage: this.simage,
                    coinSymbol: this.ssymbol,
                    walletId: walletId,
                    userId: sessionStorage.getItem("id"),
                    apy: apy,
                    lockedPeriod: days,
                    lockedAmount: this.round(Number(coinAmount), 7),
                    reward: this.round(Number(reward), 7),
                    startDate: serverTimestamp(),
                    endDate: Number(endDateSecs),
                    status: "in progress"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = this.ssymbol+" has been staked successfully";

            },

            async stakeEth(){

                let balance, coinAmount, price, walletId, newBalance;

                //start loading
                this.loadingText= "Staking "+this.ssymbol+"...";
                this.$refs.loadingModal.classList.add("bg-active");

                //NAN
                if(isNaN(this.stakeAmountETH)){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Invalid amount";
                    return;

                }
                
                //check stake amount
                if(Number(this.stakeAmountETH) < this.sMinimum){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "The minimum amount for ETH 2.0 staking is "+this.sMinimum+" ETH";
                    return;

                }

                //get coinAmount
                // await axios
                // .get(
                // "https://min-api.cryptocompare.com/data/pricemultifull?fsyms="+this.ssymbol+"&tsyms=USD"
                // )
                // .then((response) => {
                //     price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                // });

                coinAmount = Number(this.stakeAmountETH)
                //console.log("coin amount: "+coinAmount);

                //balance
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", this.ssymbol) );

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {

                    walletId = doc.id;
                    balance = doc.data().balance;

                })

                if(balance < coinAmount){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+this.ssymbol+" balance";
                    return;
                }

                newBalance = balance - coinAmount;

                const wRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                let days = Number(this.duration);
                let apy = Number(this.sApy);

                //get reward
                let rApy = apy/100;
                // let reward = (Number(lockedAmount) * (rApy/365)) * Number(days);
                let reward = Number(coinAmount) * rApy;

                //get end date
                let todaySecs = new Date().getTime()/1000;
                let durationSecs = days * 24 * 60 * 60;
                let endDateSecs = todaySecs + durationSecs;

                //upload transaction
                await addDoc(collection(db, "UserEthStakings"), {
                    
                    coinName:  this.sname,
                    coinImage: this.simage,
                    coinSymbol: this.ssymbol,
                    walletId: walletId,
                    userId: sessionStorage.getItem("id"),
                    apy: apy,
                    lockedPeriod: days,
                    lockedAmount: Number(coinAmount),
                    reward: this.round(Number(reward), 7),
                    startDate: serverTimestamp(),
                    endDate: Number(endDateSecs),
                    status: "in progress"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = this.ssymbol+" has been staked successfully";

            },

            async reward(){

                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                //get staking trans
                let todaySecs = new Date().getTime()/1000;

                //Fixed staking
                const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id"))); 
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    querySnapshot.forEach((doc) => {

                        //check endDate;
                        if( todaySecs  > doc.data().endDate && doc.data().status == 'in progress'){

                            //staking complete
                            this.updateStaking(doc.data().walletId, doc.data().reward, doc.data().lockedAmount, doc.id, doc.data().coinSymbol);

                        }

                    })
                }

                //Stable staking
                const q2 = query(collection(db, "UserStableStakings"), where("userId", "==", sessionStorage.getItem("id"))); 
                const querySnapshot2 = await getDocs(q2);

                if(querySnapshot2.docs.length > 0){
                    querySnapshot2.forEach((doc) => {

                        //check endDate;
                        if( todaySecs  > doc.data().endDate && doc.data().status == 'in progress'){

                            //staking complete
                            this.updateStableStaking(doc.data().walletId, doc.data().reward, doc.data().lockedAmount, doc.id, doc.data().coinSymbol);

                        }

                    })
                }

                //Eth staking
                const q3 = query(collection(db, "UserEthStakings"), where("userId", "==", sessionStorage.getItem("id"))); 
                const querySnapshot3 = await getDocs(q3);

                if(querySnapshot3.docs.length > 0){
                    querySnapshot3.forEach((doc) => {

                        //check endDate;
                        if( todaySecs  > doc.data().endDate && doc.data().status == 'in progress'){

                            //staking complete
                            this.updateEthStaking(doc.data().walletId, doc.data().reward, doc.data().lockedAmount, doc.id, doc.data().coinSymbol);

                        }

                    })
                }

                this.getStakeTrans();

            },

            async updateStaking(walletId, reward, lockedAmount, docId, symbol){

                let balance, newBalance, price, newReward, newLockedAmount;

                //get balance
                const walletRef = doc(db, "UserWallets", walletId);
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    balance = Number(walletSnap.data().balance);
                }

                //if J
                if(sessionStorage.getItem("id") == "James Rosenberg89265471000000"){

                    await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+symbol+'&tsyms=USD')
                    .then( response => {

                        price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                        
                    }); 


                    newLockedAmount = price * lockedAmount;
                    newReward = price * reward;

                    //update balance
                    newBalance = balance + Number(newReward) + Number(newLockedAmount);
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    const stakeRef = doc(db, "UserStakings", docId);
                    await updateDoc(stakeRef, {
                        status: "completed"
                    });

                }

                else{
                    //update balance
                    newBalance = balance + Number(reward) + Number(lockedAmount);
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    const stakeRef = doc(db, "UserStakings", docId);
                    await updateDoc(stakeRef, {
                        status: "completed"
                    });
                }
            },

            async updateEthStaking(walletId, reward, lockedAmount, docId, symbol){

                let balance, newBalance, price, newReward, newLockedAmount;

                //get balance
                const walletRef = doc(db, "UserWallets", walletId);
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    balance = Number(walletSnap.data().balance);
                }

                //if J
                if(sessionStorage.getItem("id") == "James Rosenberg89265471000000"){

                    await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+symbol+'&tsyms=USD')
                    .then( response => {

                        price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                        
                    }); 


                    newLockedAmount = price * lockedAmount;
                    newReward = price * reward;

                    //update balance
                    newBalance = balance + Number(newReward) + Number(newLockedAmount);
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    const stakeRef = doc(db, "UserEthStakings", docId);
                    await updateDoc(stakeRef, {
                        status: "completed"
                    });

                }

                else{
                    //update balance
                    newBalance = balance + Number(reward) + Number(lockedAmount);
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    const stakeRef = doc(db, "UserEthStakings", docId);
                    await updateDoc(stakeRef, {
                        status: "completed"
                    });
                }
            },

            async updateStableStaking(walletId, reward, lockedAmount, docId, symbol){

                let balance, newBalance, price, newReward, newLockedAmount;

                //get balance
                const walletRef = doc(db, "UserWallets", walletId);
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    balance = Number(walletSnap.data().balance);
                }

                //if J
                if(sessionStorage.getItem("id") == "James Rosenberg89265471000000"){

                    await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+symbol+'&tsyms=USD')
                    .then( response => {

                        price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                        
                    }); 


                    newLockedAmount = price * lockedAmount;
                    newReward = price * reward;

                    //update balance
                    newBalance = balance + Number(newReward) + Number(newLockedAmount);
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    const stakeRef = doc(db, "UserStableStakings", docId);
                    await updateDoc(stakeRef, {
                        status: "completed"
                    });

                }

                else{
                    //update balance
                    newBalance = balance + Number(reward) + Number(lockedAmount);
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    const stakeRef = doc(db, "UserStableStakings", docId);
                    await updateDoc(stakeRef, {
                        status: "completed"
                    });
                }
            },

            async getStakeTrans(){
                

                const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3;
                let temL, tempR;
                               
                querySnapshot.forEach((doc) => {
                    i++;

                    if(doc.data().status == 'in progress' ){
                        this.getStakeTotal(doc.data().coinSymbol, doc.data().lockedAmount);
                        this.getRewardsTotal(doc.data().coinSymbol, Number(doc.data().reward));
                    }

                    a2 = String(new Date(Number(doc.data().startDate.seconds) * 1000));
                    a3 = String(new Date(Number(doc.data().endDate) * 1000));
                    

                    this.rewardsList[i] = {
                        number: i,
                        id: doc.id,
                        apy: Number(doc.data().apy),
                        endDateSecs: Number(doc.data().endDate),
                        endDate: a3.slice(0, -36),
                        symbol: doc.data().coinSymbol,
                        image: doc.data().coinImage,
                        lockedAmount: Number(doc.data().lockedAmount),
                        lockedPeriod: Number(doc.data().lockedPeriod),
                        name: doc.data().coinName,
                        reward: doc.data().reward,
                        startDate: a2.slice(0, -36),
                        startDateSecs: Number(doc.data().startDate.seconds),
                        status: doc.data().status,
                        
                        userId: doc.data().userId,
                        walletId: doc.data().walletId,
                    }
                    
                })

                if(this.rewardsList.length < 1){
                    document.getElementById("yesT").style.display= 'none';
                }

                //get totalBTC
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC&tsyms=USD')
                .then( response => {

                    let price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                    
                    //stake
                    let totalTemp = this.totalStake / price;
                    this.totalStakeBtc  = this.round(totalTemp, 7);

                    //reward
                    let totalTemp2 = this.totalReward / price;
                    this.totalRewardBtc  = this.round(totalTemp2, 7);
                    
                });
                

                this.getEthStaking();
                 
            },

            async getLiquidStakeTrans(){
                
                const q = query(collection(db, "UserLiquidStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3;
                let temL, tempR, mReward;
                               
                querySnapshot.forEach((doc) => {
                    i++;

                    if(doc.data().status == 'in progress' ){
                        this.getStakeTotal(doc.data().coinSymbol, doc.data().lockedAmount);
                        this.getRewardsTotal(doc.data().coinSymbol, Number(doc.data().reward));
                    }

                    let todaySecs = new Date().getTime()/1000;
                    let checkSecs = todaySecs - doc.data().startDate.seconds;
                    let days = checkSecs/(60 * 60 * 24);

                    let rApy = doc.data().apy/100;
                    mReward = doc.data().lockedAmount * (rApy/365) * Number(days);

                    a2 = String(new Date(Number(doc.data().startDate.seconds) * 1000));
                    a3 = String(new Date(Number(doc.data().endDate) * 1000));
                    

                    this.liquidRewardsList[i] = {
                        number: i,
                        id: doc.id,
                        apy: Number(doc.data().apy),
                        endDateSecs: Number(doc.data().endDate),
                        endDate: a3.slice(0, -36),
                        symbol: doc.data().coinSymbol,
                        image: doc.data().coinImage,
                        lockedAmount: Number(doc.data().lockedAmount),
                        lockedPeriod: Number(doc.data().lockedPeriod),
                        name: doc.data().coinName,
                        reward: mReward,
                        startDate: a2.slice(0, -36),
                        startDateSecs: Number(doc.data().startDate.seconds),
                        status: doc.data().status,
                        
                        userId: doc.data().userId,
                        walletId: doc.data().walletId,
                    }
                    
                })

                if(this.liquidRewardsList.length < 1){
                    document.getElementById("yesT1").style.display= 'none';
                }
                

                this.getStableTrans();
                 
            },

            async getStableTrans(){

                const q = query(collection(db, "UserStableStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3;
                let temL, tempR;
                               
                querySnapshot.forEach((doc) => {
                    i++;

                    if(doc.data().status == 'in progress' ){
                        this.getStakeTotal(doc.data().coinSymbol, doc.data().lockedAmount);
                        this.getRewardsTotal(doc.data().coinSymbol, Number(doc.data().reward));
                    }

                    a2 = String(new Date(Number(doc.data().startDate.seconds) * 1000));
                    a3 = String(new Date(Number(doc.data().endDate) * 1000));
                    

                    this.stableRewardsList[i] = {
                        number: i,
                        id: doc.id,
                        apy: Number(doc.data().apy),
                        endDateSecs: Number(doc.data().endDate),
                        endDate: a3.slice(0, -36),
                        symbol: doc.data().coinSymbol,
                        image: doc.data().coinImage,
                        lockedAmount: Number(doc.data().lockedAmount),
                        lockedPeriod: Number(doc.data().lockedPeriod),
                        name: doc.data().coinName,
                        reward: doc.data().reward,
                        startDate: a2.slice(0, -36),
                        startDateSecs: Number(doc.data().startDate.seconds),
                        status: doc.data().status,
                        
                        userId: doc.data().userId,
                        walletId: doc.data().walletId,
                    }
                    
                })

                if(this.stableRewardsList.length < 1){
                    document.getElementById("yesT2").style.display= 'none';
                }

                this.getEthTrans();

            },

            async getEthTrans(){

                const q = query(collection(db, "UserEthStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3;
                let temL, tempR;
                               
                querySnapshot.forEach((doc) => {
                    i++;

                    if(doc.data().status == 'in progress' ){
                        this.getStakeTotal(doc.data().coinSymbol, doc.data().lockedAmount);
                        this.getRewardsTotal(doc.data().coinSymbol, Number(doc.data().reward));
                    }

                    a2 = String(new Date(Number(doc.data().startDate.seconds) * 1000));
                    a3 = String(new Date(Number(doc.data().endDate) * 1000));
                    

                    this.ethRewardsList[i] = {
                        number: i,
                        id: doc.id,
                        apy: Number(doc.data().apy),
                        endDateSecs: Number(doc.data().endDate),
                        endDate: a3.slice(0, -36),
                        symbol: doc.data().coinSymbol,
                        image: doc.data().coinImage,
                        lockedAmount: Number(doc.data().lockedAmount),
                        lockedPeriod: Number(doc.data().lockedPeriod),
                        name: doc.data().coinName,
                        reward: doc.data().reward,
                        startDate: a2.slice(0, -36),
                        startDateSecs: Number(doc.data().startDate.seconds),
                        status: doc.data().status,
                        
                        userId: doc.data().userId,
                        walletId: doc.data().walletId,
                    }
                    
                })

                if(this.ethRewardsList.length < 1){
                    document.getElementById("yesT3").style.display= 'none';
                }

                

            },

            async withdraw(liquid){
            
                this.loadingText= "Withdrawing "+liquid.symbol+"...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                let balance = 0;

                //check days
                let todaySecs = new Date().getTime()/1000;
                let checkSecs = todaySecs - liquid.startDateSecs;
                
                let days = checkSecs/(60 * 60 * 24);

                if(days < 10){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum durartion for withdrawal on liquid staking is 10 days";
                    return;
                }


                //get reward
                let rApy = liquid.apy/100;
                let reward = liquid.lockedAmount * (rApy/365) * Number(days);

                //get balance
                const walletRef = doc(db, "UserWallets", liquid.walletId);
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    balance = walletSnap.data().balance;
                }

                //update balance
                let newBalance = balance + reward + liquid.lockedAmount;
                await updateDoc(walletRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //update status
                const stakeRef = doc(db, "UserLiquidStakings", liquid.id);
                await updateDoc(stakeRef, {
                    status: "completed"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "Withdrawal completed";

            },

            getStakeTotal(symbol, amount){

                let price, totalTemp;

                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+symbol+'&tsyms=USD')
                .then( response => {

                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                    totalTemp = price * amount;
                    this.totalStake += totalTemp;
                    this.totalStakeD = Number(this.totalStake).toLocaleString("us-US");
                    
                    
                }); 

            },

            getRewardsTotal(symbol, amount){

                let price, totalTemp;

                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+symbol+'&tsyms=USD')
                .then( response => {

                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                    totalTemp = price * amount;
                    this.totalReward += totalTemp;
                    this.totalRewardD = Number(this.totalReward).toLocaleString("us-US");
                    
                    
                }); 

            },
            
        }
        
    }
</script>