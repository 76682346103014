<template>
    
    <section class="contact-us-wrapper section-padding">
        <div class="container">
            <div class="row text-center">
                <!-- <div class="col-md-6 col-xl-4">
                    <div class="single-contact-box box1">
                        <div class="icon" style="background-color:#1F2128">
                            <i class="fas fa-phone"></i>
                        </div>
                        <div class="contact-info">
                            <span>+088-436-258-001</span>
                            <span></span>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-6 col-xl-4">
                    <div class="single-contact-box box2">
                        <div class="icon" style="background-color:#1F2128">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="contact-info">
                            <span>help@krypchain.io</span>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4">
                    <div class="single-contact-box box2">
                        <div class="icon" style="background-color:#1F2128">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="contact-info">
                            <span>support@krypchain.io</span>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4">
                    <div class="single-contact-box box2">
                        <div class="icon" style="background-color:#1F2128">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="contact-info">
                            <span>info@krypchain.io</span>
                            
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6 col-xl-4">
                    <div class="single-contact-box box3">
                        <div class="icon" style="background-color:#1F2128">
                            <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <div class="contact-info">
                
                            <span> Bergwiesen 28, 8047 Zurich  Switzerland</span>
                            
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row mt-4 mt-lg-5">
                <div class="col-lg-8 col-xl-6 offset-xl-3 offset-lg-2 text-center">
                    <div class="block-contents">
                        <div class="section-title mb-4">
                            <h2 class="wow fadeInUp">Get in touch now!</h2>
                            <p class="wow fadeInUp pt-15" data-wow-delay=".3s">Got inquiry or some feedback for us? Fill out the form below and we will get back to you within 5 hours.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="width: 100%; display: flex; justify-content: center; align-items: center;">
                
                <div class="col-lg-6 pls-lg-4 wow fadeInUp" >
                    <form @submit.prevent="sendEmail"  class="contact-form row">
                        <div class="col-12">
                            <input type="text" placeholder="Name" required v-model="name">
                        </div>
                        <div class="col-12">
                            <input type="email" placeholder="Email Address" required v-model="email">
                        </div>
                        
                        <div class="col-12 ">
                        <textarea placeholder="Write your message " required v-model="message"></textarea>
                    </div>
                    <div class="col-12 ">
                        <button class="theme-btn " ref="btn"  style="background-color: #145eff; border-color:#145eff">{{btn}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </section>

</template>

<script>
    export default {
        

        data(){
            return{
                name:"",
                email: "",
                message: "",
                btn: "Send message"
            }
        },

        methods:{
            sendEmail(){
                this.btn = 'Sending message...';
                this.$refs.btn.disabled = true;

                let name = this.name;
                let email = this.email;
                let message = this.message;

                //send email

                Email.send({
                    SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
                    To : "help@krypchain.io",
                    From : "support@krypchain.io",
                    Subject : "Contact form",
                    Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New message from contact page 
            </div>  

            <div style=" font-size: 14px; font-weight: 700; margin-bottom: 15px; color: #ffffff;">
                User details
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Message: ${message}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@krypchain.io" style="color: #ffffff;">support@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2022 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                }).
                then((message) => {
                    console.log(message);
                    this.$refs.btn.disabled = true;
                    this.btn = "Message sent successfully"
                    
                })
            }
        }

    }
</script>