<template>
    
    <AuthBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import AuthBody from "@/components/dashboard/AuthBody.vue"; 
  
  export default defineComponent({
    name: "AuthView",
    components: {
      
        AuthBody
      
    },
  });
</script>
  