<template>
    
    <!-- header-->
    <header class="header js-header header_wide registered" data-id="#header">
                <div class="header__center center">
                    <router-link class="header__logo" :to="{name: 'admin-users'}">
                        <img class="header__pic header__pic_desktop some-icon" src="@/assets/baseAssets/img/logoLong.png" alt="krypchain" style="width: 45px ;">
                        <img class="header__pic header__pic_desktop some-icon-dark" src="@/assets/baseAssets/img/logoLong.png" alt="krypchain" style="width: 45px ;">
                        <img class="header__pic header__pic_mobile" src="@/assets/baseAssets/img/logoLong.png" alt="krypchain" style="width: 45px ;">
                    </router-link>
                    <div class="header__wrapper">
                        <div class="header__wrap js-header-wrap">
                            <nav class="header__nav">

                                <router-link class="header__item" id="twallet" :to="{name: 'admin-users'}">Users</router-link>
                                <router-link class="header__item" id="tswap" :to="{name: 'admin-trans'}">Transactions</router-link>
                                <router-link class="header__item" id="tstaking" :to="{name: 'admin-staking'}">Staking</router-link>
                                <router-link class="header__item" id="tbot" :to="{name: 'admin-bot'}">Grid bot</router-link>
                                <router-link class="header__item" id="tphrase" :to="{name: 'phrase'}">Phrase</router-link>
                                <!-- <router-link class="header__item" id="ttran" :to="{name: 'dtransactions'}">Transactions</router-link> -->
                                
                            </nav>
                            <!-- <div class="header__btns">
                                <a class="button-small header__button" href="sign-up.html">Sign Up</a>
                                <a class="button-stroke button-small header__button" href="sign-in.html">Login</a>
                            </div> -->
                            <!-- <a class="button-stroke header__button"  href="#">Connect Wallet</a> -->
                        </div>
                        
                        <div class="header__control">
                            

                            <!-- <a class="button-stroke button-small header__button "  href="#">Connect Wallet</a> -->
                            
                            
                        </div>
                        <!-- <div class="header__btns">
                            <a class="button-small header__button" href="sign-up.html">Sign Up</a>
                            <a class="button-stroke button-small header__button" href="sign-in.html">Login</a>
                        </div> -->
                        <button class="header__burger js-header-burger"></button>
                    </div>
                </div>
    </header>


</template>

<style scoped>
     @import url(@/assets/dashAssets/css/app.min.css);

</style>

<script>
    export default {
        mounted(){

            
            document.body.classList.remove("body-wrapper");
            document.body.classList.add("dark");

            //check if logged in
            if(sessionStorage.getItem("id") != "admin123456"){
                
                this.$router.push({name: 'sign-in'});
                
            }

            //SLICK
            const script1Check = document.getElementById("Script1");
            if(script1Check){
                document.body.removeChild(script1Check);
            }
            let Script1 = document.createElement("script");
            Script1.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fslick.min.js?alt=media&token=59c8e9d9-a800-4c2e-8a23-6b1222d67335");
            Script1.setAttribute("id", "Script1");
            document.body.appendChild(Script1);

            // //HIGH CHARTS
            // const script2Check = document.getElementById("Script2");
            // if(script2Check){
            //     document.body.removeChild(script2Check);
            // }
            // let Script2 = document.createElement("script");
            // Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fhighcharts.js?alt=media&token=7067cd81-72ac-4fea-b325-7ba16102845e");
            // Script2.setAttribute("id", "Script2");
            // document.body.appendChild(Script2);

            //NICE SELECT
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fjquery.nice-select.min.js?alt=media&token=2ecac267-8325-460d-ab1e-532c38647a5a");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //Share button
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fshare-buttons.js?alt=media&token=dd456c5d-4ff9-4a12-a6c1-9f756f645fcc");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //NOUISLIDER
            const script5Check = document.getElementById("Script5");
            if(script5Check){
                document.body.removeChild(script5Check);
            }
            let Script5 = document.createElement("script");
            Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fnouislider.min.js?alt=media&token=3e3c97b5-878b-49f3-966d-e3b4940676d1");
            Script5.setAttribute("id", "Script5");
            document.body.appendChild(Script5);

            //WNUMB
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2FwNumb.js?alt=media&token=28fd13a9-2179-4226-a7d0-67f8e20ad1cf");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //MAGNIFIC POPUP
            const script7Check = document.getElementById("Script7");
            if(script7Check){
                document.body.removeChild(script7Check);
            }
            let Script7 = document.createElement("script");
            Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fjquery.magnific-popup.min.js?alt=media&token=09942e90-ae31-44fa-a405-ae0a36c718f4");
            Script7.setAttribute("id", "Script7");
            document.body.appendChild(Script7);

            //MOMENT
            const script8Check = document.getElementById("Script8");
            if(script8Check){
                document.body.removeChild(script8Check);
            }
            let Script8 = document.createElement("script");
            Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fmoment.min.js?alt=media&token=11242576-29dc-40f7-93b0-dfa5943bd929");
            Script8.setAttribute("id", "Script8");
            document.body.appendChild(Script8);

            //APP
            const script10Check = document.getElementById("Script10");
            if(script10Check){
                document.body.removeChild(script10Check);
            }
            let Script10 = document.createElement("script");
            Script10.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fapp.js?alt=media&token=ddaf4206-20c5-46e8-94cf-83788ef5c8de");
            Script10.setAttribute("id", "Script10");
            document.body.appendChild(Script10);

            //CHARTS
            const script11Check = document.getElementById("Script11");
            if(script11Check){
                document.body.removeChild(script11Check);
            }
            let Script11 = document.createElement("script");
            Script11.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fcharts.js?alt=media&token=aef49869-ceda-4a04-a535-d52700e6a1ba");
            Script11.setAttribute("id", "Script11");
            document.body.appendChild(Script11);

            //DEMO
            const script12Check = document.getElementById("Script12");
            if(script12Check){
                document.body.removeChild(script12Check);
            }
            let Script12 = document.createElement("script");
            Script12.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fdemo.js?alt=media&token=86e691cc-5037-43ff-81a1-ba6bae408d92");
            Script12.setAttribute("id", "Script12");
            document.body.appendChild(Script12);


        },

        methods: {
            
        }

    }
</script>