<template>
    
    <div class="outer">
    <!-- HEADER -->
    <HeaderB />

    <div class="outer__inner">
      <div
        class="activity__table"
        style="width: 100%; margin-top: 20px; padding-left: 10px; padding-right: 10px;"
      >
        <div class="activity__row" style="width: 100%">
          
            <h3 style="color: #fff;">Admin Staking</h3>
          
        </div>

        <div class="activity__row" v-for="wal in userList" :key="wal.id">

            <h3 style="padding-top: 20px; color: #fff;">{{ wal.name }}</h3>

        <div class="field" style="margin-top: 30px;">
            <div class="field__label">{{ wal.symbol }} 30 days APY</div>
            <div class="field__wrap">
                <input class="field__input" type="text" :value="wal.days30" :id="wal.symbol+'30'">
            </div>
        </div>

        <br>

        <div class="field" style="margin-top: 30px;">
            <div class="field__label">{{ wal.symbol }} 60 days APY</div>
            <div class="field__wrap">
                <input class="field__input" type="text" :value="wal.days60" :id="wal.symbol+'60'">
            </div>
        </div>

        <br>

        <div class="field" style="margin-top: 30px;">
            <div class="field__label">{{ wal.symbol }} 90 days APY</div>
            <div class="field__wrap">
                <input class="field__input" type="text" :value="wal.days90" :id="wal.symbol+'90'">
            </div>
        </div>

        <br>

        <div class="field" style="margin-top: 30px;">
            <div class="field__label">{{ wal.symbol }} 180 days APY</div>
            <div class="field__wrap">
                <input class="field__input" type="text" :value="wal.days180" :id="wal.symbol+'180'">
            </div>
        </div>

        <br>

        <div class="field">
            <div class="field__label" style="padding-top: 30px;"></div>
            <div class="field__wrap">
                <button class="button button__small" style="margin-left: 20px; margin-bottom: 30px;" @click="save(wal)">Save</button>
            </div>
            
        </div>

        <br>
        <hr><br>

        </div>


      </div>
    </div>
  </div>

  <!-- MODALS -->
  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="loadingModal"
  >
    <div
      class="popup__container"
      style="width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img src="@/assets/dashAssets/img/loading.gif" alt="" />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ loadingText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="successModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/success.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ successText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeS">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="errorModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/error.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ errorText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeE">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HeaderB from "@/components/master/Header.vue";
import {
  doc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  serverTimestamp,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "@/firebase";

export default {
  components: {
    HeaderB,
  },

  data() {
    return {
      loadingText: "",
      successText: "",
      errorText: "",

      userName: "",
      userList: [],
    };
  },

  mounted() {
    this.getInfoList();
  },

  methods: {
    closeS() {
      this.$refs.successModal.classList.remove("bg-active");
      this.$router.go();
    },

    closeE() {
      this.$refs.errorModal.classList.remove("bg-active");
    },

    async getInfoList() {
      this.loadingText = "Getting info...";
      this.$refs.loadingModal.classList.add("bg-active");

      let i = -1;
      let a2;

      //get wallet balances
      const q = query(collection(db, "AminStaking"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        i++;
        
        this.userList[i] = {

          id: String(doc.id),
          number: i,
          name: doc.data().name,
          symbol: doc.data().symbol,
          days30: doc.data().days30,
          days60: doc.data().days60,
          days90: doc.data().days90,
          days180: doc.data().days180
          
        };
      });

      this.$refs.loadingModal.classList.remove("bg-active");
    },

    async save(wal){

        this.loadingText = "Saving...";
        this.$refs.loadingModal.classList.add("bg-active");

        //update address and balance
        const walletRef = doc(db, "AminStaking", wal.id);
        await updateDoc(walletRef, {
            days30: document.getElementById(""+wal.symbol+"30").value,
            days60: document.getElementById(""+wal.symbol+"60").value,
            days90: document.getElementById(""+wal.symbol+"90").value,
            days180: document.getElementById(""+wal.symbol+"180").value,
        });

        this.successText = "Successfully updated "+wal.symbol+" Staking";
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.successModal.classList.add("bg-active");
        return;

    }

  },
};
</script>