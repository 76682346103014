<template>
    
    <ForgotPasswordBody/>
    
</template>
  
<script>
  import { defineComponent } from "vue";
  import ForgotPasswordBody from "@/components/account/ForgotPasswordBody.vue"; 
  
  export default defineComponent({
    name: "ForgotPasswordView",
    components: {
      ForgotPasswordBody
    },
  });
</script>
  