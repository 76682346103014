<template>
    <Navbar/>
    <HomeBody/>
    <Footer/>
</template>
  
<script>
  import { defineComponent } from "vue";
  import Navbar from "@/components/Navbar.vue";
  import HomeBody from "@/components/HomeBody.vue";
  import Footer from "@/components/Footer.vue"; 
  
  export default defineComponent({
    name: "HomeView",
    components: {
      Navbar,
      HomeBody,
      Footer
    },
  });
</script>
  