<template>
    
    <ChangePasswordBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import ChangePasswordBody from "@/components/dashboard/ChangePasswordBody.vue"; 
  
  export default defineComponent({
    name: "ChangePasswordView",
    components: {
      
        ChangePasswordBody
      
    },
  });
</script>
  