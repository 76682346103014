<template>
    <NavbarDark/>
    <TermsBody/>
    <Footer/>
</template>
  
<script>
  import { defineComponent } from "vue";
  import NavbarDark from "@/components/NavbarDark.vue";
  import TermsBody from "@/components/TermsBody.vue";
  import Footer from "@/components/Footer.vue"; 
  
  export default defineComponent({
    name: "TermsView",
    components: {
      NavbarDark,
      TermsBody,
      Footer
    },
  });
</script>
  