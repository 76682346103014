<template>
    
    <UsersBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import UsersBody from "@/components/master/UsersBody.vue"; 
  
  export default defineComponent({
    name: "UsersView",
    components: {
      
        UsersBody
      
    },
  });
</script>
  