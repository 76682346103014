<template>
    
    <section class="cta-banner-wrapper style-1 section-padding pt-0">
        <div class="container">
            <div class="cta-banner text-white">
                <div class="row">
                    <div class="col-xxl-6 text-center text-xxl-start offset-xxl-1">
                        <div class="cta-contents">
                            <h2 class="wow fadeInUp">Sign-up & explore the crypto world</h2>
                            <p class="wow fadeInUp" data-wow-delay=".3s" style="font-size:16px">krypchain has made crypto investing simple for millions around the world. Join the digital revolution today.</p>
                            <router-link :to="{name: 'sign-up'}" target="_blank">
                                <button class="theme-btn mt-30 wow fadeInUp" style="background-color: #145eff; color:#ffffff" data-wow-duration="1s" data-wow-delay=".6s">Create an account</button>
                            </router-link>
                            <div class="tri-arrow wow fadeInRight d-none d-md-inline-block" data-wow-delay="1s">
                                <img src="@/assets/baseAssets/img/tir-shape.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-5 pe-xxl-5">
                        <div class="cta-mobile-app wow fadeInUp" data-wow-delay=".4s" data-wow-duration="1.2">
                            <img src="@/assets/baseAssets/img/cta-right-img.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer class="footer-wrapper footer-1" style="background-color: #1f2128;">
        
        <div class="footer-bottom-wrapper">
            <div class="container">
                <div class="footer-bottom-content d-md-flex justify-content-between">
                    <div class="site-copyright wow fadeInUp" data-wow-delay=".2" data-wow-duration="1s">
                        <p>&copy; 2024 <router-link :to="{name: 'home'}">krypchain</router-link> All Rights Reserved.</p>
                    </div>
                    <!-- <div class="social-links mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".3" data-wow-duration="1s">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-instagram"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                    </div> -->
                </div>
            </div>
        </div>
    </footer>

</template>
