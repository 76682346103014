<template>
    
    <section class="contact-us-wrapper section-padding">
        <div class="container">
            <div class="row text-center">

                <h1>KrypChain User Agreement</h1>

            </div>

            <br>

            <div>
    
                <p>This agreement (the “Agreement”) is for customers who reside outside the United States of America, United Kingdom, European Economic Area, Japan and Singapore. In reviewing these terms you will see that some text is coloured in green. These clauses only apply to the regulated services provided to you by KrypChain is regulated by the UK Financial Conduct Authority. This is a contract between you and:</p>

                <br>
    
                <p>KrypChain, Ltd ("KrypChain") a private limited company incorporated in England and Wales and whose registered office address is 24 Holborn Viaduct, London EC1A 2BN, United Kingdom</p>

                <br>
    
                <p>References in this Agreement to "KrypChain", "we", "our" or "us", are to KrypChain depending on the services being discussed, and references to "you" or "your" are to the person with whom KrypChain enters into this Agreement. </p>

                <br>
    
                <p>By signing up to use an account through KrypChain.IO, or any of our associated websites, application programming interfaces (“APIs”), or mobile applications (collectively the "Site"), you agree that you have read, understood, and accept all of the terms and conditions contained in this Agreement, as well as our Privacy Policy and Cookie Policy.</p>

                <br>
    
                <p>We refer to the E-Money Services, Digital Currency Services and Additional Services (all defined below) collectively as the "KrypChain Services", which can be accessed via the platform operated by KrypChain (the “KrypChain Platform”) (including the online platform which is accessible via the Site or at such location as may be prescribed by KrypChain from time to time).</p>

                <br>
    
                <p>Access to E-Money Services is not automatic is only provided where KrypChain decides in its discretion to provide them. Each of the KrypChain Services is provided by KrypChain , as set out in clause 2 below. </p>

                <br>
    
                <p>*You should be aware that the risk of loss in trading or holding Digital Currencies can be substantial. As with any asset, the value of Digital Currencies can go up or down and there can be a substantial risk that you lose money buying, selling, holding, or investing in digital currencies.</p>

                <br>
    
                <p>Digital Currency Services and Additional Services are not currently regulated by the Financial Conduct Authority. The Digital Currency Services and Additional Services are not within scope of the jurisdiction of the UK Financial Ombudsman Service, and your Digital Currencies and E-Money are not subject to protection under the UK Financial Services Compensation Scheme.</p>

                <br>
    
                <p>You should carefully consider whether trading or holding Digital Currencies is suitable for you in light of your financial condition.*</p>
    
                
            </div>
            
            
        </div>
  
    </section>

</template>