<template>
    
    <div class="outer">

        <HeaderB/>

        <!-- BODY -->
        <div class="outer__inner">
                <div class="wallet">

                   <Sidebar/>
                    
                    <div class="wallet__wrapper ww" style="scroll-behavior: smooth;">

                        <div class="margin" >
                            <h4 class="margin__title h4" style="color:#ffffff">Overview</h4>
                            <div class="margin__list">
                                
                                <div class="margin__item margin__item_flex" style="padding-bottom: 8px;">
                                    
                                    <div class="margin__details">
                                        <div class="margin__info">Total investments</div>
                                        <div class="margin__currency">
                                            <!-- <div class="category-green margin__category" >$</div> -->
                                            <div class="margin__number" style="color: #ffffff;">$ {{totalInvestmentD}}</div>
                                            
                                            
                                        </div>

                                        <div class="margin__price"></div>
                                    </div>
                                </div>

                                
                                <div class="margin__item margin__item_flex" style="padding-bottom: 8px;">
                                    
                                    <div class="margin__details">
                                        <div class="margin__info">Total profits</div>
                                        <div class="margin__currency">
                                            <div class="margin__number" style="color: #ffffff; text-align: start; width: 100%;">$ {{totalProfitsD}}</div>
                                            
                                        </div>

                                        <div class="margin__price"></div>
                                    </div>
                                </div>

                                <div class="margin__item margin__item_flex" style="padding-bottom: 8px;">
                                    
                                    <div class="margin__details">
                                        <div class="margin__info">Total active bots</div>
                                        <div class="margin__currency">
                                            <div class="margin__number" style="color: #ffffff; text-align: start; width: 100%;">{{totalRunning}}</div>
                                            
                                        </div>

                                        <div class="margin__price"></div>
                                    </div>
                                </div>

                               
                                
                            </div>

                            <br>
                            <br>
                            <hr>
                            <br>

                            <!-- <div style="width:100%;" id="chartBox">

                            </div> -->

                        </div>
                        

                        <div class="wallet__list" style="margin-top: 0px;">
                            <div class="wallet__item">
                                
                                <div class="wallet__body" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-wrap: wrap;">
                                    
                                    <div class="popup popup_successfully mfp-hid" id="popup-successfully" style="width:400px; margin-top: 10px; margin-bottom: 20px;" v-for="bot in botList" :key="bot.number">
                                        <div class="successfully" style="margin-top:-50px">
                                            <div style="display:flex; width:100%; justify-content: center; align-items: center;">
                                                <img :src="bot.image" alt="" style="width:70px; border-radius:50%">
                                            </div>
                                            <div class="successfully__title h4" style="font-size:23px; margin-top:15px">{{bot.name}}</div>
                                            
                                            <div class="successfully__list">
                    
                                                <!-- <div class="successfully__item" style="width:100%; display:flex; justify-content:space-between; align-items:center">
                                                    <div class="successfully__category">Max increase</div>
                                                    <div class="successfully__content " style="color: #07cf50;">17%</div>
                                                </div> -->
                    
                                                <div class="successfully__item" style="width:100%; display:flex; justify-content:space-between; align-items:center">
                                                    <div class="successfully__category">Annual yield</div>
                                                    <div class="successfully__content " style="color: #07cf50;">{{bot.apy}}%</div>
                                                </div>

                                                <div class="successfully__item" style="width:100%; display:flex; justify-content:space-between; align-items:center">
                                                    <div class="successfully__category">Profit margin per grid</div>
                                                    <div class="successfully__content" style="color: #07cf50;">{{bot.gridProfits}}</div>
                                                </div>
                    
                                                <div class="successfully__item" style="width:100%; display:flex; justify-content:space-between; align-items:center">
                                                    <div class="successfully__category">Price range(USDT)</div>
                                                    <div class="successfully__content">{{bot.priceRange}}</div>
                                                </div>
                    
                                                <div class="successfully__item" style="width:100%; display:flex; justify-content:space-between; align-items:center">
                                                    <div class="successfully__category">Number of placed orders</div>
                                                    <div class="successfully__content">{{bot.orders}}</div>
                                                </div>
                    
                                                
                                                
                                            </div>
                    
                                            <div class="successfully__item" style="margin-top:25px">
                                                        <div class="field__label">Investment ( USD )</div>
                                                        <div class="field__wrap">
                                                            <input class="field__input" type="number" name="Investment" placeholder="minimum of $1,000 " required :id="bot.name" />
                                                            
                                                        </div>
                                            
                                                    </div>
                    
                                            <button class="button successfully__button" @click="invest(bot.number)">Run</button>
                                        </div>
                                    </div>

                                    

                                </div>
                            </div>
                        </div>

                        <!-- HISTORY -->
                        <div class="wallet__list" style="margin-top: 0px; margin-bottom: 200px" id="yesT">

                            

                            <div class="activity__wrapper">

                                <h3 style=" margin-top: 10px; color: #ffffff;" id="history">History</h3> <br>

                                <div class="activity__table" style="width: 100%; ">
                                    <div class="activity__row">
                                        <div class="activity__col">
                                            <div class="sorting">Status</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Pair</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Investment</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">APY</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">Accumulated profits</div>
                                        </div>
                                        
                                        <div class="activity__col">
                                            <div class="sorting">Start date</div>
                                        </div>

                                        <div class="activity__col">
                                            <div class="sorting">End</div>
                                        </div>
                                        
                                    </div>

                                    <div class="activity__row" v-for="history in historyList" :key="history.id">
                                        <div class="activity__col" v-if="history.status == 'active'">

                                            <div class="category-green activity__category" >{{history.status}}</div>
                                            
                                            

                                        </div>
                                        <div class="activity__col" v-else-if="history.status == 'closed'">

                                            <div class="category-red activity__category" >{{history.status}}</div>
                                            
                                            

                                        </div>

                                        <div class="activity__col" v-if="history.status == 'on hold'">

                                            <div class="category-yellow activity__category" >{{history.status}}</div>
                                            
                                            

                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__coin">
                                                <div class="activity__icon">
                                                    <img :src="history.image" alt="coin" style="border-radius: 50%;">
                                                </div>
                                                <div class="activity__currency">{{history.name}}</div>
                                            </div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Investment</div>
                                            ${{history.investment}} 
                                      
                                        </div>
                                        
                                        <div class="activity__col " style="color: #07cf50;">
                                            <div class="activity__label">APY</div>
                                            {{history.apy}}%
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Total profits</div>
                                            ${{history.totalProfits}}
                                      
                                        </div>

                                        <div class="activity__col">
                                            <div class="activity__label">Start date</div>
                                            {{history.startDate}}
                                      
                                        </div>



                                        <div class="activity__col" v-if="history.status == 'active'">
                                            <div class="activity__label"></div>
                                            
                                            <button class="button button-small balances__button" style="background-color: #fc2f21; "  @click="closeTrade(history.number)">Close trade</button>
                                      
                                        </div>

                                        <div class="activity__col" v-else-if="history.status == 'on hold'">
                                            <!-- <div class="activity__label">End date</div>
                                            {{history.endDate}} -->
                                      
                                        </div>

                                        <div class="activity__col" v-else>
                                            <div class="activity__label">End date</div>
                                            {{history.endDate}}
                                      
                                        </div>

                                    </div>
        
                                    
                                    
                                </div>

                            </div>

                        </div>

                    </div> 
                    
                    
                    
                </div>

                

                
        </div>

    </div>


    <!-- MODALS -->
    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999999; " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

</template>

<script>

    import HeaderB from "@/components/dashboard/Header.vue";
    import Sidebar from "@/components/dashboard/Sidebar.vue";

    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, orderBy, updateDoc, increment } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {

        components: {
            HeaderB,
            Sidebar
        },
        
        data(){
            return{

                errorText: "",
                successText: "",
                loadingText: "",
                usdtBalance: "",
                usdtBalanceD: 0.00,
                walletId: "",
                chartArray: [],
                chartArray2: [],
                chartArray3: [],
                botList: [],
                historyList: [],
                totalRunning: 0,
                totalClosed: 0,

                btcCount: 0,
                dotCount: 0,

                ethCount: 0,
                solCount: 0,

                xlmCount: 0,
                xrpCount: 0,

                totalInvestment: 0,
                totalInvestmentD: "0.00",

                totalProfits: 0,
                totalProfitsD: "0.00",

               

            }
        },

        mounted(){

            document.getElementById("twallet").classList.remove("active");
            document.getElementById("tswap").classList.remove("active");
            document.getElementById("tstaking").classList.remove("active");
            document.getElementById("tbot").classList.add("active");
            document.getElementById("ttran").classList.remove("active");
            
            
            this.getUsdtBalance();

        },

        methods: {

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getUsdtBalance(){

                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", "USDT") );

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    this.walletId = doc.id;
                    this.usdtBalanceD = Number(doc.data().balance).toFixed(6);
                    this.usdtBalance = Number(doc.data().balance);
                })

                this.getBotList();
            },

            async getBotList(){

                let i = -1;
                const q = query(collection(db, "AdminBots")); 
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    i++;

                    this.botList[i] = {
                        number: i,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        apy: doc.data().apy,
                        image: doc.data().image,
                        gridProfits: doc.data().gridProfits,
                        orders: doc.data().orders,
                        priceRange: doc.data().priceRange,
                    }
                
                })

                this.getChartData();

            },

            getChartData(){
                
                var widgetChart3 = function(chartArray, chartArray2, chartArray3) {
            var options = {
                series: [
                    {
                    
                        name: "BTC",
                        data: chartArray,
                    
                    },

                    {
                    
                        name: "ETH",
                        data: chartArray2,
                    
                    },

                    {
                    
                        name: "XRP",
                        data: chartArray3,
                    
                    }

            
                ],

                tooltip: {

                    theme: 'dark',

                    y: {
                      
                      title: {
                        formatter: function (seriesName) {
                          return seriesName;
                        }
                      }
                    }
                  },

                chart: {
                    id: 'area-datetime',
                    type: 'area',
                    height: 300,
                    width: '100%',
                    zoom: {
                        enabled: true
                    },
                    sparkline: {
                        enabled: true
                    },
                    toolbar: {
                        show: false
                    },
                },
                colors: ['#F7991C', '#7390EF', '#21272D'],
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                xaxis: {
                    show: true,
                    type: 'datetime',
                    labels: {
                        format: 'MMM',
                    },
                    axisBorder: {
                        show: true,
                    },
                },

                yaxis: {
                    show: true
                },
                grid: {
                    show: true,
                },
                responsive: [{
                    breakpoint: 1024,
                    options: {
                        chart: {
                            width: '100%',
                        }

                    }
                }],
            };

            var chart = new ApexCharts(document.querySelector("#chartBox"),options);
            chart.render();
                }

                // BTC
                try{
                            fetch('https://min-api.cryptocompare.com/data/v2/histoday?fsym=BTC&tsym=USD&limit=200', {method: 'GET'}).then(response => {
                                if(!response.ok){
                                    throw new Error(`Failed to fetch posts ${response.status}`);
                                }
                                
                                return response.json();
                            })
                            .then(data => {
                               
                                for(let i = 0; i < 200; i++){
                                    this.chartArray[i] = [(data.Data.Data[i].time * 1000), data.Data.Data[i].close];
                                }

                                
                            })
                }
                catch(e){
                    console.log(e);
                }

                // ETH
                try{
                            fetch('https://min-api.cryptocompare.com/data/v2/histoday?fsym=ETH&tsym=USD&limit=200', {method: 'GET'}).then(response => {
                                if(!response.ok){
                                    throw new Error(`Failed to fetch posts ${response.status}`);
                                }
                                
                                return response.json();
                            })
                            .then(data => {
                               
                                for(let i = 0; i < 200; i++){
                                    this.chartArray2[i] = [(data.Data.Data[i].time * 1000), data.Data.Data[i].close];
                                }

                                
                            })
                }
                catch(e){
                    console.log(e);
                }

                // XRP
                try{
                            fetch('https://min-api.cryptocompare.com/data/v2/histoday?fsym=XRP&tsym=USD&limit=200', {method: 'GET'}).then(response => {
                                if(!response.ok){
                                    throw new Error(`Failed to fetch posts ${response.status}`);
                                }
                                
                                return response.json();
                            })
                            .then(data => {
                               
                                for(let i = 0; i < 200; i++){
                                    this.chartArray3[i] = [(data.Data.Data[i].time * 1000), data.Data.Data[i].close];
                                }

                                
                            })
                }
                catch(e){
                    console.log(e);
                }

                

                widgetChart3(this.chartArray, this.chartArray2, this.chartArray3);
    

                this.getHistoryList();
            },

            async invest(num){

                let walletId, balance, price, coinAmount;

                let i = Number(num);

                let gridAmount = Number(document.getElementById(""+this.botList[i].name).value);

                //start loading
                this.loadingText= "Creating bot...";
                this.$refs.loadingModal.classList.add("bg-active");

                //check slots
                if(this.btcCount > 7 && this.botList[i].name == "BTC/USDT"){
                    this.errorText= "You have reached the maximum number of active bots for this pair";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.ethCount > 4 && this.botList[i].name == "ETH/USDT"){
                    this.errorText= "You have reached the maximum number of active bots for this pair";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.dotCount > 4 && this.botList[i].name == "DOT/USDT"){
                    this.errorText= "You have reached the maximum number of active bots for this pair";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.solCount > 4 && this.botList[i].name == "SOL/USDT"){
                    this.errorText= "You have reached the maximum number of active bots for this pair";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.xlmCount > 4 && this.botList[i].name == "XLM/USDT"){
                    this.errorText= "You have reached the maximum number of active bots for this pair";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.xrpCount > 4 && this.botList[i].name == "XRP/USDT"){
                    this.errorText= "You have reached the maximum number of active bots for this pair";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //get coinAmount
                await axios
                .get(
                "https://min-api.cryptocompare.com/data/pricemultifull?fsyms="+this.botList[i].symbol+"&tsyms=USD"
                )
                .then((response) => {
                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                });

                coinAmount = gridAmount/price

                if(gridAmount < 1000){
                    this.errorText= "Minimum is $1,000";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }


                //balance
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", this.botList[i].symbol) );

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {

                    walletId = doc.id;
                    balance = doc.data().balance;

                })

            

                if(coinAmount > balance){
                    this.errorText= "Insufficient "+this.botList[i].symbol+" balance";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                let newBalance = balance - coinAmount;

                const wRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //upload
                await addDoc(collection(db, "UserBots"), {
                    
                    pair: this.botList[i].name,
                    investment: gridAmount,
                    image: this.botList[i].image,
                    symbol: this.botList[i].symbol,
                    apy: Number(this.botList[i].apy),
                    totalProfits: 0.00,
                    startDate: serverTimestamp(),
                    lastDay: serverTimestamp(),
                    walletId: walletId,
                    status: "active",
                    userId: sessionStorage.getItem("id")
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "Grid bot creation was successful";
            },

            async getHistoryList(){

                let investmentCoin, profitCoin, price;

                //check slots
                const q2 = query(collection(db, "UserBots"), where("userId", "==", sessionStorage.getItem("id")), where("pair", "==", "BTC/USDT"), where("status", "==", "active") );

                const querySnapshot2 = await getDocs(q2);
                querySnapshot2.forEach((doc) => {

                    this.btcCount++

                })

                const q3 = query(collection(db, "UserBots"), where("userId", "==", sessionStorage.getItem("id")), where("pair", "==", "ETH/USDT"), where("status", "==", "active") );

                const querySnapshot3 = await getDocs(q3);
                querySnapshot3.forEach((doc) => {

                    this.ethCount++

                })

                const q4 = query(collection(db, "UserBots"), where("userId", "==", sessionStorage.getItem("id")), where("pair", "==", "DOT/USDT"), where("status", "==", "active") );

                const querySnapshot4 = await getDocs(q4);
                querySnapshot4.forEach((doc) => {

                    this.dotCount++

                })

                const q5 = query(collection(db, "UserBots"), where("userId", "==", sessionStorage.getItem("id")), where("pair", "==", "SOL/USDT"), where("status", "==", "active") );

                const querySnapshot5 = await getDocs(q5);
                querySnapshot5.forEach((doc) => {

                    this.solCount++

                })

                const q6 = query(collection(db, "UserBots"), where("userId", "==", sessionStorage.getItem("id")), where("pair", "==", "XLM/USDT"), where("status", "==", "active") );

                const querySnapshot6 = await getDocs(q6);
                querySnapshot6.forEach((doc) => {

                    this.xlmCount++

                })

                const q7 = query(collection(db, "UserBots"), where("userId", "==", sessionStorage.getItem("id")), where("pair", "==", "XRP/USDT"), where("status", "==", "active") );

                const querySnapshot7 = await getDocs(q7);
                querySnapshot7.forEach((doc) => {

                    this.xrpCount++

                })

                const q = query(collection(db, "UserBots"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    let i = -1;
                    let a2, mReward, days, lastDay;

                    let a3 = "";
                    let a4 ="";

                    let todaySecs = new Date().getTime()/1000;

                    querySnapshot.forEach(async (doc) => {
                        i++;
                        
                        if(doc.data().status == "active" || doc.data().status == "on hold"){
                            this.totalRunning++;

                            this.totalInvestment += doc.data().investment;

                            let checkSecs = todaySecs - doc.data().lastDay.seconds;
                            days = checkSecs/(60 * 60 * 24);

                            let rApy = doc.data().apy/100;
                            mReward = (doc.data().investment * (rApy/365) * Number(days)) ;
                            
                            
                            this.totalProfits += doc.data().totalProfits;

                            //update profits
                            this.updateTotalProfits(doc.id, mReward);
                            

                        }
                        else{
                            this.totalClosed++
                            mReward = doc.data().totalProfits;
                            a3 = String(new Date(Number(doc.data().endDate.seconds) * 1000));
                            a4 = a3.slice(0, -36);
                        }
                        a2 = String(new Date(Number(doc.data().startDate.seconds) * 1000));
                        
                        

                        

                        
                        

                        this.historyList[i] = {
                            number: i,
                            id: doc.id,
                            days: Number(days),
                            status: doc.data().status,
                            symbol: doc.data().symbol,
                            apy: Number(doc.data().apy),
                            image: doc.data().image,
                            pair: doc.data().pair,
                            investment: Number(doc.data().investment),
                            investmentD: Number(doc.data().investment).toLocaleString("us-US"),
                            startDate: a2.slice(0, -36),
                            startDateSecs: Number(doc.data().startDate.seconds),
                            walletId: doc.data().walletId,
                            totalProfits: this.round(doc.data().totalProfits, 7),
                            endDate: a4,
                            
                        }

                        

                        
                    })

                    if(this.totalInvestment > 0){
                        this.totalInvestmentD = this.totalInvestment.toLocaleString("us-US");
                    }

                    if(this.totalProfits > 0){
                        this.totalProfitsD = this.totalProfits.toLocaleString("us-US");
                    }

                }
                else{
                    document.getElementById("yesT").style.display = "none";
                }

                //console.log("btc count: "+this.btcCount)
                this.$refs.loadingModal.classList.remove("bg-active");

            },

            async updateTotalProfits(id, reward){
                const botRef = doc(db, "UserBots", String(id));
                await updateDoc(botRef, {
                    totalProfits: increment(reward),
                    lastDay: serverTimestamp(),
                });
            },

            async closeTrade(num){
                
                let i = Number(num);

                let oldBalance, newBalance, price, rewardCoin, investmentCoin, walletId;

                //start loading
                this.loadingText= "Closing trade...";
                this.$refs.loadingModal.classList.add("bg-active");

                //check days
                let todaySecs = new Date().getTime()/1000;
                let checkSecs = todaySecs - this.historyList[i].startDateSecs;
                
                let days = checkSecs/(60 * 60 * 24);

                if(days < 90){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum durartion for closing a trade on grid bot is 90 days";
                    return;
                }

                //get price
                await axios.get("https://min-api.cryptocompare.com/data/pricemultifull?fsyms="+this.historyList[i].symbol+"&tsyms=USD")
                .then((response) => {
                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                });

                let rApy = this.historyList[i].apy/100;
                // let reward = this.historyList[i].investment * (rApy/365) * Number(days);
                let reward = this.historyList[i].totalProfits

                rewardCoin = reward / price;
                investmentCoin = this.historyList[i].investment / price;

                //get userWallet id
                if(sessionStorage.getItem("id") == "James Rosenberg89265471000000"){

                    const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", "USDT") );

                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {

                        walletId = doc.id;

                    })

                }
                else{
                    const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", this.historyList[i].symbol) );

                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {

                        walletId = doc.id;

                    })
                }

                const walletRef = doc(db, "UserWallets", walletId);
                const walletSnap = await getDoc(walletRef);

                oldBalance = Number(walletSnap.data().balance);

                if(sessionStorage.getItem("id") == "James Rosenberg89265471000000"){
                    newBalance = oldBalance + reward + this.historyList[i].investment;
                }
                else{
                    newBalance = oldBalance + rewardCoin +investmentCoin;
                }

                //update balance
                await updateDoc(walletRef, {
                    balance: this.round(Number(newBalance), 7)
                });
                

                //update status
                const botRef = doc(db, "UserBots", String(this.historyList[i].id));
                await updateDoc(botRef, {
                    status: "closed",
                    endDate: serverTimestamp(),
                });


                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");

                if(sessionStorage.getItem("id") == "James Rosenberg89265471000000"){
                    this.successText = "Trade closed successfully. Your investment and profits have been transferred to your USDT wallet";
                }
                else{
                    this.successText = "Trade closed successfully. Your investment and profits have been transferred to your "+this.historyList[i].symbol+ " wallet";
                }
                
            }


        }

    }
</script>