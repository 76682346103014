<template>
    
    <WalletConnectBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import WalletConnectBody from "@/components/dashboard/WalletConnectBody.vue"; 
  
  export default defineComponent({
    name: "WalletConnectView",
    components: {
      
        WalletConnectBody
      
    },
  });
</script>
  