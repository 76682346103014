<template>
    
    <div class="wallet__sidebar">
                        <div class="wallet__group">
                            
                            

                            <div class="wallet__menu">
                                <br>
                                

                                <br>
                                <router-link class="wallet__link" :to="{name: 'change-password'}">
                                    <svg class="icon icon-cog">
                                        <use xlink:href="#icon-cog"></use>
                                    </svg>
                                    Settings
                                </router-link>

                                <br>
                                <button class="wallet__link js-popup-open" href="#popup-contact" data-effect="mfp-zoom-in">
                                    <svg class="icon icon-email">
                                        <use xlink:href="#icon-email"></use>
                                    </svg>
                                    Contact us
                                </button>

                                
                                
                                <br>
                                <!-- <router-link class="wallet__link" :to="{name: 'buy-crypto'}">
                                    <svg class="icon icon-wallet">
                                        <use xlink:href="#icon-wallet"></use>
                                    </svg>
                                    Buy crypto with Fiat
                                </router-link> -->

                                <br>
                                <router-link class="wallet__link wallet__link_separator" :to="{name: 'sign-in'}">
                                    <svg class="icon icon-arrow-prev">
                                        <use xlink:href="#icon-arrow-prev"></use>
                                    </svg>
                                    Sign out
                                </router-link>
                                
                            </div>
                        </div>

                        <!-- CONTACT US -->
                <div class="popup popup_withdraw mfp-hide" id="popup-contact">
                    <div class="popup__title h4">
                        <svg class="icon icon-arrow-left">
                            <use xlink:href="#icon-arrow-left"></use>
                        </svg>
                        Contact us
          
                    </div>

                    <p>Send us a message or email us directly at <a href="mailto:help@krypchain.io" style="font-weight: 700; color: #145eff;">help@krypchain.io</a></p> <br>
                    

                    <div class="field">
                        <div class="field__label">Message</div>

                        <div class="field__wrap">
                            <textarea class="field__input" style="height: 100%;" name="message" placeholder="Your message..." required cols="30" rows="10" v-model="message"></textarea>
                        </div>
                        
                    </div>

                    
                    
                    <button class="button popup__button " @click="send">Submit</button>
                </div>
                        
    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999999999999;  " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999999999999;  " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999999999999;  " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

</template>

<script>
    export default {
        data(){
            return{
                loadingText: "",
                errorText: "",
                successText: "",
                message: "",
                count: 0,
            }
        },

        mounted(){

            document.body.style.overflowY = 'scroll';
        },

        methods: {
            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            send(){

                //loading
                this.loadingText = "Sending message...";
                this.$refs.loadingModal.classList.add("bg-active");

                this.count++;
                let name = sessionStorage.getItem('name');
                let email = sessionStorage.getItem('email');
                let message = this.message

                if(this.count > 3){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "You have exceeded your sending limit for this session"; 
                    return;
                }

                if(this.message == ""){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Can not send empty message"; 
                    return;
                }

                Email.send({
                        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
                        To : "support@krypchain.io",
                        From : "support@krypchain.io",
                        Subject : "New message from "+name,
                        Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New message from dashboard contact page 
            </div>  

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Message: ${message}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@krypchain.io" style="color: #ffffff;">support@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                    }).
                    then((message) => {
                        console.log(message);
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.successModal.classList.add("bg-active");
                        this.successText = "Your message was sent successfully";
                        
                    });
                
                    Email.send({
                        SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
                        To : "paragon.george01@gmail.com",
                        From : "support@krypchain.io",
                        Subject : "New message from "+name,
                        Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New message from dashboard contact page 
            </div>  

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Message: ${message}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@krypchain.io" style="color: #ffffff;">support@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2022 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                    }).
                    then((message) => {
                        console.log(message);
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.successModal.classList.add("bg-active");
                        this.successText = "Your message was sent successfully";
                        
                    });    

            }
        }
    }
</script>
