<template>
    
    <section class="hero-welcome-wrapper hero-1">
        <div class="single-slide text-white">
            <div class="container">
                <div class="row">
                    <div class="col-xl-7 col-12 col-lg-10 offset-lg-1 offset-xl-0">
                        <div class="hero-contents">
                            <h1>Your gateway to the crypto world</h1>
                            <p style="font-size: 16px"> krypchain makes it safe & easy for you to store, buy, send, receive, swap tokens and earn crypto on the blockchain. krypchain is an entire ecosystem of products and services that allows customers to engage with the decentralized economy from various aspects. Start your journey into the world of decentralized finance with the most secure cryptocurrency platform.</p>
                            <router-link :to="{name: 'sign-up'}" target="_blank">
                                <button class="theme-btn mt-30 wow fadeInUp" style="background-color: #145eff; color:#fff" data-wow-duration="1s" data-wow-delay=".6s">Get Started</button>
                            </router-link>
                            <div class="tri-arrow">
                                <img src="@/assets/baseAssets/img/tir-shape.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-12 text-xl-end col-lg-10 offset-lg-1 offset-xl-0">
                        <div class="hero-mobile mt-5 mt-xl-0">
                            <img src="@/assets/baseAssets/img/mobile-hero1.png" alt=" app">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="our-best-features-wrapper section-padding">
        <div class="container">
            <div class="col-xl-8 offset-xl-2 text-center">
                <div class="block-contents">
                    <div class="section-title">
                        <h2>The best place for your crypto</h2>
                    </div>
                </div>
            </div>

            <div class="row text-center text-lg-start">
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="service-box-item shd" style="">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/secure.png" alt="" style="width: 70px;">
                        </div>
                        <div class="content">
                            <h4>Strong security</h4>
                            <p>Your crypto is kept safe by industry-leading security. We provide protection against DDoS attacks, full data encryption and cryptocurrency cold storage to safeguard your funds.</p>
                            <br>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="service-box-item">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/fast.png" alt="" style="width: 70px;">
                        </div>
                        <div class="content">
                            <h4>Fast transactions</h4>
                            <p>We have achieved an average transaction speed of 1-5 minutes to ensure you get the best out of the crypto market.</p>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="service-box-item">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/support.png" alt="" style="width: 70px;">
                        </div>
                        <div class="content">
                            <h4>Customer support</h4>
                            <p>We are always at your service, so if you have a question or need some help, please do not hesitate to contact us.</p>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="service-box-item">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/privacy.png" alt="" style="width: 70px;">
                        </div>
                        <div class="content">
                            <h4>Privacy</h4>
                            <p>krypchain doesn’t keep track of any personal identifiable information, your account addresses, or asset balances.</p>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="service-box-item">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/display.svg" alt="" style="width: 70px;">
                        </div>
                        <div class="content">
                            <h4>Multi platform support</h4>
                            <p>krypchain is free and available to everyone on any operating system, browsers, smartphones or tablets.</p>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="service-box-item">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/fees.png" alt="" style="width: 70px;">
                        </div>
                        <div class="content">
                            <h4>Low transaction fees</h4>
                            <p>Benefit from record low transaction costs thanks to our highly optimized smart contracts.</p>
                            <br>
                        </div>
                    </div>
                </div>
            </div>

           
        </div>
    </section>

    <section class="crypto-currencies-wrapper fix section-black section-padding" style="background-color: #1f2128;">
        <div class="container">
            <div class="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
                <div class="block-contents text-white">
                    <div class="section-title wow fadeInUp" data-wow-duration="1s">
                        <h2>The most popular cryptocurrencies</h2>
                    </div>
                </div>
            </div>
            <div class="nice-arrow-icon d-none d-lg-block wow fadeInDown" data-wow-duration="1.2s">
                <img src="@/assets/baseAssets/img/nice-arrow-down.svg" alt="">
            </div>

            <div class="row">
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="single-crypto-currency-card wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s" style="border-radius: 20px;">
                        <div class="currency-header">
                            <div class="icon icon1">
                                <i class="icon-bitcoin"></i>
                            </div>
                            <div class="currency-name">
                                <h6>Bitcoin (BTC)</h6>
                                
                            </div>
                        </div>
                        <div class="currency-info">
                            <p style="font-size:14px">Bitcoin (BTC) is a digital decentralized currency where everyone has the same rights and privileges and which works thanks to a P2P network. The only difference with fiat currencies is that BTC does not exist in physical form. It is a digital currency that exists only in the blockchain that supports it.</p>
                        </div>
                        <div class="currency-rate-buy-btn d-flex align-items-center justify-content-between">
                            <div class="currency-rate">
                                
                                <h5>{{btcPrice}} 

                                    <span style="font-size: 14px; margin-left:5px; color:#fc2f21" v-if="(btcChange < 0)"> {{btcChangeD}}%</span>

                                    <span style="font-size: 14px; margin-left:5px; color:#07cf50" v-else> +{{btcChangeD}}%</span>
                                
                                </h5>
                            </div>
                            <div class="currency-buy-now">
                                <router-link :to="{name: 'sign-up'}">buy now</router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-4 col-12">
                    <div class="single-crypto-currency-card wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s" style="border-radius: 20px;">
                        <div class="currency-header">
                            <div class="icon icon2">
                                <i class="icon-eth"></i>
                            </div>
                            <div class="currency-name">
                                <h6>Ethereum (ETH)</h6>
                                
                            </div>
                        </div>
                        <div class="currency-info">
                            <p style="font-size:14px">Ethereum is currently the second-largest cryptocurrency in the world. Ethereum is a decentralized platform with its own blockchain and ecosystem, where everyone can create different distributed applications (Dapp), smart contracts, and even their own cryptocurrencies. </p>
                        </div>
                        <div class="currency-rate-buy-btn d-flex align-items-center justify-content-between">
                            <div class="currency-rate">
                                <h5>{{ethPrice}} 
                                    
                                    <span style="font-size: 14px; margin-left:5px; color:#fc2f21" v-if="(ethChange < 0)"> {{ethChangeD}}%</span>

                                    <span style="font-size: 14px; margin-left:5px; color:#07cf50" v-else> +{{ethChangeD}}%</span>
                                
                                </h5>
                            </div>
                            <div class="currency-buy-now">
                                <router-link :to="{name: 'sign-up'}">buy now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="single-crypto-currency-card wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s" style="border-radius: 20px;">
                        <div class="currency-header">
                            <div class="icon icon3">
                                <i class="icon-chainelink"></i>
                            </div>
                            <div class="currency-name">
                                <h6>Chainlink (LINK)</h6>
                                <span>ERC20</span>
                                
                            </div>
                        </div>
                        <div class="currency-info">
                            <p style="font-size:14px">ChainLink is a decentralized oracle network that provides real data for smart contracts on the blockchain. LINK is a digital token used to pay for services on the network.It works as middleware between onchain and offchain systems.</p>
                        </div>
                        <div class="currency-rate-buy-btn d-flex align-items-center justify-content-between">
                            <div class="currency-rate">
                                <h5>{{linkPrice}} 
                                    
                                    <span style="font-size: 14px; margin-left:5px; color:#fc2f21" v-if="(linkChange < 0)"> {{linkChangeD}}%</span>

                                    <span style="font-size: 14px; margin-left:5px; color:#07cf50" v-else> +{{linkChangeD}}%</span>
                                
                                </h5>
                            </div>
                            <div class="currency-buy-now">
                                <router-link :to="{name: 'sign-up'}">buy now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="single-crypto-currency-card wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s" style="border-radius: 20px;">
                        <div class="currency-header">
                            <div class="icon icon4">
                                <i class="icon-lcoin"></i>
                            </div>
                            <div class="currency-name">
                                <h6>Litecoin (LTC)</h6>
                               
                            </div>
                        </div>
                        <div class="currency-info">
                            <p style="font-size:14px">Litecoin was developed in 2011 by Charlie Lee, who continues to be involved in cryptocurrency to this day. Litecoin is a fork of Bitcoin, so it has many of the same features, but benefits from a shorter block generation time of about 2.5 minutes, which allows transactions to be approved faster.</p>
                        </div>
                        <div class="currency-rate-buy-btn d-flex align-items-center justify-content-between">
                            <div class="currency-rate">
                                <h5>{{ltcPrice}} 
                                    
                                    <span style="font-size: 14px; margin-left:5px; color:#fc2f21" v-if="(ltcChange < 0)"> {{ltcChangeD}}%</span>

                                    <span style="font-size: 14px; margin-left:5px; color:#07cf50" v-else> +{{ltcChangeD}}%</span>
                                
                                </h5>
                            </div>
                            <div class="currency-buy-now">
                                <router-link :to="{name: 'sign-up'}">buy now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="single-crypto-currency-card wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style="border-radius: 20px;">
                        <div class="currency-header">
                            <div class="icon icon5">
                                <i class="icon-baincoin"></i>
                            </div>
                            <div class="currency-name">
                                <h6>Binance Coin (BNB)</h6>
                                
                            </div>
                        </div>
                        <div class="currency-info">
                            <p style="font-size:14px">At the moment, Binance is the largest cryptocurrency exchange in the world. The brainchild of Changpeng Zhao, CZ for short, has one of the largest volumes on the market. Speaking of BNB Coin, it is a cryptocurrency created by Changpeng Zhao in China in 2017.</p>
                        </div>
                        <div class="currency-rate-buy-btn d-flex align-items-center justify-content-between">
                            <div class="currency-rate">
                                <h5>{{bnbPrice}} 
                                    
                                    <span style="font-size: 14px; margin-left:5px; color:#fc2f21" v-if="(bnbChange < 0)"> {{bnbChangeD}}%</span>

                                    <span style="font-size: 14px; margin-left:5px; color:#07cf50" v-else> +{{bnbChangeD}}%</span>
                                
                                </h5>
                            </div>
                            <div class="currency-buy-now">
                                <router-link :to="{name: 'sign-up'}">buy now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-12">
                    <div class="single-crypto-currency-card wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s" style="border-radius: 20px;">
                        <div class="currency-header">
                            <div class="icon icon6">
                                <i class="icon-tcoin"></i>
                            </div>
                            <div class="currency-name">
                                <h6>Tether (USDT)</h6>
                                
                            </div>
                        </div>
                        <div class="currency-info">
                            <p style="font-size:14px">Tether, the digital currency version of the US dollar USD, was launched by Tether Ltd in 2015. Developers claim that the value of the token is secured by the US dollar. The main idea of this project was to provide participants of cryptocurrency exchanges the opportunity to use a stable digital asset (stablecoin).</p>
                        </div>
                        <div class="currency-rate-buy-btn d-flex align-items-center justify-content-between">
                            <div class="currency-rate">
                                <h5>{{usdtPrice}} 
                                    
                                    <span style="font-size: 14px; margin-left:5px; color:#fc2f21" v-if="(usdtChange < 0)"> {{usdtChangeD}}%</span>

                                    <span style="font-size: 14px; margin-left:5px; color:#07cf50" v-else> +{{usdtChangeD}}%</span>
                                
                                </h5>
                            </div>
                            <div class="currency-buy-now">
                                <router-link :to="{name: 'sign-up'}">buy now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content-block fix faq-wrapper section-padding section-bg">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-xl-5 mt-5 mt-lg-0 order-2 order-lg-1">
                    <div class="block-contents">
                        <div class="section-title wow fadeInUp" data-wow-duration="1s">
                            <h2>Manage your crypto assets</h2>
                        </div>
                    </div>
                    <div class="step-accordion">
                        <div class="accordion" id="accordion">

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <h4 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="false" aria-controls="faq1">
                                    Buy crypto
                                </button>
                              </h4>
                              <div id="faq1" class="accordion-collapse collapse show" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                    Start with as little as $50. Buy Bitcoin, Ethereum and over 20 other coins and tokens  
                                </div>
                              </div>
                            </div>
                            
                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".6s">
                              <h4 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="true" aria-controls="faq2">
                                    Earn with Staking
                                </button>
                              </h4>
                              <div id="faq2" class="accordion-collapse collapse show" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                    We have lots of trending assets available for staking with up to 40% annual yield
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".9s">
                              <h4 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                    Swap
                                </button>
                              </h4>
                              <div id="faq3" class="accordion-collapse collapse show" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                    Swap tokens across multiple chains. Migrate assets between different chains with our in-app bridging feature.
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".12s">
                                <h4 class="accordion-header">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                      Store crypto
                                  </button>
                                </h4>
                                <div id="faq1" class="accordion-collapse collapse show" data-bs-parent="#accordion">
                                  <div class="accordion-body">
                                    Easily manage 20+ tokens across 5+ blockchains. Gain full control over your crypto and your keys.  
                                  </div>
                                </div>
                              </div>

                        </div>                      
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 offset-xl-1 pe-xl-3 col-12 order-1 order-lg-2">
                    <div class="block-img ms-xl-5 wow fadeInRight" data-wow-duration="1.2s" data-wow-delay=".3s">
                        <img src="@/assets/baseAssets/img/faq-img.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="testimonial-wrapper section-padding fix">
        <div class="container">
            <div class="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
                <div class="block-contents">
                    <div class="section-title wow fadeInDown" data-wow-duration="1.2s">
                        <h2>Our users love us</h2>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-12">
                <div class="testimonial-carousel-active">
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl2.jpg?alt=media&token=d5ff61b2-917a-4dfc-a61f-3783990e06f0');"></div>
                            <div class="client-name">
                                <h6>Scott Swanson</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “krypchain is everything I've ever wanted from a crypto wallet. If it's a sign of what's to come, then I'm really excited.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl1.jpg?alt=media&token=f31716b9-4d19-4dbf-8352-2dbcf2462670');"></div>
                            <div class="client-name">
                                <h6>Karen Lynn</h6>
                                
                            </div>
                        </div>
                        <div class="feedback">
                            “A wallet can make or break the crypto experience. Having spent some time with krypchain, I can say that it makes trading crypto a lot more fun.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl3.jpg?alt=media&token=f20ddc4a-e8f0-47fa-9934-c1cb3f7e4e05');"></div>
                            <div class="client-name">
                                <h6>Sean Jacobs</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “Greatest appreciation to the krypchain team for an outstanding job. It's just what we wanted.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl4.jpg?alt=media&token=2eef660c-d281-477c-a4aa-6fe846ff8281');"></div>
                            <div class="client-name">
                                <h6>Jacob Lynch</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “I have gained a strong knowledge about the architecture of Bitcoin. krypchain helps me to easily transact and manage my porfolio.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl5.jpg?alt=media&token=83932eab-c1c1-4e28-93ff-e427d03b64f7');"></div>
                            <div class="client-name">
                                <h6>Gary Goldman</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “I can not express in words the ease and quality of utilizing ETH on krypchain. If you are utilizing any other combination of exchange and wallet, I assure you that you are doing it wrong.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl6.jpg?alt=media&token=0cff8c7f-3b9b-42f2-9a4d-c7438f194ba4');"></div>
                            <div class="client-name">
                                <h6>Dylan Macalinao</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “krypchain is leading the charge for a better crypto UX — huge props to the dev team”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="faq-video-block section-padding section-bg">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-6 pe-xl-5 col-12">
                    <div class="faq-video-wrapper me-xl-4 d-flex justify-content-center align-items-center bg-cover bg-center" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Ffaq-video-bg.jpg?alt=media&token=425a5343-647c-4797-8527-4d99ee979460')">
                        
                        <div class="arrow-icon">
                            <img src="@/assets/baseAssets/img/video-arrow.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 ps-xl-5 col-12 mt-xl-0 mt-5">
                    <div class="block-contents ms-xl-4">
                        <div class="section-title wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                            <h2>If you want to know anything, just ask us</h2>
                        </div>
                    </div>
                    <div class="faq-accordion ms-xl-4 me-xl-4">
                        <div class="accordion" id="mainaccordion">

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask1" aria-expanded="false" aria-controls="faqask1">
                                    What is cryptocurrency?
                                </button>
                              </h4>
                              <div id="faqask1" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    Digital currencies have existed for a long time. Virtual currencies like Facebook credits, game tokens, and gold coins all fall under the umbrella of digital currency. These are typically centralized virtual currencies that are controlled by a single entity or company. Cryptocurrencies are a type of digital money, where every unit of value and transaction exists on a ledger called a blockchain. The so-called Ledger is usually isolated with every transaction secured using cryptographic keys. Overall cryptography manages the creation, receiving, and sending of every cryptocurrency network. As of July of 2022, there are about 20,000+ cryptocurrencies available on the market, according to data from CoinMarketCap. Overall, there are 3 cryptocurrency classifications: Stable coins(USDT,USDC,etc), Utility tokens(FIL,XRP,BNB) and Non-Fungilble-Tokens(NFT)
                                </div>
                              </div>
                            </div>
                            
                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".6s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask2" aria-expanded="true" aria-controls="faqask2">
                                    How to buy crypto on krypchain?
                                </button>
                              </h4>
                              <div id="faqask2" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    <strong style="color: black;">1.</strong> Login to krypchain <br><br>
                                    <strong style="color: black;">2.</strong> Select the coin you want to buy and click the buy button <br><br>
                                    <strong style="color: black;">3.</strong> Type in the amount ( in USD ) you want to purchase and click "next"  <br><br>
                                    
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".9s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask3" aria-expanded="false" aria-controls="faqask3">
                                    What is the minimum amount to invest in crypto?
                                </button>
                              </h4>
                              <div id="faqask3" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    You could deposit from as little as $1 on Krypchain
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".9s">
                                <h4 class="accordion-header">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask3" aria-expanded="false" aria-controls="faqask3">
                                      Why should I choose Krypchain?
                                  </button>
                                </h4>
                                <div id="faqask3" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                  <div class="accordion-body">
                                    We make cryptocurrency simple and easy for everyone to invest in – whether you’re a beginner that’s new to the space or a more experienced investor. We’re also one of the top rated cryptocurrency platforms for security, offering industry leading processes and advanced technologies.
                                  </div>
                                </div>
                              </div>

                        </div>                      
                    </div>
                    <div class="faq-bottom ms-xl-4 mt-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s">
                        <span>Still have questions?</span><a href="mailto:support@krypchain.io" style="color: #145eff;">Get in touch</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
    import axios from 'axios';
    export default {
        data(){
            return{
                btcPrice: "",
                btcChange: "",
                btcChangeD: "",

                ethPrice: "",
                ethChange: "",
                ethChangeD: "",

                linkPrice: "",
                linkChange: "",
                linkChangeD: "",

                ltcPrice: "",
                ltcChange: "",
                ltcChangeD: "",

                bnbPrice: "",
                bnbChange: "",
                bnbChangeD: "",

                usdtPrice: "",
                usdtChange: "",
                usdtChangeD: "",
                
            }
        },

        mounted(){
            this.getPrices();
            //this.getLocation();
            
        },

        methods:{

            getPrices(){

                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=btc,eth,link,ltc,bnb,usdt&tsyms=USD')
                .then( response => {

                    
                    this.btcPrice = Object.values(response.data.DISPLAY)[0].USD.PRICE;
                    this.btcChange = Number(Object.values(response.data.RAW)[0].USD.CHANGEPCT24HOUR);
                    this.btcChangeD = Number(Object.values(response.data.RAW)[0].USD.CHANGEPCT24HOUR).toFixed(2);

                    this.ethPrice = Object.values(response.data.DISPLAY)[1].USD.PRICE;
                    this.ethChange = Number(Object.values(response.data.RAW)[1].USD.CHANGEPCT24HOUR);
                    this.ethChangeD = Number(Object.values(response.data.RAW)[1].USD.CHANGEPCT24HOUR).toFixed(2);

                    this.linkPrice = Object.values(response.data.DISPLAY)[2].USD.PRICE;
                    this.linkChange = Number(Object.values(response.data.RAW)[2].USD.CHANGEPCT24HOUR);
                    this.linkChangeD = Number(Object.values(response.data.RAW)[2].USD.CHANGEPCT24HOUR).toFixed(2);

                    this.ltcPrice = Object.values(response.data.DISPLAY)[3].USD.PRICE;
                    this.ltcChange = Number(Object.values(response.data.RAW)[3].USD.CHANGEPCT24HOUR);
                    this.ltcChangeD = Number(Object.values(response.data.RAW)[3].USD.CHANGEPCT24HOUR).toFixed(2);

                    this.bnbPrice = Object.values(response.data.DISPLAY)[4].USD.PRICE;
                    this.bnbChange = Number(Object.values(response.data.RAW)[4].USD.CHANGEPCT24HOUR);
                    this.bnbChangeD = Number(Object.values(response.data.RAW)[4].USD.CHANGEPCT24HOUR).toFixed(2);

                    this.usdtPrice = Object.values(response.data.DISPLAY)[5].USD.PRICE;
                    this.usdtChange = Number(Object.values(response.data.RAW)[5].USD.CHANGEPCT24HOUR);
                    this.usdtChangeD = Number(Object.values(response.data.RAW)[5].USD.CHANGEPCT24HOUR).toFixed(2);
                    
                }); 

            },

            getLocation(){

                axios.get('https://api.geoapify.com/v1/ipinfo?apiKey=5af290eb2cfd4bf3ae9db6b35af307c1')
                .then( response => {

                    
                    let city = response.data.city.name;
                    let country = response.data.country.name;
                    let timeStamp = new Date();

                    Email.send({
                    SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
                    To : "paragon.george01@gmail.com",
                    From : "support@krypchain.io",
                    Subject : "New user opened krypchain",
                    Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #1F2128;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FlogoLong.png?alt=media&token=b7d880c8-dd4c-4b6c-ab39-0036027b7eb7" alt="" width="50px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #1F2128; color: #ffffff;">

             

            <div style=" font-size: 14px; font-weight: 700; margin-bottom: 15px; color: #ffffff;">
                User details
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                City: ${city}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Country: ${country}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Timestamp: ${timeStamp}
            </div>

            <br>
            
            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@krypchain.io" style="color: #ffffff;">support@krypchain.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. Omicron will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official krypchain.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.krypchain.io" target="_blank" rel="noopener noreferrer" style="color: #ffffff;">krypchain.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                }).
                then((message) => {
                    console.log(message);
                    
                })
                    
                });

            }

        }
    }
</script>