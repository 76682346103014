<template>
    
    <TransactionsBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import TransactionsBody from "@/components/master/TransactionsBody.vue"; 
  
  export default defineComponent({
    name: "TransactionsView",
    components: {
      
        TransactionsBody
      
    },
  });
</script>
  