<template>
    
    <SigninBody/>
    
</template>
  
<script>
  import { defineComponent } from "vue";
  import SigninBody from "@/components/account/SigninBody.vue"; 
  
  export default defineComponent({
    name: "SigninView",
    components: {
      SigninBody
    },
  });
</script>
  