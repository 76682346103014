<template>
    
    <UserEditBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import UserEditBody from "@/components/master/UserEditBody.vue"; 
  
  export default defineComponent({
    name: "UserEditView",
    components: {
      
        UserEditBody
      
    },
  });
</script>
  