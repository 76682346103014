<template>
    
    <div class="outer">

        <!-- HEADER -->
        <HeaderB/>

        <!-- BODY -->
        <div class="outer__inner">
                    <div class="profile">
                        
                        <div class="profile__body">
                            <div class="profile__center center">

                                <!-- PROFILE SIDEBAR -->
                                <div class="profile__sidebar">
                                    <div class="profile__dropdown">
                                        <div class="profile__top">
                                            <svg class="icon icon-lock">
                                                <use xlink:href="#icon-lock"></use>
                                            </svg>
                                            Change password
                      
                                        </div>

                                        <div class="profile__menu">
                                            
                                            <router-link class="profile__link" :to="{name: 'dash-recovery'}">
                                                <svg class="icon icon-laptop">
                                                    <use xlink:href="#icon-laptop"></use>
                                                </svg>
                                                Recovery phrase
                                            </router-link>
                                            <router-link class="profile__link" :to="{name: '2fa'}">
                                                <svg class="icon icon-barcode">
                                                    <use xlink:href="#icon-barcode"></use>
                                                </svg>
                                                2FA security
                                            </router-link>
                                            <router-link class="profile__link active" :to="{name: 'change-password'}">
                                                <svg class="icon icon-lock">
                                                    <use xlink:href="#icon-lock"></use>
                                                </svg>
                                                Change password
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="profile__wrapper">
                                    <div class="password">

                                        <form @submit.prevent="changePassword" class="password__form">
                                            <div class="password__title h3">Change password</div>

                                            <div class="field">
                                                <div class="field__label">Current password</div>
                                                <div class="field__wrap">
                                                    <input class="field__input" type="password" name="Old password" placeholder="*******************" required v-model="oldPassword">
                                                </div>
                                            </div>

                                            <div class="field">
                                                <div class="field__label">New password</div>
                                                <div class="field__wrap">
                                                    <input class="field__input" type="password" name="New password" placeholder="*******************" required v-model="newPassword">
                                                </div>
                                            </div>

                                            <div class="field">
                                                <div class="field__label">Confirm new password</div>
                                                <div class="field__wrap">
                                                    <input class="field__input" type="password" name="Confirm new password" placeholder="*******************" required v-model="cNewPassword">
                                                </div>
                                            </div>
                                            
                                            
                                            <div style="width: 100%; display:flex; justify-content:center; align-items:center">

                                                <button class="button login__button">Change password</button>

                                            </div>

                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>

    </div>

    <!-- MODALS -->
    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>


</template>

<script>
    import HeaderB from "@/components/dashboard/Header.vue";
    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc, orderBy } from "firebase/firestore"; 
    import { db } from '@/firebase';
    export default {
        
        components: { 
            
            HeaderB,
        
        },

        mounted(){
            this.getPassword();
        },

        data(){
            return{
                oldPassword: "",
                newPassword: "",
                cNewPassword: "",
                loadingText: "",
                errorText: "",
                successText: "",
                currentPassword: "",
            }
        },

        methods: {

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async getPassword(){

                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                const passwordRef = doc(db, "Users", sessionStorage.getItem('id'));
                const passwordSnap = await getDoc(passwordRef);

                if (passwordSnap.exists()) {
                    this.currentPassword = passwordSnap.data().password;
                    
                }

                this.$refs.loadingModal.classList.remove("bg-active"); 

            },

            async changePassword(){
                //start loading
                this.loadingText= "Updating password...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.oldPassword != this.currentPassword){

                    this.errorText= "Incorrect password";
                    this.$refs.loadingModal.classList.remove("bg-active"); 
                    this.$refs.errorModal.classList.add("bg-active"); 
                    return;
                }

                if(this.newPassword != this.cNewPassword){

                    this.errorText= ' "New password" does not match with "Confirm new password" ';
                    this.$refs.loadingModal.classList.remove("bg-active"); 
                    this.$refs.errorModal.classList.add("bg-active"); 
                    return;

                }

                if(this.newPassword.length < 8){
                    this.errorText= "Password can not be less than 8 characters";
                    this.$refs.loadingModal.classList.remove("bg-active"); 
                    this.$refs.errorModal.classList.add("bg-active"); 
                    return;
                }

                if(this.newPassword == this.currentPassword ){
                    this.errorText = "New password can not be the same as old password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //update password
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                    password: String(this.newPassword)
                });

                this.successText = "Password was updated successfully"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");

            }

        }

    }
</script>