<template>
    
    <ForgotPasswordBody2/>
    
</template>
  
<script>
  import { defineComponent } from "vue";
  import ForgotPasswordBody2 from "@/components/account/ForgotPasswordBody2.vue"; 
  
  export default defineComponent({
    name: "ForgotPasswordView2",
    components: {
      ForgotPasswordBody2
    },
  });
</script>
  