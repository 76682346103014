<template>
    
    <div class="outer" >

        <!-- HEADER -->
        <HeaderB/>

        <div class="outer__inner" >

            <input class="field__input" type="text" placeholder="Search by name, coin, status or date..." v-model="searchInput"  />

            <div class="activity__table" style="width:100%; margin-top: 20px; padding-left: 10px; padding-right: 10px; ">
                <div class="activity__row" style="width:100%; ">
                                    <div class="activity__col">
                                        <div class="sorting">Status</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Type</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Name</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Email</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Coin</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Date</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Amount</div>
                                    </div>
                                    
                                    <div class="activity__col">
                                        <div class="sorting">Amount(USD)</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Address</div>
                                    </div>
                                    

                                    <div class="activity__col">
                                        <div class="sorting">Approve</div>
                                    </div>

                                    <div class="activity__col">
                                        <div class="sorting">Decline</div>
                                    </div>
                                    
                </div>



                <div class="activity__row" v-for="tran in filteredTrans" :key="tran.id">
                                    <div class="activity__col">
                                        <div class="activity__label" style="padding-right: 10px;">Status</div>

                                        <div class="category-green activity__category" v-if="tran.status == 'successful'" >{{tran.status}}</div>

                                        <div class="category-yellow activity__category" v-else-if="tran.status == 'pending'" >{{tran.status}}</div>

                                        <div class="category-red activity__category" v-else-if="tran.status == 'cancelled'" >{{tran.status}}</div>
                                        
                                    </div>
                                    <div class="activity__col">
                                        <div class="activity__label" style="padding-right: 10px;">Type</div>
                                    {{tran.type}}
                                
                                    </div>

                                    <div class="activity__col">
                                        <div class="activity__label">Name</div>
                                        {{tran.name}}
                                
                                    </div>

                                    <div class="activity__col">
                                        <div class="activity__label">Email</div>
                                        {{tran.email}}
                                
                                    </div>

                                    <div class="activity__col">
                                        <div class="activity__label">Coin</div>
                                        <div class="activity__coin">
                                            
                                            <div class="activity__currency">{{tran.coin}}</div>
                                        </div>
                                    </div>

                                    <div class="activity__col">
                                        <div class="activity__label">Date</div>
                                        {{tran.date}}
                                
                                    </div>

                                    <div class="activity__col" v-if="tran.type == 'Send'">
                                        <div class="activity__label">Amount</div>
                                        {{tran.amount}}
                                
                                    </div>

                                    <div class="activity__col" v-if="tran.type == 'Send'">
                                        <div class="activity__label">Amount(USD)</div>
                                        {{tran.amountUsd}}
                                
                                    </div>

                                    <div class="activity__col" v-if="tran.type == 'Send'">
                                        <div class="activity__label" style="margin-right: 50px;">Address</div>
                                        <p style="word-break: break-word;">{{tran.address}}</p>
                                
                                    </div>

                                    <div class="activity__col" v-if="tran.type == 'Receive' && tran.status == 'successful'">
                                        <div class="activity__label" style="margin-right: 50px;">Address</div>
                                        <p style="word-break: break-word;">{{tran.address}}</p>
                                
                                    </div>
                                    
                                    <div class="activity__col" v-if="tran.type == 'Receive'">
                                        <div class="activity__label" v-if="tran.status == 'pending'">Amount</div>
                                        <input class="field__input" type="number" placeholder="Amount" :id="tran.number+'Amount'" v-if="tran.status == 'pending'"  />
                                
                                    </div>
                                    <div class="activity__col" v-if="tran.type == 'Receive'">
                                        <div class="activity__label" v-if="tran.status == 'pending'">Amount(USD)</div>
                                        <input class="field__input" type="number" placeholder="Amount in USD" :id="tran.number+'AmountUsd'"  v-if="tran.status == 'pending'" />
                                
                                    </div>
                                    <div class="activity__col" v-if="tran.type == 'Receive'">
                                        <div class="activity__label" v-if="tran.status == 'pending'">Address</div>
                                        <input class="field__input" v-if="tran.status == 'pending'" type="text" placeholder="Wallet address" :id="tran.number+'Address'"   />
                                
                                    </div>

                                    <div class="activity__col">
                                        <div class="activity__label">Approve</div>
                                        <button class="button button__small" style="background-color:#07cf50" v-if="tran.status == 'pending'" @click="approve(tran.number)">Approve</button>
                                
                                    </div>

                                    <div class="activity__col">
                                        <div class="activity__label">Decline</div>
                                        <button class="button button__small" style="background-color:#fc2f21" v-if="tran.status == 'pending'" @click="decline(tran.number)">Decline</button>
                                
                                    </div>
                                    
                                    
                </div>
                                

            </div>

               
                
        </div>


    </div>

    <!-- MODALS -->
    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

</template>

<script>
    import HeaderB from "@/components/master/Header.vue";
    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc, orderBy, deleteDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    export default {
        
        components: {
            HeaderB,
        },

        data(){
            return{
                errorText:"",
                successText:"",
                loadingText:"",
                transList: [],
                searchInput: "",
            }
        },

        computed: {

            filteredTrans(){
                return this.transList.filter( trans => trans.name.toLowerCase().includes(this.searchInput.toLowerCase()) || trans.coin.toLowerCase().includes(this.searchInput.toLowerCase()) || trans.date.toLowerCase().includes(this.searchInput.toLowerCase()) || trans.status.toLowerCase().includes(this.searchInput.toLowerCase()));
            }

        },

        mounted(){
            this.getTrans();
        },

        methods: {
            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getTrans(){

                this.loadingText= "Getting transactions...";
                this.$refs.loadingModal.classList.add("bg-active");

                let i = -1;
                let a2;
                //get user transactions
                const q = query(collection(db, "AdminTrans"), orderBy("timeStamp", "desc"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    i++;
                    a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));

                    if(doc.data().type == "Send"){
                        this.transList[i] = {
                            id: doc.id,
                            number: i,
                            type: doc.data().type,
                            image: doc.data().image,
                            name: doc.data().name,
                            amount: doc.data().amount,
                            amountUsd: doc.data().amountUsd,
                            address: doc.data().address,
                            email: doc.data().email,
                            date: a2.slice(0, -36),
                            symbol: doc.data().symbol,
                            status: doc.data().status,
                            walletId: doc.data().walletId,
                            coin: doc.data().coin,
                            timeStamp: doc.data().timeStamp,
                            userId: doc.data().userId,
                            userTranId: doc.data().userTranId,
                        }
                    }
                    else{
                        this.transList[i] = {
                            id: doc.id,
                            number: i,
                            type: doc.data().type,
                            image: doc.data().image,
                            name: doc.data().name,
                            email: doc.data().email,
                            amount: doc.data().amount,
                            amountUsd: doc.data().amountUsd,
                            address: doc.data().address,
                            date: a2.slice(0, -36),
                            symbol: doc.data().symbol,
                            status: doc.data().status,
                            walletId: doc.data().walletId,
                            coin: doc.data().coin,
                            timeStamp: doc.data().timeStamp,
                            userId: doc.data().userId,
                        }
                    }
                });

                this.$refs.loadingModal.classList.remove("bg-active");

            },

            async approve(num){

                let i = Number(num);

                this.loadingText= "approving transaction...";
                this.$refs.loadingModal.classList.add("bg-active");

                //get balance
                let balance;
                let amount;
                let amountUsd;
                let address;
                let newBalance;

                if(this.transList[i].type == "Receive"){
                    amount = Number(document.getElementById(""+i+"Amount").value);
                    amountUsd = Number(document.getElementById(""+i+"AmountUsd").value);
                    address = document.getElementById(""+i+"Address").value;

                    //empty check
                    if(document.getElementById(""+i+"Amount").value == "" || document.getElementById(""+i+"AmountUsd").value == "" || document.getElementById(""+i+"Address").value == ""){

                        this.errorText= "All fields must be filled...";
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.errorModal.classList.add("bg-active");

                        return;
                    }
                }

                else{
                    amount = this.transList[i].amount;
                    amountUsd = this.transList[i].amountUsd;
                    address = this.transList[i].address;
                }

                const walletRef = doc(db, "UserWallets", this.transList[i].walletId);
                const walletSnap = await getDoc(walletRef);

                const AdminTransRef = doc(db, "AdminTrans", this.transList[i].id);
                

                if(walletSnap.exists()){
                    balance = walletSnap.data().balance;
                }

                //approve send
                if(this.transList[i].type == "Send"){
                
                    const UserTransRef = doc(db, "UserTrans", this.transList[i].userTranId);
                    
                    if(balance < amount){
                        this.errorText = "User does not have enough balance for this transaction";
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.errorModal.classList.add("bg-active");
                        return;
                    }

                    newBalance = balance - amount;

                    //update balance
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    //update Admin transaction
                    await updateDoc(AdminTransRef, {
                        status: "successful",
                        
                    });

                    //update User trans
                    await updateDoc(UserTransRef, {
                        status: "successful",
                        
                    });

                }
                else{
                    //approve receive

                    newBalance = balance + amount;

                    //update balance
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    //update Admin transaction
                    await updateDoc(AdminTransRef, {
                        status: "successful",
                        amount: this.round(amount, 7),
                        amountUsd: this.round(amountUsd, 2),
                        address: address,
                    });

                    //Add doc to User trans
                    await addDoc(collection(db, "UserTrans"), {
                        type: "Receive",
                        name: this.transList[i].name,
                        email: this.transList[i].email,
                        coin: this.transList[i].coin,
                        symbol: this.transList[i].symbol,
                        image: this.transList[i].image,
                        address: address,
                        amount: this.round(amount, 7),
                        status: "successful",
                        amountUsd: this.round(amountUsd, 2),
                        timeStamp: this.transList[i].timeStamp,
                        userId: this.transList[i].userId,
                        walletId: this.transList[i].walletId
                    });
                }

                this.successText= "Successful"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
            },

            async decline(num){

                let i = Number(num);

                this.loadingText= "declining transaction...";
                this.$refs.loadingModal.classList.add("bg-active");

                const AdminTransRef = doc(db, "AdminTrans", this.transList[i].id);
                

                //Decline send
                if(this.transList[i].type == "Send"){

                    const UserTransRef = doc(db, "UserTrans", this.transList[i].userTranId);

                    //update Admin transaction
                    await updateDoc(AdminTransRef, {
                        status: "cancelled",
                        
                    });

                    //update User trans
                    await updateDoc(UserTransRef, {
                        status: "cancelled",
                        
                    });

                }
                else{
                    //Decline receive

                    //delete doc
                    await deleteDoc(doc(db, "AdminTrans", this.transList[i].id));

                    
                }

                this.successText= "Successful"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
            }
        }

    }
</script>