<template>
    
    <LoginAuthBody/>
    
</template>
  
<script>
  import { defineComponent } from "vue";
  import LoginAuthBody from "@/components/account/LoginAuthBody.vue"; 
  
  export default defineComponent({
    name: "LoginAuthView",
    components: {
      LoginAuthBody
    },
  });
</script>
  