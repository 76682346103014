<template>
    
    <StakingBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import StakingBody from "@/components/dashboard/StakingBody.vue"; 
  
  export default defineComponent({
    name: "StakingView",
    components: {
      
        StakingBody
      
    },
  });
</script>
  