<template>
    
    <section class="content-block section-padding">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-6 col-12 pe-xl-5 mt-5 mt-xl-0 order-2 order-xl-1">
                    <div class="block-contents">
                        <div class="section-title mb-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
                            <h2>Earn up to <span style="color: #32C072;">29%</span> interest on crypto</h2>
                        </div>
                        <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style="font-size:16px">Make your idle digital assets work for you. By staking your coins with KrypChain, you let them work for you and increase your earnings. You can put your idle assets to work straight away and have a predictable source of passive income. Just top up and start earning up to 35% interest immediately, no further action is needed on your side. </p>
                        
                        <router-link :to="{name: 'sign-up'}" style="background-color: #145eff; color:#fff; border-color:#145eff" class="theme-btn second-color mt-40 wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.1s">Get Started</router-link>
                    </div>
                </div>
                <div class="col-xl-6 pe-lg-0 col-12 order-1 order-xl-2">
                    <div class="block-img-right">
                        <img src="@/assets/baseAssets/img/interface.png" alt="">
                        <img src="@/assets/baseAssets/img/interface-chart.png" alt="">
                        
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="services-wrapper fix section-black section-padding">
        <div class="container">
            <div class="col-lg-8 ps-xl-5 pe-xl-5 col-12 offset-lg-2 text-center">
                <div class="block-contents text-white">
                    <div class="section-title wow fadeInUp" data-wow-duration="1s">
                        <h2>Supported assets</h2>
                        <p></p>
                    </div>
                </div>
            </div>
           
            
            <div class="row">

                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-core-feature">
                        <div class="icon" style="width: 100%; display:flex; justify-content:center; align-items:center">
                            <img src="@/assets/baseAssets/img/coins/eth.png" alt="" style="width: 120px;">
                        </div>
                        <div class="content" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-direction: column;">
                            <h4>ETH</h4>
                            <p style="color: #32C072; font-size: 18px; font-weight: 600;">Up to 17.97%</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-core-feature">
                        <div class="icon" style="width: 100%; display:flex; justify-content:center; align-items:center">
                            <img src="@/assets/baseAssets/img/coins/sol.png" alt="" style="width: 120px;">
                        </div>
                        <div class="content" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-direction: column;">
                            <h4>SOL</h4>
                            <p style="color: #32C072; font-size: 18px; font-weight: 600;">Up to 28.17%</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-core-feature">
                        <div class="icon" style="width: 100%; display:flex; justify-content:center; align-items:center">
                            <img src="@/assets/baseAssets/img/coins/xrp.png" alt="" style="width: 120px;">
                        </div>
                        <div class="content" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-direction: column;">
                            <h4>XRP</h4>
                            <p style="color: #32C072; font-size: 18px; font-weight: 600;">Up to 16.97%</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-core-feature">
                        <div class="icon" style="width: 100%; display:flex; justify-content:center; align-items:center">
                            <img src="@/assets/baseAssets/img/coins/sandbox.png" alt="" style="width: 120px;">
                        </div>
                        <div class="content" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-direction: column;">
                            <h4>SAND</h4>
                            <p style="color: #32C072; font-size: 18px; font-weight: 600;">Up to 19.50%</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-core-feature">
                        <div class="icon" style="width: 100%; display:flex; justify-content:center; align-items:center">
                            <img src="@/assets/baseAssets/img/coins/algo.png" alt="" style="width: 120px;">
                        </div>
                        <div class="content" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-direction: column;">
                            <h4>ALGO</h4>
                            <p style="color: #32C072; font-size: 18px; font-weight: 600;">Up to 25.70%</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-core-feature">
                        <div class="icon" style="width: 100%; display:flex; justify-content:center; align-items:center">
                            <img src="@/assets/baseAssets/img/coins/ada.png" alt="" style="width: 120px;">
                        </div>
                        <div class="content" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-direction: column;">
                            <h4>ADA</h4>
                            <p style="color: #32C072; font-size: 18px; font-weight: 600;">Up to 20.50%</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-core-feature">
                        <div class="icon" style="width: 100%; display:flex; justify-content:center; align-items:center">
                            <img src="@/assets/baseAssets/img/coins/shib.png" alt="" style="width: 120px;">
                        </div>
                        <div class="content" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-direction: column;">
                            <h4>SHIB</h4>
                            <p style="color: #32C072; font-size: 18px; font-weight: 600;">Up to 28.21%</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-core-feature">
                        <div class="icon" style="width: 100%; display:flex; justify-content:center; align-items:center">
                            <img src="@/assets/baseAssets/img/coins/xlm.png" alt="" style="width: 120px;">
                        </div>
                        <div class="content" style="width: 100%; display:flex; justify-content:center; align-items:center; flex-direction: column;">
                            <h4>XLM</h4>
                            <p style="color: #32C072; font-size: 18px; font-weight: 600;">Up to 29.21%</p>
                        </div>
                    </div>
                </div>
                
                
                
            </div>

        </div>
    </section>

    <section class="faq-video-block section-padding section-bg">
        <div class="container">

            <div class="col-lg-8 ps-xl-5 pe-xl-5 col-12 offset-lg-2 text-center">
                <div class="block-contents">
                    <div class="section-title wow fadeInUp" data-wow-duration="1s">
                        <h2 style="color: #000;">KrypChain staking FAQs</h2><br>
                        <p></p>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                
                <div class="col-xl-6 ps-xl-5 col-12 mt-xl-0 mt-5">
                    
                    <div class="faq-accordion ms-xl-4 me-xl-4">
                        <div class="accordion" id="mainaccordion">

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask1" aria-expanded="false" aria-controls="faqask1">
                                    What is Staking in the Cryptocurrency Market?
                                </button>
                              </h4>
                              <div id="faqask1" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    In very basic terms, cryptocurrency staking is a process supporting the functionality of a blockchain network and earning rewards by holding cryptocurrency. The reason why staked crypto earns rewards is because the blockchain puts coins to work for transaction validation, voting purposes, and to secure the network. Cryptocurrency networks that support staking rely on a consensus mechanism called proof of stake (PoS) or similar consensus mechanisms, for example, delegated proof of stake (DPos).
                                </div>
                              </div>
                            </div>
                            
                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".6s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask2" aria-expanded="true" aria-controls="faqask2">
                                    Advantages of staking with KrypChain?
                                </button>
                              </h4>
                              <div id="faqask2" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    <strong style="color: black;">1. Easy to use</strong> You don't need to manage private keys, acquire resources, make trades, or perform other complicated tasks to participate in staking. KrypChain's one-stop service allows users to obtain generous rewards with little effort. <br><br>
                                    <strong style="color: black;">2. No gas fee</strong> KrypChain staking deposits users’ funds into smart contracts on users’ behalf, saving users on-chain gas fees. <br><br>
                                   
                                    
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".9s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask3" aria-expanded="false" aria-controls="faqask3">
                                    After I participate in Staking, how is the earnings cycle calculated?
                                </button>
                              </h4>
                              <div id="faqask3" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    Once funds are successfully allocated to Staking, earnings are calculated beginning at 00:00 (UTC) the following day. The minimum earnings calculation period is one day; earnings for a period of less than one day will not be included in the earnings distribution.
                                </div>
                              </div>
                            </div>

                            

                        </div>                      
                    </div>
                    
                </div>

                <div class="col-xl-6 ps-xl-5 col-12 mt-xl-0 mt-5">
                    
                    <div class="faq-accordion ms-xl-4 me-xl-4">
                        <div class="accordion" id="mainaccordion">

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask4" aria-expanded="false" aria-controls="faqask4">
                                    What is Proof of Stake (PoS)?
                                </button>
                              </h4>
                              <div id="faqask4" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    Proof of stake (PoS) is a consensus algorithm for processing transactions and creating new blocks in a blockchain network that relies on a staking process to secure the network. Proof-of-Stake is usually considered an alternative to proof of work (PoW) while staking acts as an alternative to mining. One of the reasons why proof of stake gained significant popularity among crypto projects is because it allows blockchain management to be more energy-efficient than the traditional proof of work mechanism. It is known as a more accessible way to contribute to blockchain since users can delegate coins to support the network without needing specialized equipment.
                                </div>
                              </div>
                            </div>
                            
                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".6s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask5" aria-expanded="true" aria-controls="faqask5">
                                    What is Proof of Work (POW)?
                                </button>
                              </h4>
                              <div id="faqask5" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    In proof of work, miners create new blocks by solving complex mathematical puzzles. In proof of stake, a network uses a specific algorithm to select stakers for adding the latest batch of transactions to the blockchain. The network chooses validators based on the size of their stake and the length of time they stake coins. The longer and larger the amount of coins staked, the more chances that your staking node will be selected to bring value to the network. For its work, stakers receive a staking reward paid in cryptocurrency. The exact implementation of staking varies from network to network but, in most cases, users need to lock a specific amount of coins to become a staker.
                                    
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".9s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask6" aria-expanded="false" aria-controls="faqask6">
                                    Does Staking Work Like a Bank Deposit?
                                </button>
                              </h4>
                              <div id="faqask6" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    At first glance, earning crypto through staking resembles a savings account in the bank. When it comes to depositing money in a bank, the bank uses these funds to lend to other people, or for investing in financial instruments. The interest income the bank gives customers is the way to compensate for using deposited funds. <br>

                                    But the staking concept works in a completely different way. While staking, you earn rewards by running a staking node or by delegating your coins to the node owner. Whoever you delegate your coins to, these funds are used only for increasing the so-called staking power of the node. The higher the staking power, the greater the node’s weight in the blockchain decision-making process. It affects the chances of receiving staking rewards. <br>

                                    The staking reward percentage is determined in the coins you stake, not in the fiat value of these coins. Since crypto prices may fluctuate, you may benefit from both staking rewards and cryptocurrency appreciation. In another case, the staking rewards may dampen the impact of downward movements.
                                </div>
                              </div>
                            </div>

                        </div>                      
                    </div>
                    
                </div>

                <div class="faq-bottom ms-xl-4 mt-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s">
                    <span>Still have questions?</span><a href="mailto:help@krypchain.io" style="color: #145eff;">Get in touch</a>
                </div>

            </div>

        </div>
    </section>

</template>