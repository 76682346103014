<template>
    
    <section class="content-block section-padding">
        <div class="container">

            <div class="row align-items-center">
                <div class="col-lg-5 mb-3 mb-lg-0  pe-lg-0 col-12">
                    <div class="animated-img-wrapper">
                        <img src="@/assets/baseAssets/img/dashboard-img.png" alt="">
                        <img src="@/assets/baseAssets/img/top-element-img.png" alt="">
                        <img src="@/assets/baseAssets/img/chart-graphic.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6 offset-lg-1 mt-5 mt-lg-0 col-12 ps-lg-5 pe-lg-5">
                    <div class="block-contents ms-xl-3">
                        <div class="section-title mb-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
                            <h2>Automate your crypto trading</h2>
                        </div>
                        <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style="font-size:16px">krypchain helps you set simple and sophisticated trading robots to make a profits in different market conditions. Generate profit from small price fluctuations as the market moves sideways. Set the order grid that follows the market automatically. Access the best grid trading solution in the industry.</p>
                        
                        <router-link :to="{name: 'sign-up'}" class="theme-btn second-color mt-40 wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.1s" style="background-color:#145eff ; border-color: #145eff;">Get Started</router-link>
                    </div>
                </div>
            </div>

            <!-- <div class="row section-padding mtm-30 d-flex justify-content-between pb-0">
                <div class="col-xxl-4  ">
                    <div class="single-about-features">
                        <div class="icon">
                            <img src="assets/img/gr1.png" alt="" style="width: 80px;">
                        </div>
                        <div class="content">
                            <h3>Make easy profits</h3>
                            <p>Most markets are moving sideways most of the time, while strong price moves are usually short-lived. GRID trading allows making a continuous profit when other strategies fail to make any profit at all!</p>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4">
                    <div class="single-about-features">
                        <div class="icon">
                            <img src="assets/img/gr2.png" alt="" style="width: 80px;">
                        </div>
                        <div class="content">
                            <h3>Enable trailing, go sailing</h3>
                            <p> Our GRID bot requires very little attention from you as it is able to move the order grid following the price in both directions so that the bot can stay active no matter what. This means you can save more time for yachting!</p>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4">
                    <div class="single-about-features">
                        <div class="icon">
                            <img src="assets/img/gr3.png" alt="" style="width: 80px;">
                        </div>
                        <div class="content">
                            <h3>Any trader, any market</h3>
                            <p>Our GRID bot allows making a profit in the base or the quote currency. You can run a grid bot in just a few clicks with ready-to-go templates or set up a bot with your custom settings. </p>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
    </section>

    <section class="our-best-features-wrapper section-padding" style="margin-top: -50px;">
        <div class="container">
            <div class="col-xl-8 offset-xl-2 text-center">
                <div class="block-contents">
                    <div class="section-title">
                        <h2>Grid bot features</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="features-card-item style-1">
                        <div>
                            <img src="@/assets/baseAssets/img/gb1.png" alt="" style="width: 70px; margin-bottom:10px">
                        </div>
                        <h3>Ready-made strategies</h3>
                        <p style="margin-top:10px">The most secure and fast payment can be made through cyptrocurrency</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="features-card-item style-1">
                        <div>
                            <img src="@/assets/baseAssets/img/gb2.png" alt="" style="width: 70px; margin-bottom:10px">
                        </div>
                        <h3>Performance analytics</h3>
                        <p style="margin-top:10px">Get a complete understanding of your results with detailed statistics of your bots.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="features-card-item style-1">
                        <div>
                            <img src="@/assets/baseAssets/img/gb3.png" alt="" style="width: 70px; margin-bottom:10px">
                        </div>
                        <h3>Profit 24/7</h3>
                        <p style="margin-top:10px">krypchain is a cloud-based platform, allowing your bots to continue working even you are offline.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="features-card-item style-1">
                        <div>
                            <img src="@/assets/baseAssets/img/gb4.png" alt="" style="width: 70px; margin-bottom:10px">
                        </div>
                        <h3>Security</h3>
                        <p style="margin-top:10px"> The krypchain GRID bot uses high-end encryption to secure the connection between the platform and your exchange.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="features-card-item style-1">
                        <div>
                            <img src="@/assets/baseAssets/img/gb5.png" alt="" style="width: 70px; margin-bottom:10px">
                        </div>
                        <h3>Fully automated</h3>
                        <p style="margin-top:10px">Our GRID bot requires very little attention from you as it is able to move the order grid following the price in both directions so that the bot can stay active no matter what.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="features-card-item style-1">
                        <div>
                            <img src="@/assets/baseAssets/img/gb6.png" alt="" style="width: 70px; margin-bottom:10px">
                        </div>
                        <h3>Any trader, any market</h3>
                        <p style="margin-top:10px"> Our GRID bot allows making a profit on multiple cryptocurrencies. You can run a grid bot and start making profit in just a few clicks.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="faq-video-block section-padding section-bg" style="margin-top: -80px;">
        <div class="container">

            <div class="col-lg-8 ps-xl-5 pe-xl-5 col-12 offset-lg-2 text-center">
                <div class="block-contents">
                    <div class="section-title wow fadeInUp" data-wow-duration="1s">
                        <h2 style="color: #000;">Grid bot FAQs</h2><br>
                        <p></p>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">

                <div class="col-xl-12">
                    
                    <div class="faq-accordion ms-xl-4 me-xl-4">
                        <div class="accordion" id="mainaccordion">

                            
                            <div class="accordion-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <h4 class="accordion-header" >
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask4" aria-expanded="false" aria-controls="faqask4">
                                    What can Grid bot do?
                                </button>
                              </h4>
                              <div id="faqask4" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">

                                    <p>GRID bot is made to make a profit on a sideways (flat) market by trading between multiple order levels (’grid’).</p>

                                    <p>The bot will use two currencies of the pair (e.g. BTC / USDT) for placing limit orders for buying and selling and will make a profit in one currency.</p>

                                    <p>If you choose to make a profit in USDT, the bot will be buying and selling BTC for profit in USDT and vice versa — if you choose BTC, then your bot will be selling it for USDT to buy back cheaper with profit in BTC.</p>

                                    <p>The bot keeps trading as long as the price stays inside the grid.</p>

                                    <p>If the price breaks outside, the grid follows it automatically and the bot stays active.</p>

                                    <p>In other words, the bot makes a profit in USDT on a flat market and follows the price if it moves up, or accumulates Bitcoin on a flat market and follows the price if it goes down.</p>

                                </div>
                              </div>
                            </div>
                            
                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".6s">
                              <h4 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask5" aria-expanded="true" aria-controls="faqask5">
                                    What is the Grid strategy?
                                </button>
                              </h4>
                              <div id="faqask5" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                <div class="accordion-body">
                                    <p>The driving force of this strategy is a stochastic price fluctuation, also referred to as a ’flat’ or ’sideways’ market.</p>

                                    <p>When the price is fluctuating in a tight range, you could potentially make an infinite profit by just making trades repeatedly.</p>

                                    <p>In reality, the price does not stick anywhere for too long, so you need to cover as many levels as possible by splitting your investment into multiple Buy and Sell orders aka ’order grid’.</p>

                                    <p>You are making profits steadily as long as the price is bouncing inside the grid so that you are buying let’s say 0.1 BTC on each Buy level and selling it at a higher Sell level for profit in USDT.</p>

                                    <p>The success factors of your strategy are time and volatility — the longer the price stays inside the grid, the longer you keep trading, and the more volatile is the price, the more trades you make.</p>

                                    <p>Overall, the grid strategy may work well as it is utilizing the only guaranteed market direction — to the right.</p>
                                    
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".9s">
                                <h4 class="accordion-header">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask7" aria-expanded="false" aria-controls="faqask7">
                                      Is profit guaranteed ?
                                  </button>
                                </h4>
                                <div id="faqask7" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                  <div class="accordion-body">
                                      <p>Unfortunately, the only guaranteed things in life are death and taxes. While our Grid bots offers many advantages for our users, they cannot guarantee profits.</p>

                                      <p>The cryptocurrency market is highly volatile and speculative, and any trading activity is ultimately done at your own risk. Always remember the golden rule: never invest more than you can afford to lose.</p>
                                  </div>
                                </div>
                              </div>

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".9s">
                                <h4 class="accordion-header">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask8" aria-expanded="false" aria-controls="faqask8">
                                      Does krypchain take fees from my trades ?
                                  </button>
                                </h4>
                                <div id="faqask8" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                  <div class="accordion-body">
                                      <p>No, krypchain doesn’t take any fees from your trades.</p>
                                  </div>
                                </div>
                            </div>  

                            <div class="accordion-item wow fadeInUp" data-wow-duration="1.1s" data-wow-delay=".9s">
                                <h4 class="accordion-header">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqask9" aria-expanded="false" aria-controls="faqask9">
                                    What sort of quality control measures does krypchain take for it's Grid bots?
                                  </button>
                                </h4>
                                <div id="faqask9" class="accordion-collapse collapse" data-bs-parent="#mainaccordion">
                                  <div class="accordion-body">
                                      <p>All Grid bots are completely sandboxed and run individually. Krypchain only mirrors signals and portfolio distributions depending on the circumstances of the individual investor. Logging, debugging and other functionality are not available for investors. Nobody will ever directly touch a user's bot or its underlying code/algorithm.</p>
                                  </div>
                                </div>
                              </div> 


                        </div>                      
                    </div>
                    
                </div>

                <div class="faq-bottom ms-xl-4 mt-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s">
                    <span>Still have questions?</span><a href="mailto:support@krypchain.io" style="color: #145eff;">Get in touch</a>
                </div>

            </div>

        </div>
    </section>

</template>