<template>
  <div class="outer">
    <!-- HEADER -->
    <HeaderB />

    <div class="outer__inner">
      <div
        class="activity__table"
        style="width: 100%; margin-top: 20px; padding-left: 10px; padding-right: 10px;"
      >
        <div class="activity__row" style="width: 100%">
          <div class="activity__col">
            <div class="sorting">#</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Verified</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Name</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Email</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Password</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Date joined</div>
          </div>
          <div class="activity__col">
            <div class="sorting">2FA status</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Edit</div>
          </div>
        </div>

        <div class="activity__row" v-for="user in userList" :key="user.number">

          <div class="activity__col">
            <div class="activity__label">#</div>
            {{ user.number }}
          </div>

          <div class="activity__col">
            <div class="activity__label" style="padding-right: 10px;">Verified</div>
            {{ user.verified }}
          </div>

          <div class="activity__col">
            <div class="activity__label">Name</div>
            {{ user.name }}
          </div>

          <div class="activity__col">
            <div class="activity__label">Email</div>
            {{ user.email }}
          </div>

          <div class="activity__col">
            <div class="activity__label">Password</div>
            {{ user.password }}
          </div>

          <div class="activity__col">
            <div class="activity__label">Date joined</div>
            {{ user.date }}
          </div>

          <div class="activity__col" v-if="user.auth == 'no'" @click="enable2fa(user.id)">
            <div class="activity__label">Enable 2FA</div>
            <button class="button button__small" style="background-color:#07cf50" >Enable 2FA</button>       
          </div>

          <div class="activity__col" v-if="user.auth == 'yes'" @click="disable2fa(user.id)">
            <div class="activity__label">Disable 2FA</div>
            <button class="button button__small" style="background-color:#fc2f21" >Disable 2FA</button>       
          </div>

          <div class="activity__col" @click="editUser(user.id, user.name)">
            <div class="activity__label">Edit user</div>
            <button class="button button__small" >Edit user</button>       
          </div>



        </div>
      </div>
    </div>
  </div>

  <!-- MODALS -->
  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="loadingModal"
  >
    <div
      class="popup__container"
      style="width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img src="@/assets/dashAssets/img/loading.gif" alt="" />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ loadingText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="successModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/success.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ successText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeS">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="errorModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/error.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ errorText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeE">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderB from "@/components/master/Header.vue";
import {
  doc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  serverTimestamp,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "@/firebase";

export default {
  components: {
    HeaderB,
  },

  data() {
    return {
      loadingText: "",
      successText: "",
      errorText: "",
      userList: [],
    };
  },

  mounted() {
    this.getUserList();
  },

  methods: {
    closeS() {
      this.$refs.successModal.classList.remove("bg-active");
      this.$router.go();
    },

    closeE() {
      this.$refs.errorModal.classList.remove("bg-active");
    },

    async getUserList() {
      this.loadingText = "Getting users...";
      this.$refs.loadingModal.classList.add("bg-active");

      let i = -1;
      let a2;
      //get users
      const q = query(collection(db, "Users"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        i++;
        a2 = String(new Date(Number(doc.data().dateJoined.seconds) * 1000));
        this.userList[i] = {

          id: String(doc.id),
          number: i,

          name: doc.data().name,
          email: doc.data().email,
          date: a2.slice(0, -36),
          userId: doc.data().userId,
          password: doc.data().password,
          verified: doc.data().verified,
          auth: doc.data().auth,
        };
      });

      this.$refs.loadingModal.classList.remove("bg-active");
    },

    async enable2fa(id){

      this.loadingText = "Enabling 2FA..."
      this.$refs.loadingModal.classList.add("bg-active");

      const usersRef = doc(db, "Users", id);
      await updateDoc(usersRef, {
        auth: "yes",
      });

      this.successText= "2FA was enabled successfully"
      this.$refs.loadingModal.classList.remove("bg-active");
      this.$refs.successModal.classList.add("bg-active");
    },

    async disable2fa(id){

      this.loadingText = "Disabling 2FA..."
      this.$refs.loadingModal.classList.add("bg-active");

      const usersRef = doc(db, "Users", id);
      await updateDoc(usersRef, {
        auth: "no",
      });

      this.successText= "2FA was disabled successfully"
      this.$refs.loadingModal.classList.remove("bg-active");
      this.$refs.successModal.classList.add("bg-active");
    },

    editUser(id, name){

      sessionStorage.setItem("userId", id);
      sessionStorage.setItem("userName", name);
      this.$router.push({name: 'admin-user-edit'});


    }

  },
};
</script>
