<template>
    <NavbarDark/>
    <StakingBody/>
    <Footer/>
</template>
  
<script>
  import { defineComponent } from "vue";
  import NavbarDark from "@/components/NavbarDark.vue";
  import StakingBody from "@/components/StakingBody.vue";
  import Footer from "@/components/Footer.vue"; 
  
  export default defineComponent({
    name: "StakingView",
    components: {
      NavbarDark,
      StakingBody,
      Footer
    },
  });
</script>
  