<template>
    
    <SignupBody/>
    
</template>
  
<script>
  import { defineComponent } from "vue";
  import SignupBody from "@/components/account/SignupBody.vue"; 
  
  export default defineComponent({
    name: "SignupView",
    components: {
      SignupBody
    },
  });
</script>
  