<template>
    <h1>WALLET connext</h1>
</template>

<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>