<template>
    
    <VerifyEmailBody/>
    
</template>
  
<script>
  import { defineComponent } from "vue";
  import VerifyEmailBody from "@/components/account/VerifyEmailBody.vue"; 
  
  export default defineComponent({
    name: "VerifyEmailView",
    components: {
      VerifyEmailBody
    },
  });
</script>
  