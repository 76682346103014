<template>
    
    <div class="outer">

        <!-- HEADER -->
        <HeaderB/>

        <div class="outer__inner">

            <div class="activity" id="noT">
                    <div class="activity__center center">
                        
                        <h2 style="color:#ffffff; width:100%; text-align:center; margin-top: 150px">No transactions yet...</h2>
                        
                    </div>
                </div>

                <div class="activity" id="yesT">
                    <div class="activity__center center">
                        <div class="activity__wrapper">
                            
                            <div class="activity__line">
                                <h2 class="activity__title h4" style="color:#ffffff">Transactions</h2>
                                
                            </div>
                            <div class="activity__table">
                                <div class="activity__row">
                                    <div class="activity__col">
                                        <div class="sorting">Status</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Type</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Coin</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="sorting">Amount</div>
                                    </div>
                                    
                                    <div class="activity__col">
                                        <div class="sorting">Address</div>
                                    </div>
                                    <!-- <div class="activity__col">
                                        <div class="sorting">Transaction ID</div>
                                    </div> -->
                                    
                                    <div class="activity__col">
                                        <div class="sorting">Date</div>
                                    </div>
                                </div>



                                <div class="activity__row" v-for="tran in transList" :key="tran.number">
                                    <div class="activity__col">
                                        <div class="activity__label" style="padding-right:10px">Status</div>
                                        <div class="category-green activity__category" v-if="tran.status == 'successful'">successful</div>
                                        <div class="category-yellow activity__category" v-if="tran.status == 'pending'">pending</div>
                                        <div class="category-red activity__category" v-if="tran.status == 'cancelled'">cancelled</div>
                                    </div>
                                    <div class="activity__col">
                                        <div class="activity__label" style="padding-right:10px">Type</div>
                                        {{tran.type}}
                                  
                                    </div>
                                    <div class="activity__col">
                                        <div class="activity__label" style="padding-right:10px">Coin</div>
                                        <div class="activity__coin">
                                            <div class="activity__icon">
                                                <img :src="tran.image" alt="Bitcoin" style="border-radius:50%">
                                            </div>
                                            <div class="activity__currency">{{tran.coin}}</div>
                                        </div>
                                    </div>
                                    
                                    <div class="activity__col">
                                        <div class="activity__label">Amount (USD)</div>
                                        ${{tran.amountUsd}}
                                  
                                    </div>
                                    <div class="activity__col" >
                                        <div class="activity__label" style="margin-right: 70px;">Address</div>
                                        <p style="word-break: break-word;">{{tran.address}}</p>
                                  
                                    </div>
                                    <!-- <div class="activity__col">
                                        <div class="activity__label">Transaction ID</div>
                                        {{tran.id}}
                                  
                                    </div> -->
                                    <div class="activity__col">
                                        <div class="activity__label">Date</div>
                                        {{tran.timeStamp}}
                                  
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                        
                    </div>
                </div>
                
        </div>


    </div>

    <!-- MODALS -->
    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

</template>

<script>
    import HeaderB from "@/components/dashboard/Header.vue";
    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc, orderBy } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {

        components: {
            HeaderB,
        },

        data(){
            return{
                errorText:"",
                successText:"",
                loadingText:"",
                transList: [],
            }
        },

        mounted(){

            document.getElementById("twallet").classList.remove("active");
            document.getElementById("tswap").classList.remove("active");
            document.getElementById("tstaking").classList.remove("active");
            document.getElementById("tbot").classList.remove("active");
            document.getElementById("ttran").classList.add("active");

            this.getTrans();

        },

        methods:{

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async getTrans(){

                this.loadingText= "Getting transactions...";
                this.$refs.loadingModal.classList.add("bg-active");

                const q = query(collection(db, "UserTrans"), where("userId", "==", sessionStorage.getItem("id")), orderBy("timeStamp", "desc")); 
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    let i = -1;
                    let a2;
                    document.getElementById("noT").style.display = "none";

                    querySnapshot.forEach((doc) => {
                        i++;
                        
                        a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));
                        

                        this.transList[i] = {
                            number: i,
                            id: doc.id,
                            type: doc.data().type,
                            name: doc.data().name,
                            email: doc.data().email,
                            coin: doc.data().coin,
                            symbol: doc.data().symbol,
                            image: doc.data().image,
                            address: doc.data().address,
                            amount: doc.data().amount,
                            status: doc.data().status,
                            amountUsd: doc.data().amountUsd,
                            timeStamp: a2.slice(0, -36),
                            
                        }

                        
                    })
                }
                else{
                    document.getElementById("yesT").style.display = "none";
                    document.getElementById("noT").style.display = "flex";
                }

                this.$refs.loadingModal.classList.remove("bg-active");

            }
            
        }
        
    }
</script>