<template>
    
    <section class="promo-content-block fix section-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-12">
                    <div class="video-cta">
                        <img src="@/assets/baseAssets/img/video-banner.jpg" alt="" style="border-radius: 15px;">
                        
                    </div>
                </div>
                <div class="col-xl-6 col-12 ps-xl-5 mt-5 mt-xl-0 ">
                    <div class="block-contents ms-xl-5">
                        <div class="section-title mb-4">
                            <h2 class="wow fadeInUp">Our story</h2>
                            <p class="wow fadeInUp pt-15" data-wow-delay=".3s" style="font-size:16px">krypchain was one of the first platforms to make fiat-to-crypto transactions accessible by offering card payments and bank transfers to the clients. Currently, we provide a rich variety of tools trading for Bitcoin, Bitcoin Cash, Ethereum, Ripple, Stellar, Litecoin, Tron, and many other crypto assets.</p>
                            <p class="mt-3" style="font-size:16px">We aim to continue building new services and improving upon existing ones. Earning your trust is always on our mind. Throughout our company’s history, effective and trustworthy collaboration with regulators has always been a top priority. Our teams are constantly working to maintain our compliance with evolving regulatory requirements and financial industry standards.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row section-padding mtm-30 d-flex justify-content-between pb-0">
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="single-about-features">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/man.png" alt="">
                        </div>
                        <div class="content">
                            <h4>Teamwork</h4>
                            <p>By treating others with respect and supporting each other, we stick to basic principles of human relationships. We value teamwork over personal ambition as the only way to success. What are we doing? We’re building a great team that’s a pleasure to work with.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 offset-xxl-1 col-md-6 ps-xl-0">
                    <div class="single-about-features">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/idea.png" alt="">
                        </div>
                        <div class="content">
                            <h4>Creative thinking</h4>
                            <p> We prefer free mindsets instead of detailed instructions where we can avoid them. The only golden rule we set is to be accountable for actions we take, behaviors we demonstrate, and decisions we make.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 offset-xxl-1 col-md-6 ps-xl-0">
                    <div class="single-about-features">
                        <div class="icon">
                            <img src="@/assets/baseAssets/img/setting.png" alt="">
                        </div>
                        <div class="content">
                            <h4>Goal oriented</h4>
                            <p>Our individual and team achievements are meaningful when they’re aligned with our company’s goals. We’re inspired by the things we do and the people for whom we do them. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="promo-content-block fix section-padding section-bg">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-6 col-12 mt-5 mt-xl-0 order-2 order-xl-1">
                    <div class="block-contents">
                        <div class="section-title mb-4">
                            <h2 class="wow fadeInUp">Our mission</h2>
                            <p class="wow fadeInUp pt-15" data-wow-delay=".3s" style="font-size:16px">krypchain is committed to facilitating
                                the global free flow of cryptocurrencies. By harnessing the potential behind blockchain technology, krypchain is pioneering a new digital financial system.</p>

                                <p class="wow fadeInUp pt-15" data-wow-delay=".3s" style="font-size:16px">We see a future for the financial world where all assets are tokenized on-chain and people have efficient ways to transfer or manage their funds. At krypchain, we always look ahead which is why we’ve designed our products to be seamless, secure and instant.</p>
                        </div>
                    </div>
                    <div class="funfacts d-flex">
                        <div class="single-funfact wow fadeInUp" data-wow-delay=".5s">
                            <h3>
                                <span>30</span>
                                +
                            </h3>
                            <p>Supported assets</p>
                        </div>
                        <div class="single-funfact wow fadeInUp" data-wow-delay=".5s">
                            <h3>
                                <span>97</span>
                                %
                            </h3>
                            <p>User satisfaction</p>
                        </div>
                        
                    </div>
                </div>
                <div class="col-xl-5 offset-xl-1 order-1 order-xl-2">
                    <img src="@/assets/baseAssets/img/mission.png" alt="" style=" border-radius:15px;">
                </div>
            </div>
        </div>
    </section>

    <section class="testimonial-wrapper section-padding fix">
        <div class="container">
            <div class="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
                <div class="block-contents">
                    <div class="section-title wow fadeInDown" data-wow-duration="1.2s">
                        <h2>Our users love us</h2>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-12">
                <div class="testimonial-carousel-active">
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl2.jpg?alt=media&token=d5ff61b2-917a-4dfc-a61f-3783990e06f0');"></div>
                            <div class="client-name">
                                <h6>Scott Swanson</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “krypchain is everything I've ever wanted from a crypto wallet. If it's a sign of what's to come, then I'm really excited.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl1.jpg?alt=media&token=f31716b9-4d19-4dbf-8352-2dbcf2462670');"></div>
                            <div class="client-name">
                                <h6>Karen Lynn</h6>
                                
                            </div>
                        </div>
                        <div class="feedback">
                            “A wallet can make or break the crypto experience. Having spent some time with krypchain, I can say that it makes trading crypto a lot more fun.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl3.jpg?alt=media&token=f20ddc4a-e8f0-47fa-9934-c1cb3f7e4e05');"></div>
                            <div class="client-name">
                                <h6>Sean Jacobs</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “Greatest appreciation to the krypchain team for an outstanding job. It's just what we wanted.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl4.jpg?alt=media&token=2eef660c-d281-477c-a4aa-6fe846ff8281');"></div>
                            <div class="client-name">
                                <h6>Jacob Lynch</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “I have gained a strong knowledge about the architecture of Bitcoin. krypchain helps me to easily transact and manage my porfolio.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl5.jpg?alt=media&token=83932eab-c1c1-4e28-93ff-e427d03b64f7');"></div>
                            <div class="client-name">
                                <h6>Gary Goldman</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “I can not express in words the ease and quality of utilizing ETH on krypchain. If you are utilizing any other combination of exchange and wallet, I assure you that you are doing it wrong.”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                    <div class="single-testimoinal-item">
                        <div class="client-info">
                            <div class="client-img bg-cover" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fcl6.jpg?alt=media&token=0cff8c7f-3b9b-42f2-9a4d-c7438f194ba4');"></div>
                            <div class="client-name">
                                <h6>Dylan Macalinao</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="feedback">
                            “krypchain is leading the charge for a better crypto UX — huge props to the dev team”
                        </div>
                        <div class="rating">
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                            <i class="icon-star"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="testimonial-wrapper section-padding fix" style="margin-top: -80px;">
        <div class="container">
            <div class="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
                <div class="block-contents">
                    <div class="section-title wow fadeInDown" data-wow-duration="1.2s">
                        <h2>Our partnerships</h2>
                    </div>
                </div>
            </div>

            <div class="row" style="width: 100%; display:flex; justify-content:center; align-items:center">
                <img src="@/assets/baseAssets/img/p1.svg" alt="" style="width: 150px; margin: 15px;">

                <img src="@/assets/baseAssets/img/p2.svg" alt="" style="width: 150px; margin: 15px;">

                <img src="@/assets/baseAssets/img/p3.svg" alt="" style="width: 200px; margin: 15px;">

                

                <img src="@/assets/baseAssets/img/p7.svg" alt="" style="width: 200px; margin: 15px;">

                <img src="@/assets/baseAssets/img/p8.svg" alt="" style="width: 200px; margin: 15px;">

                <img src="@/assets/baseAssets/img/p9.svg" alt="" style="width: 200px; margin: 15px;">

                <img src="@/assets/baseAssets/img/p10.svg" alt="" style="width: 250px; margin: 15px;">

                <img src="@/assets/baseAssets/img/p11.svg" alt="" style="width: 200px; margin: 15px;">

                

                
            </div>
            


        </div>
    </section>

    <hr>
    <br>
    <br>
    <br>

</template>