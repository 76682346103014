<template>
    <NavbarDark/>
    <AboutBody/>
    <Footer/>
</template>
  
<script>
  import { defineComponent } from "vue";
  import NavbarDark from "@/components/NavbarDark.vue";
  import AboutBody from "@/components/AboutBody.vue";
  import Footer from "@/components/Footer.vue"; 
  
  export default defineComponent({
    name: "AboutView",
    components: {
      NavbarDark,
      AboutBody,
      Footer
    },
  });
</script>
  