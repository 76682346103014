<template>
    
    <WalletBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import WalletBody from "@/components/dashboard/WalletBody.vue"; 
  
  export default defineComponent({
    name: "WalletView",
    components: {
      
        WalletBody
      
    },
  });
</script>
  