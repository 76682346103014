<template>
    
    <AdminStakingBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import AdminStakingBody from "@/components/master/AdminStakingBody.vue"; 
  
  export default defineComponent({
    name: "AdminStakingView",
    components: {
      
        AdminStakingBody
      
    },
  });
</script>
  