<template>
    
    <div class="outer">

        <!-- HEADER -->
        <HeaderB/>

        <!-- SWAP BODY -->
        <div class="outer__inner">
                <div class="bidding js-bidding">
                    
                    <div class="bidding__body">
                        <div class="bidding__center center" style="flex-direction: column;">
                            
                            <div class="bidding__wrapper center">
                                <div class="bidding__item js-bidding-item">
                                    <div class="currency">
                                        <div class="currency__title">Swap crypto</div>

                                        <div class="currency__row">
                                            
                                            <div style="width: 100%;">
                                                <div class="currency__label" style="opacity: 0.5">From</div>
                                                <div class="field" style="position: relative;">

                                                    <img :src="c1Image" style="width: 40px; position: absolute; top: 15px; border-radius: 50%; right: 40px;" alt="" >

                                                    <select v-model="select1" style="color:#ffffff; padding:20px 15px 20px 15px; border-radius: 10px; background-color: #242731; width: 100%;" @change="change1($event)" >
                                                        
                                                        <option value="" disabled selected hidden>Select an asset to send</option>

                                                        <option :value="coin.number" v-for="coin in coinList" :key="coin.symbol">{{coin.name}} ({{coin.symbol}})</option>
                                                        
                                                    </select>
                                                    
                                                </div>

                                                <div class="popup__wrap" style="margin-top: 5px;">
                                                    <div class="popup__category">
                                                        Available <br>balance
                                                    </div>
                                                    <div class="popup__details">
                                                        <div class="popup__currency">{{c1Balance}} {{c1Symbol}}</div>
                                                        <div class="popup__price">${{c1BalanceUsdDisplay}}</div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div class="currency__label" style="margin-top: -20px;"></div>
                                        <div class="currency__payment">
                                            <div class="currency__sign h4">$</div>
                                            <div class="currency__field" >
                                                <div class="currency__value"></div>
                                                <input class="currency__input" type="text"  placeholder="0" v-model="swapAmount" >
                                            </div>
                                        </div>

                                        <div class="currency__row">
                                            
                                            <div style="width: 100%;">
                                                <div class="currency__label" style="opacity: 0.5">To</div>
                                                <div class="field" style="position: relative;">

                                                    <img :src="c2Image" style="width: 40px; position: absolute; border-radius: 50%; top: 15px; right: 40px;" alt="" >

                                                    <select v-model="select2" style="color:#ffffff; padding:20px 15px 20px 15px; border-radius: 10px; background-color: #242731; width: 100%;" @change="change2($event)">
                                                        
                                                        <option value="" disabled selected hidden>Select an asset to receive</option>

                                                        <option :value="coin.number" v-for="coin in coinList" :key="coin.symbol" >{{coin.name}} ({{coin.symbol}})</option>
                                                        
                                                    </select>

                                                    

                                                </div>

                                                <div class="popup__wrap" style="margin-top: 5px;">
                                                    <div class="popup__category">
                                                        Available <br>balance
                                                    </div>
                                                    <div class="popup__details">
                                                        <div class="popup__currency">{{c2Balance}} {{c2Symbol}}</div>
                                                        <div class="popup__price">${{c2BalanceUsdDisplay}}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </div>
                                        
                                        <div class="currency__btns">
                                            <button class="button currency__button " @click="swap">Swap</button>
                                        </div>
                                    </div>
                                </div>

                                
                            </div>

                            <br>
                            <br>
                            <hr>
                            <br>

                            <div class="activity__wrapper center" style="margin-top: 30px;" id="yesT">
                                
                                <div class="activity__line">
                                    <h2 class="activity__title h4" style="color:#ffffff">Swap history</h2>
                                    
                                </div>
                                <div class="activity__table">
                                    <div class="activity__row">
                                        <div class="activity__col">
                                            <div class="sorting">From</div>
                                        </div>
                                        <div class="activity__col">
                                            <div class="sorting">To</div>
                                        </div>
                                        
                                        <div class="activity__col">
                                            <div class="sorting">Amount</div>
                                        </div>
                                        

                                        <div class="activity__col">
                                            <div class="sorting">Transaction ID</div>
                                        </div>

                                        <div class="activity__col">
                                            <div class="sorting">Status</div>
                                        </div>
                                        
                                        <div class="activity__col">Date</div>
                                    </div>

                                    <div class="activity__row" v-for="tran in transList" :key="tran.id">
                                        
                                        <div class="activity__col">
                                            <div class="activity__label" style="margin-right: 5px;">From</div>
                                            {{tran.sentSymbol}}
                                      
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label" style="margin-right: 5px;">To</div>
                                            {{tran.receivedSymbol}}
                                      
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Amount</div>
                                            ${{tran.amount}}
                                      
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Transaction ID</div>
                                             {{tran.id}}
                                      
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Status</div>
                                             Successful
                                      
                                        </div>
                                        <div class="activity__col">
                                            <div class="activity__label">Date and time</div>
                                            {{tran.date}}
                                      
                                        </div>
                                    </div>
    
                                    
                                    
                                </div>
                            </div>



                        </div>
                    </div>

                    

                </div>

                

                
        </div>

    </div>

    <!-- MODALS -->
    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/success.png" alt="" style="width: 100px; margin: 10px;">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{successText}}</p><br>

                    <button class="button entry__button" @click="closeS">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>


    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 30px;">
                
        <div class="popup__box">

            <div class="popup__fieldset" >
                
                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    
                    <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px; ">

                    <p class="successfully__info" style="margin-top: 10px; text-align: center; width: 100%;">{{errorText}}</p><br>

                    <button class="button entry__button" @click="closeE">Okay</button>
                    
                </div>

                
                
                
            </div>
            

            

            
        </div>
        
    </div>

    </div>

</template>

<script>
    import HeaderB from "@/components/dashboard/Header.vue";
    import { doc, addDoc, collection, query, where, getDocs, serverTimestamp, orderBy, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {

        components: {
            HeaderB,
        },

        data(){
            return{
                errorText:"",
                successText:"",
                loadingText:"",
                select1:"",
                select2: "",
                coinList: [],
                sl:[],
                marketPrice:[],
                marketPriceDisplay:[],
                marketCap: [],
                marketChange: [],
                marketChange2: [],
                marketSupply: [],
                marketImage: [],

                c1Image: "",
                c1Symbol: "",
                c1Balance: "",
                c1BalanceUsd: "",
                c1BalanceUsdDisplay: "",
                c1WalletId: "",
                c1Price: 0,

                c2Image: "",
                c2Symbol: "",
                c2Balance: "",
                c2BalanceUsd: "",
                c2BalanceUsdDisplay: "",
                c2WalletId: "",
                c2Price: 0,

                swapAmount: 0,
                transList:[],
                
            }
        },

        mounted(){

            
            document.getElementById("twallet").classList.remove("active");
            document.getElementById("tswap").classList.add("active");
            document.getElementById("tstaking").classList.remove("active");
            document.getElementById("tbot").classList.remove("active");
            document.getElementById("ttran").classList.remove("active");

            this.getSwapTrans();

            this.getCoinSymbols();

            $(document).ready(function () {
            $('.select').niceSelect();
            });

        },

        methods:{

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getCoinSymbols(){

                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                let i = -1;
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc")); 
                const querySnapshot = await getDocs(q); 

                querySnapshot.forEach((doc) => {
                    i++;
                    this.sl[i] = doc.data().symbol;
                })

                this.getMarketData();
            },

            getMarketData(){
                
                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+this.sl[0]+','+this.sl[1]+','+this.sl[2]+','+this.sl[3]+','+this.sl[4]+','+this.sl[5]+','+this.sl[6]+','+this.sl[7]+','+this.sl[8]+','+this.sl[9]+','+this.sl[10]+','+this.sl[11]+','+this.sl[12]+','+this.sl[13]+','+this.sl[14]+','+this.sl[15]+','+this.sl[16]+','+this.sl[17]+','+this.sl[18]+','+this.sl[19]+','+this.sl[20]+','+this.sl[21]+','+this.sl[22]+','+this.sl[23]+','+this.sl[24]+','+this.sl[25]+','+this.sl[26]+','+this.sl[27]+','+this.sl[28]+','+this.sl[29]+','+this.sl[30]+','+this.sl[31]+'&tsyms=USD')
                .then( response => {

                    for(let i = 0; i < 32; i++){
                       
                        
                        this.marketPrice[i] = Number(Object.values(response.data.RAW)[i].USD.PRICE)
                        this.marketPriceDisplay[i] = Object.values(response.data.DISPLAY)[i].USD.PRICE;
                        this.marketCap[i] = Object.values(response.data.RAW)[i].USD.MKTCAP;
                        this.marketChange[i] = Number(Object.values(response.data.RAW)[i].USD.CHANGEPCT24HOUR);
                        this.marketChange2[i] = Number(Object.values(response.data.RAW)[i].USD.CHANGE24HOUR);
                        this.marketSupply[i] = Number(Object.values(response.data.RAW)[i].USD.SUPPLY);
                        this.marketImage[i] = String("https://assets.coincap.io/assets/icons/"+String(this.sl[i]).toLowerCase()+"@2x.png");
                       
                        
                    }

                    this.getCoins();
                }); 
            },

            async getCoins(){

                let i = -1;
                let a=0;
                let tempB = 0;
                let tempA = 0;
                let tempSpot = 0;
                let assetsHeld = 0;
                let cImage;

                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    
                    i++;

                    //check balance not minus
                    let balanceL = doc.data().balance;
                    if(balanceL < 0 || isNaN(balanceL)){
                        balanceL = 0;
                        this.minusBalFix(doc.id);
                    }

                    a = Number(doc.data().balance) * Number(this.marketPrice[i]);
                    tempSpot += a;

                    if(a > 0){
                        assetsHeld++;
                    }

                    tempB = Number(doc.data().balance)  * Number(this.marketPrice[i]);
                    tempA = Number(doc.data().balance);

                    if(doc.data().name == "Terra"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fluna.png?alt=media&token=19c7fc87-ad9e-4abe-9d68-119dc5d0c749";
                    }
                    else if(doc.data().name == "Terra Classic"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2F01_LunaClassic_color.webp?alt=media&token=0aed8649-c61d-4791-869b-ef7c54c2a02d"
                    }
                    else if(doc.data().name == "Solana"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2FSolana_logo.png?alt=media&token=7cfd36ce-7305-415b-ac6c-faf3fcacef1b"
                    }
                    else if(doc.data().name == "Algorand"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Falgo.png?alt=media&token=b41200d3-a6c7-4818-af40-90abdfd579d4"
                    }
                    else if(doc.data().name == "Polkadot"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fdot.png?alt=media&token=f55f3c4e-15e4-4d1a-bdb5-5139bc6a4dda"
                    }
                    else if (doc.data().symbol == "APE") {
          cImage =
            "https://www.cryptocompare.com/media/39838302/ape.png";
        }

        else if (doc.data().symbol == "RNDR") {
          cImage =
            "https://www.cryptocompare.com/media/39500858/rndr.png";
        }
                    else{
                        cImage = this.marketImage[i];
                    }

                    this.coinList[i] = {      

                        number: i,
                        walletId: doc.id,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        color: doc.data().color,
                        address: doc.data().address,
                        balance: tempA,
                        balanceUsd: tempB,
                        balanceDisplay: this.round(tempA, 6),
                        balanceUsdDisplay: Number(tempB).toLocaleString("us-US"),
                        image: cImage,
                        price: this.marketPrice[i],
                        priceDisplay: this.marketPriceDisplay[i],
                        change: this.round(Number(this.marketChange[i]), 3),
                        change2: this.round(Number(this.marketChange2[i]), 5),
                        marketCap: Number(this.marketCap[i]).toLocaleString("us-US"),
                        supply: Number(this.marketSupply[i]).toLocaleString("us-US"), 
                        chartId: doc.data().symbol+"-chart"
                        
                    
                    }
                    
                    

                });


                

                // this.c1Symbol = this.coinList[1].symbol;
                // this.c1Image = this.coinList[1].image;
                // this.c1Balance = this.coinList[1].balance;
                // this.c1BalanceUsd = this.coinList[1].balanceUsd;

                this.$refs.loadingModal.classList.remove("bg-active"); 
            },

            change1(event){
              
                let i =  Number(event.target.value);

                this.c1Symbol = this.coinList[i].symbol;
                this.c1Image = this.coinList[i].image;
                this.c1Balance = this.round(this.coinList[i].balance, 6);
                this.c1BalanceUsd = this.coinList[i].balanceUsd;
                this.c1BalanceUsdDisplay = this.coinList[i].balanceUsdDisplay;
                this.c1WalletId = this.coinList[i].walletId;
                this.c1Price = this.coinList[i].price;
            },

            change2(event){
              
              let i =  Number(event.target.value);

              this.c2Symbol = this.coinList[i].symbol;
              this.c2Image = this.coinList[i].image;
              this.c2Balance = this.round(this.coinList[i].balance, 6);
              this.c2BalanceUsd = this.coinList[i].balanceUsd;
              this.c2BalanceUsdDisplay = this.coinList[i].balanceUsdDisplay;
              this.c2WalletId = this.coinList[i].walletId;
              this.c2Price = this.coinList[i].price;
          },

          async  swap(){
                this.loadingText= "Processing swap...";
                this.$refs.loadingModal.classList.add("bg-active");

                if(this.swapAmount == ""){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Invalid amount";
                    return;
                }

                if(this.c1Symbol == ""){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Select an asset to send";
                    return;
                }

                if(this.c2Symbol == ""){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Select an asset to receive";
                    return;
                }

                if(Number(this.swapAmount) < 10){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "The minimum amount is $10";
                    return;
                }

                if(this.c1Symbol == this.c2Symbol){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Can not swap "+this.c1Symbol+" for "+this.c2Symbol;
                    return;
                }

                if(Number(this.c1BalanceUsd) < Number(this.swapAmount)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+this.c1Symbol+" balance";
                    return;
                }

                //update coin1
                let c1CoinAmount = Number(this.swapAmount) / Number(this.c1Price)
                let newC1Balance = Number(this.c1Balance) - Number(c1CoinAmount);

                const c1Ref = doc(db, "UserWallets", String(this.c1WalletId));
                await updateDoc(c1Ref, {
                    balance: newC1Balance,
                });

                //update coin2
                let c2CoinAmount = Number(this.swapAmount) / Number(this.c2Price)
                let newC2Balance = Number(this.c2Balance) + Number(c2CoinAmount);

                const c2Ref = doc(db, "UserWallets", String(this.c2WalletId));
                await updateDoc(c2Ref, {
                    balance: newC2Balance,
                });

                //upload transaction
                await addDoc(collection(db, "SwapTrans"), {
                      amount: this.swapAmount,
                      status: "successful",
                      sent: c1CoinAmount,
                      sentSymbol: this.c1Symbol,
                      received: c2CoinAmount,
                      receivedSymbol: this.c2Symbol,
                      timeStamp: serverTimestamp(),
                      userId: sessionStorage.getItem("id"),
                      name: sessionStorage.getItem("name"),
                      email: sessionStorage.getItem("email"),
                      sentImage: this.c1Image,
                      receivedImage: this.c2Image,
                });

                //successful
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "Successfully swapped from "+this.c1Symbol+" to "+this.c2Symbol;
          },

          async getSwapTrans(){
                let i = -1;
                const q = query(collection(db, "SwapTrans"), where("userId", "==", sessionStorage.getItem("id")), orderBy("timeStamp", "desc")); 
                const querySnapshot = await getDocs(q);
                
                if(querySnapshot.docs.length > 0){
                    let i = -1;
                    let a2;
                    //document.getElementById("noT").style.display = "none";

                    querySnapshot.forEach((doc) => {
                        i++;

                        a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));

                        this.transList[i] = {
                            id: doc.id,
                            amount: Number(doc.data().amount).toLocaleString("us-US"),
                            received: Number(doc.data().received),
                            receivedSymbol: doc.data().receivedSymbol,
                            receivedImage: doc.data().receivedImage,
                            sent: Number(doc.data().sent),
                            sentSymbol: doc.data().sentSymbol,
                            sentImage: doc.data().sentImage,
                            date : a2.slice(0, -36)
                        }

                        
                    })



                }
                else{
                    document.getElementById("yesT").style.display = "none";
                    //document.getElementById("noT").style.display = "flex";
                    
                }

                
                this.$refs.loadingModal.classList.remove("bg-active");
                
            },

            async minusBalFix(walletId){
            const walletRef = doc(db, "UserWallets", String(walletId));
            await updateDoc(walletRef, {
                balance: 0
            });
            },
            
        }
        
    }
</script>