import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC-IVoJ86LWfXk-JWaSDylecjMAERpGUcg",
    authDomain: "kryp-26f92.firebaseapp.com",
    projectId: "kryp-26f92",
    storageBucket: "kryp-26f92.appspot.com",
    messagingSenderId: "355523567765",
    appId: "1:355523567765:web:eb3b661ab31025a00cfb9c"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export{
    db
}