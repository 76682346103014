<template>
    
    <GridBotBody/>

</template>
  
<script>
  import { defineComponent } from "vue";

  import GridBotBody from "@/components/dashboard/GridBotBody.vue"; 
  
  export default defineComponent({
    name: "GridBotView",
    components: {
      
        GridBotBody
      
    },
  });
</script>
  