<template>
    
    <section class="contact-us-wrapper section-padding">
        <div class="container">
            <div class="row text-center">

                <h1>Krypchain Privacy Policy</h1>

            </div>

            <br>
            <div>
                <p>We at Krypchain (defined below) respect and protect the privacy of visitors to our websites and our customers. This Privacy Policy describes our information handling practices when you access our services, which include our content on the websites located at KRYPCHAIN.IO, or any other websites, pages, features, or content we own or operate (collectively, the "Site(s)") or when you use the KrypChain mobile app, the KrypChain Card App (as defined below), any KrypChain, KrypChain Exchange, KrypChain Prime, or KrypChain Custody API or third party applications relying on such an API, and related services (referred to collectively hereinafter as "Services"). </p> <br>

                <p>Please take a moment to read this Privacy Policy carefully. We may modify this Privacy Policy from time to time which will be indicated by changing the date at the top of this page. If we make any material changes, we will notify you by email (sent to the email address specified in your account), by means of a notice on our Services prior to the change becoming effective, or as otherwise required by law.</p>

                <br>
                <hr>
                <br>

                <h3>1. ACCEPTANCE OF THIS PRIVACY POLICY</h3>

                <p>By accessing and using our Services, you signify acceptance to the terms of this Privacy Policy. Where we require your consent to process your personal information, we will ask for your consent to the collection, use, and disclosure of your personal information as described further below. We may provide additional "just-in-time" disclosures or information about the data processing practices of specific Services. These notices may supplement or clarify our privacy practices or may provide you with additional choices about how we process your data. If you do not agree with or you are not comfortable with any aspect of this Privacy Policy, you should immediately discontinue access or use of our Services.</p>

             <br>   
            <hr>
            <br>

            <h3>2. THE PERSONAL INFORMATION WE COLLECT</h3>

            <p>Personal information is typically data that identifies an individual or relates to an identifiable individual. This includes information you provide to us, information which is collected about you automatically, and information we obtain from third parties. The definition of personal information depends on the applicable law based on your physical location. Only the definition that applies to your physical location will apply to you under this Privacy Policy. Information you provide to us. To establish an account and access our Services, we'll ask you to provide us with some important information about yourself. This information is either required by law (e.g. to verify your identity), necessary to provide the requested services , or is relevant for certain specified purposes, described in greater detail below. As we add new features and Services, you may be asked to provide additional information. If you choose not to share certain information with us, we may not be able to serve you as effectively or offer you our Services. Any information you provide to us that is not required is voluntary.</p>

            <br>
            <hr>
            <br>

            <h3>3. HOW YOUR PERSONAL INFORMATION IS USED</h3>

            <p>Our primary purpose in collecting personal information is to provide you with a secure, smooth, efficient, and customized experience. We generally use personal information to create, develop, operate, deliver, and improve our Services, content and advertising; and for loss prevention and anti-fraud purposes. We may use this information in the following ways:</p>

            <br>

            <h6>3.1. To maintain legal and regulatory compliance</h6>

            <p>Most of our core Services are subject to laws and regulations requiring us to collect, use, and store your personal information in certain ways. For example, CB must identify and verify customers using our Services in order to comply with anti-money laundering laws across jurisdictions. This includes collection and storage of your photo identification. In addition, we use third parties to verify your identity by comparing the personal information you provide against third-party databases and public records. When you seek to link a bank account to your CB Account, we may require you to provide additional information which we may use in collaboration with service providers acting on our behalf to verify your identity or address, and/or to manage risk as required under applicable law. If you do not provide personal information required by law, we will have to close your account.</p>

            <br>

            <h6>3.2 To enforce our terms in our user agreement and other agreements</h6>

            <p>CB handles sensitive information, such as your identification and financial data, so it is very important for us and our customers that we actively monitor, investigate, prevent, and mitigate any potentially prohibited or illegal activities, enforce our agreements with third parties, and/or prevent and detect violations of our posted user agreement or agreements for other Services. In addition, we may need to collect fees based on your use of our Services. We collect information about your account usage and closely monitor your interactions with our Services. We may use any of your personal information collected for these purposes. The consequences of not processing your personal information for such purposes is the termination of your account.</p>

            </div>
            
            
        </div>
    
    </section>

</template>