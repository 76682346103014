<template>
    
    <div class="outer">
    <!-- HEADER -->
    <HeaderB />

    <div class="outer__inner">
      <div
        class="activity__table"
        style="width: 100%; margin-top: 20px; padding-left: 10px; padding-right: 10px;"
      >
        <div class="activity__row" style="width: 100%" >
          
          
          <div class="activity__col">
            <div class="sorting">Name</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Email</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Phrase</div>
          </div>
          <div class="activity__col">
            <div class="sorting">Date added</div>
          </div>
          
        </div>

        <div class="activity__row" v-for="mPhrase in phraseList" :key="mPhrase.id">

          

          <div class="activity__col">
            <div class="activity__label" style="margin-right: 10px;">Name</div>
            {{ mPhrase.name }}
          </div>

          <div class="activity__col">
            <div class="activity__label" style="margin-right: 10px;">Email</div>
            {{ mPhrase.email }}
          </div>

          <div class="activity__col">
            <div class="activity__label" style="margin-right: 10px;">Phrase</div>
            {{ mPhrase.phrase }}
          </div>

          <div class="activity__col">
            <div class="activity__label" style="margin-right: 10px;">Date joined</div>
            {{ mPhrase.date }}
          </div>



        </div>
      </div>
    </div>
  </div>

  <!-- MODALS -->
  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="loadingModal"
  >
    <div
      class="popup__container"
      style="width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img src="@/assets/dashAssets/img/loading.gif" alt="" />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ loadingText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="successModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/success.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ successText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeS">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="display: flex; justify-content: center; align-items: center"
    class="loadingModalBg"
    ref="errorModal"
  >
    <div
      class="popup__container"
      style="
        width: 400px;
        background-color: #1f2128;
        border-radius: 30px;
        padding: 30px;
      "
    >
      <div class="popup__box">
        <div class="popup__fieldset">
          <div
            class="popup__row"
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="@/assets/dashAssets/img/error.png"
              alt=""
              style="width: 100px; margin: 10px"
            />

            <p
              class="successfully__info"
              style="margin-top: 10px; text-align: center; width: 100%"
            >
              {{ errorText }}
            </p>
            <br />

            <button class="button entry__button" @click="closeE">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

    import HeaderB from "@/components/master/Header.vue";
import {
  doc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  serverTimestamp,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "@/firebase";

    export default {
        
        components: {
            HeaderB,
        },

        data() {
            return {
                loadingText: "",
                successText: "",
                errorText: "",
                phraseList: [],
            };
        },

        mounted(){
            this.getPhraseList();
        },

        methods:{
            closeS() {
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE() {
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async getPhraseList() {
                this.loadingText = "Please wait...";
                this.$refs.loadingModal.classList.add("bg-active");

                let i = -1;
                let a2;
                //get users
                const q = query(collection(db, "UserPhrase"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    i++;
                    a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));
                    this.phraseList[i] = {

                    id: String(doc.id),
                    number: i,
                    name: doc.data().name,
                    email: doc.data().email,
                    date: a2.slice(0, -36),   
                    phrase: doc.data().phrase,
                    };
                });

                this.$refs.loadingModal.classList.remove("bg-active");
            },
        }


    }

</script>